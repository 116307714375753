 
 <template>
  <van-form @failed="onFailed" @submit="submit">
    <div class="about"> 
         <van-cell title="第一次体温">
                 <template #value>
                  <van-stepper v-model="voucherInfo.firTemp" step="0.1" :default-value="36.5" :decimal-length="1"  max="42" min="33"  :rules="[{ required: true, message: '第一次体温' }]"/>
                 </template>
              </van-cell>
                    <van-cell title="第二次体温">
                 <template #value>
                  <van-stepper v-model="voucherInfo.secTemp" step="0.1" :default-value="36.5" :decimal-length="1"  max="42" min="33"  :rules="[{ required: true, message: '第二次体温' }]"/>
                 </template>
              </van-cell>
      <van-cell title="核酸照片">
        <template #value>
          <van-uploader
            width="100"
            height="100"
            v-model="voucherInfo.voucherImgList"
            :after-read="(file) => afterRead(file, 'voucherImg')"
            max-count="1"
          />
        </template>
      </van-cell>
      <van-cell title="解除隔离证明">
        <template #value>
          <van-uploader
            width="100"
            height="100"
            v-model="voucherInfo.releaseCertificateList"
            :after-read="(file) => afterRead(file, 'releaseCertificate')"
            max-count="1"
          />
        </template>
      </van-cell>
    </div>
    <div style="margin: 16px">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { ImagePreview, Notify, Toast } from 'vant';
import Api from '../util/App'
export default {
  name: 'Home',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const voucherInfo = ref({
      "addStatus": "",
      "controlId": 0,
      "controlStatus": "",
      "controlStep": "",
      "createDate": "",
      "creator": 0,
      "firTemp": "", 
      "idCard": "",
      "isDelete": 0,
      "personId": 0,
      "personName": "",
      "phone": "",
      "releaseCertificate": "",
      "remark": "",
      "secTemp": "",
      "staffId": 0,
      "updateDate": "",
      "updater": 0,
      "voucherDate": "",
      "voucherImg": "",
      "voucherType": ""
    })
    const showDatetimePicker = ref(false);
    const DatetimePickerField = ref('');
    const router = useRouter()
    return { voucherInfo, router, DatetimePickerField, showDatetimePicker }

  }, mounted() {
      
       this.voucherInfo = {
      "addStatus": "",
      "controlId": 0,
      "controlStatus": "",
      "controlStep": "",
      "createDate": "",
      "creator": 0,
      "firTemp": "", 
      "idCard": "",
      "isDelete": 0,
      "personId": 0,
      "personName": "",
      "phone": "",
      "releaseCertificate": "",
      "remark": "",
      "secTemp": "",
      "staffId": 0,
      "updateDate": "",
      "updater": 0,
      "voucherDate": "",
      "voucherImg": "",
      "voucherType": ""
    }
    // 需要先根据token 交换 token 
    //eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJaRFJRR0siLCJ1c2VySWQiOiIxYjFlNjg5NzlkZmY0MzA4YmM0ZDhiNmE2MWI2YWU2ZiIsIm5hbWUiOiJaRFJRR0siLCJleHAiOjE2NTA1NDI1MzN9.-qCoM5qlRoa8GaN6-ls6yaIq3EVHib9uNDJmBji_YXJIgnQxemmS84P-YK_DWvbSn1quP0cDApDqXfRa3JnuVA
    // let sourceToken =this.router.currentRoute.value.query.token;
    //   sourceToken="eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJaRFJRR0siLCJ1c2VySWQiOiIxYjFlNjg5NzlkZmY0MzA4YmM0ZDhiNmE2MWI2YWU2ZiIsIm5hbWUiOiJaRFJRR0siLCJleHAiOjE2NTA1NDI1MzN9.-qCoM5qlRoa8GaN6-ls6yaIq3EVHib9uNDJmBji_YXJIgnQxemmS84P-YK_DWvbSn1quP0cDApDqXfRa3JnuVA"
    //  this.login(sourceToken)

    let pinfo = JSON.parse(this.$router.currentRoute.value.params.pinfo);

    this.initdata(pinfo)

  },
  activated()
  {
   this.voucherInfo = {
      "addStatus": "",
      "controlId": 0,
      "controlStatus": "",
      "controlStep": "",
      "createDate": "",
      "creator": 0,
      "firTemp": "36.5", 
      "idCard": "",
      "isDelete": 0,
      "personId": 0,
      "personName": "",
      "phone": "",
      "releaseCertificate": "",
      "remark": "",
      "secTemp": "36.5",
      "staffId": 0,
      "updateDate": "",
      "updater": 0,
      "voucherDate": "",
      "voucherImg": "",
      "voucherType": ""
    }
      let pinfo = JSON.parse(this.$router.currentRoute.value.params.pinfo);

    this.initdata(pinfo)

  },
  methods:
  {
    login(token) {
      Api.getloginByToken(token).then((res) => {
        if (res.code == 0) {
          window.localStorage.setItem('token', res.data.token)
        } else {
          Notify({ type: 'danger', message: res.msg });
        } 
      })

    },
    initdata(pinfo) {
      if (pinfo.id != null && pinfo.id != "") {
        Api.getvhDetail(pinfo.id).then(res => {
          this.voucherInfo = res.data
          if (this.voucherInfo.releaseCertificate != null && this.voucherInfo.releaseCertificate != '')
            this.voucherInfo['releaseCertificateList'] = [{ url: this.voucherInfo.releaseCertificate }]
          if (this.voucherInfo.voucherImg != null && this.voucherInfo.voucherImg != '')
            this.voucherInfo['voucherImgList'] = [{ url: this.voucherInfo.voucherImg }]
        })
      }else 
      {
        this.voucherInfo.controlId=pinfo.controlId
        this.voucherInfo.personId=pinfo.personId
      }

    }, timeFormat(time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return year + '-' + this.padLeft(month, 2) + '-' + this.padLeft(day, 2) + " " + this.padLeft(hour, 2) + ":" + this.padLeft(min, 2) + ":00"
    },
    padLeft(num, n) {
      let y = '00000000000000000000000000000' + num; //爱几个0就几个，自己够用就行  
      return y.substr(y.length - n);
    },
    afterRead(file, field) {
      // 这时候我们创建一个formData对象实例
      const formData = new FormData();
      // 通过append方法添加需要的file
      // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
      formData.append('file', file.file);

      //上传文件、成功后， 把Url 赋值给 field  
      Api.uploadFile(formData).then((res) => {
        if (res.code == 0 && res.data != null && res.data != "") {
           this.voucherInfo[field] = res.data

        }
      })



    },

    submit() {

      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '数据传输中',
      });
      //this.voucherInfo.personId=''
       this.voucherInfo['releaseCertificateList']=null;
       this.voucherInfo['voucherImgList'] = null 
      Api.getSubmit(this.voucherInfo).then(res => {
        toast.clear()
        if (res.code == 0) {

          Notify({ type: 'success', message: '上报成功!' });
         this.$router.back()

        } else {

          Notify({ type: 'danger', message: res.msg });

        }

      })
    }
  }
}

    </script>
    
    <style>
</style>