<template>
  <van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh="
      dataForm.currentPage = 1;
      getDateList();
    "
  >
    <div class="about">
      <van-search
        v-model="dataForm.searchKey"
        @search="onSearch"
        placeholder="请输入姓名"
      />
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="dataForm.searchType"
          :options="dataForm.searchTypeData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
          "
          title-class="dropitem"
        />
        <van-dropdown-item
          v-model="dataForm.searchStep"
          :options="dataForm.searchStepData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
          "
          title-class="dropitem"
        />
        <van-dropdown-item  v-if="$route.query.controlStatus!='WorkableControl'&&$route.query.controlStatus!='WorkableControlNum'"
          v-model="dataForm.searchState"
          :options="dataForm.searchStateData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
          "
          title-class="dropitem"
        />
      </van-dropdown-menu>
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="dataForm.searchCommunity"
          :options="dataForm.searchCommunityData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
            getGridData()
          "
          title-class="dropitem"
        />
        <van-dropdown-item
          v-model="dataForm.searchGrid"
          :options="dataForm.searchGridData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
          "
          title-class="dropitem"
        />
        <van-dropdown-item
          v-model="dataForm.searchStatus"
          :options="dataForm.searchStatusData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
          "
          title-class="dropitem"
        />
      </van-dropdown-menu>
      <van-dropdown-menu>
         <van-dropdown-item
          v-model="dataForm.searchDevice"
          :options="dataForm.searchDeviceData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
          "
          title-class="dropitem"
        />
        <van-dropdown-item
          v-model="dataForm.unsealed"
          :options="dataForm.unsealedData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
          "
          title-class="dropitem"
        />
      </van-dropdown-menu>
      <div style="
          font-size: 14px;
          color: #999;
          background: #f7f8fa;
          text-align: left;
          padding-left: 20px;
          margin: 3px 0 3px 0;
          height: 14px;
          line-height: 24px;
        ">
        为你筛选出<label
          style="color: red"
          v-html="dataForm.data.total"
        ></label>条结果
      </div>
    </div>

    <div class="dataList">
      <router-link
        :to="{ name: '人员详细信息向导', query: { id: item.id,status:item.controlStatus } }"
        v-for="(item, index) in dataForm.data.list"
        :key="index"
      >
        <div class="dataItem">
          <van-row class="r1">
            <span class="title">{{ item.personName }}</span>

            <van-tag
              class="leftTag"
              :color="TagColors.controlStep[item.controlStep].bgColor"
              :text-color="TagColors.controlStep[item.controlStep].color"
              v-if="item.controlStep != null&&item.controlStep != ''"
              type="primary"
            >{{ this.getDictLabel("controlStep", item.controlStep) }}</van-tag>
            <van-tag
              class="leftTag"
              color="#E8EBFF"
              text-color="#5776E6"
              v-if="item.personType != null&&item.personType != ''"
            >{{
              this.getDictLabel("PeopleType", item.personType)
            }}</van-tag>
            <van-tag
              class="rightTag"
              color="#E8EBFF"
              text-color="#5776E6"
              v-if="item.controlStatus != null&&item.controlStatus != ''"
            >{{
              this.getDictLabel("controlStatus", item.controlStatus)
            }}</van-tag>
          </van-row>
          <van-row class="r2">
            <van-col span="10">
              <div class="itemTitle">证件号码</div>
              <div class="itemValue">{{ item.idCard }}</div>
            </van-col>
            <van-col
              class="colMiddle"
              span="6"
            >
              <div class="itemTitle">手机号码</div>
              <div class="itemValue">{{ item.phone }}</div>
            </van-col>
            <van-col span="8">
              <div class="itemTitle">
                {{ dicBeginType[this.dicBeginTime[item.personType]] }}
              </div>
              <div class="itemValue">
                {{ item[this.dicBeginTime[item.personType]] }}
              </div>
            </van-col>
          </van-row>
        </div>
      </router-link>
      <van-pagination
        v-if="dataForm.data.total > 0"
        v-model="dataForm.currentPage"
        :total-items="dataForm.data.total"
        :items-per-page="10"
        :show-page-size="5"
        @change="getDateList"
      />
    </div>
  </van-pull-refresh>
</template>

<script>
import { reactive, ref } from "vue";
import Api from "../util/App";

export default {
  name: "Home",
  components: {},
  inject: ["setActive"],
  setup() {
    const dataForm = reactive({
      searchKey: "",
      searchState: "",
      searchType: "",
      searchStep: "",
      searchStatus: "",
      searchDevice: "",
      searchCommunity: "",
      searchGrid: "",
      searchTransStatus: "",
      unsealed: "",
      unsealedData: [
        { text: "未落实管控", value: "" },
        { text: "是", value: "1" },
      ],
      searchStateData: [],
      searchStepData: [],
      searchStatusData: [],
      searchTypeData: [],
      searchCommunityData: [],
      searchGridData:[],
      searchDeviceData: [],
      searchTransStatusData: [],
      currentPage: 1,
      data: {
        total: 0,
        list: [],
      },
    });

    const communityList = ref([]);
    const role = ref("");
    const TagColors = {
      controlStep: {
        NoSegregation: {
          color: "#61D65E",
          bgColor: "#D6FFD5",
        },
        InHomeSegregation: {
          color: "#FF881C",
          bgColor: "#FFE1C5",
        },
        FocusSegregation: {
          color: "#FA5555",
          bgColor: "#FFE0E0",
        },
        HealthTest: {
          color: "#5ED6C8",
          bgColor: "#E7FFFC",
        },
        CancelReturnHf: {
          color: "#5ED6C8",
          bgColor: "#E7FFFC",
        },
      },
    };
    const isLoading = ref(false);
    const dicBeginTime = {
      Other: "returnDate",
      RedAndYellow: "redYellowDate",
      HighRisk: "returnDate",
      BjStudent: "arriveHomeTime",
      OutsideCity: "returnDate",
      OutsideProvince: "returnDate",
      Abroad: "returnDate",
      PositiveExposure: "lastContactDate",
      SecondTouch: "lastContactDate",
      Touch: "lastContactDate",
    };
    const dicBeginType = {
      returnDate: "返肥时间",
      redYellowDate: "转码时间",
      lastContactDate: "末次接触时间",
      arriveHomeTime: "到家时间",
    };
    return {
      role,
      dataForm,
      communityList,
      TagColors,
      isLoading,
      dicBeginTime,
      dicBeginType,
    };
  },
  watch: {
    role: {
      handler(newval, oldval) {
        this.getDateList();
      },
      deep: true,
    },
  },
  mounted() {
    this.role = window.localStorage.getItem("role");
    this.$emit("setActive", "alllist");
    this.$emit("changeTitle", "全部人员列表");
    this.getCommunityList();
    // 重新刷新数据...
    this.dataForm.searchState =
      this.$route.query.controlStatus == undefined
        ? ""
        : this.$route.query.controlStatus;
    this.dataForm.searchCommunity =
      this.$route.query.community == undefined
        ? ""
        : this.$route.query.community;
    this.dataForm.searchType =
      this.$route.query.type == undefined ? "" : this.$route.query.type;
    this.dataForm.searchGrid =
      this.$route.query.grid == undefined ? "" : this.$route.query.grid;
    this.dataForm.searchStep =
      this.$route.query.controlStep == undefined
        ? ""
        : this.$route.query.controlStep;
    this.dataForm.searchDevice =
      this.$route.query.device == undefined ? "" : this.$route.query.device;
    this.dataForm.searchStatus =
      this.$route.query.status == undefined ? "" : this.$route.query.status;

    this.dataForm.searchTransStatus =
      this.$route.query.tsstatus == undefined ? "" : this.$route.query.tsstatus;

    this.getDateList();
  },
  activated() {
    this.role = window.localStorage.getItem("role");
    this.$emit("setActive", "alllist");
    //     // 重新刷新数据...
    if (this.$route.params.isrefresh != null && this.$route.params.isrefresh) {
      this.dataForm.searchKey = "";
      this.dataForm.searchState =
        this.$route.query.controlStatus == undefined
          ? ""
          : this.$route.query.controlStatus;
      this.dataForm.searchCommunity =
        this.$route.query.community == undefined
          ? ""
          : this.$route.query.community;
      this.dataForm.searchGrid =
        this.$route.query.grid == undefined ? "" : this.$route.query.grid;
      this.dataForm.searchType =
        this.$route.query.type == undefined ? "" : this.$route.query.type;

      this.dataForm.searchStep =
        this.$route.query.controlStep == undefined
          ? ""
          : this.$route.query.controlStep;
      this.dataForm.searchDevice =
        this.$route.query.device == undefined ? "" : this.$route.query.device;
      this.dataForm.searchStatus =
        this.$route.query.status == undefined ? "" : this.$route.query.status;
      this.dataForm.searchTransStatus =
        this.$route.query.tsstatus == undefined
          ? ""
          : this.$route.query.tsstatus;

       this.dataForm.unsealed=this.$route.query.controlStatus=='WorkableControl'||this.$route.query.controlStatus=='WorkableControlNum'?'1':'';
      this.getDateList();
    }
  },

  methods: {
    initSelectData() {
      let dicControlStatusList = this.$store.state.Dict.find(
        (p) => p.dictType == "controlStatus"
      ).dataList;
      let dicPeopleTypeStatusList = this.$store.state.Dict.find(
        (p) => p.dictType == "PeopleType"
      ).dataList;
      let dicControlStepList = this.$store.state.Dict.find(
        (p) => p.dictType == "controlStep"
      ).dataList;

      this.dataForm.searchStateData.push({ text: "全部管控状态", value: "" });
      this.dataForm.searchTypeData.push({ text: "全部人员类型", value: "" });
      this.dataForm.searchStepData.push({ text: "全部管控措施", value: "" });

      this.dataForm.searchStatusData.push({ text: "数据状态", value: "" });
      this.dataForm.searchStatusData.push({ text: "暂未管控", value: "1" });
      this.dataForm.searchStatusData.push({ text: "待审核", value: "2" });
      this.dataForm.searchStatusData.push({ text: "已审核", value: "3" });

      this.dataForm.searchDeviceData.push({ text: "不筛选门磁", value: "" });
      this.dataForm.searchDeviceData.push({ text: "应装门磁", value: "3" });
      this.dataForm.searchDeviceData.push({ text: "已装门磁", value: "2" });

      this.dataForm.searchTransStatusData.push({
        text: "不筛选转运",
        value: "",
      });
      //:未转运NotTransshipped,转运中InTransit,Transshipped已转运
      this.dataForm.searchTransStatusData.push({
        text: "未转运",
        value: "NotTransshipped",
      });
      this.dataForm.searchTransStatusData.push({
        text: "转运中",
        value: "InTransit",
      });
      this.dataForm.searchTransStatusData.push({
        text: "已转运",
        value: "Transshipped",
      });
      this.dataForm.searchGridData = [];
      this.dataForm.searchGridData.push({ text: "全部网格", value: "" });

      dicControlStatusList.forEach((element) => {
        this.dataForm.searchStateData.push({
          text: element.dictLabel,
          value: element.dictValue,
        });
      });

      dicPeopleTypeStatusList.forEach((element) => {
        this.dataForm.searchTypeData.push({
          text: element.dictLabel,
          value: element.dictValue,
        });
      });

      dicControlStepList.forEach((element) => {
        this.dataForm.searchStepData.push({
          text: element.dictLabel,
          value: element.dictValue,
        });
      });
    },
    getCommunityList() {
      this.communityList = [];
      Api.getOrgCommunityList().then((res) => {
        this.communityList = res.data;
        this.dataForm.searchCommunityData.push({ text: "全部社区", value: "" });
        this.communityList.forEach((element) => {
        this.dataForm.searchCommunityData.push({
            text: element.name,
            value: element.id,
          });
        });
        setTimeout(() => {
          this.initSelectData();
        }, 800);
      });
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(
          (p) => p.dictType == type
        ).dataList.find((p) => p.dictValue == value).dictLabel;
      } catch {
        return "";
      }
    },
    getGridData() {
      this.dataForm.searchGrid = "";
      this.dataForm.searchGridData = [];
      this.dataForm.searchGridData.push({ text: "全部网格", value: "" });
      if (this.dataForm.searchCommunity != "") {
        Api.getGridList({ community: this.dataForm.searchCommunity }).then(
          (res) => {
            res.data.forEach((element) => {
              this.dataForm.searchGridData.push({
                text: element.name,
                value: element.id,
              });
            });
          }
        );
      }
    },
    getDateList() {
      let json={
        limit: 10,
        listType: 2,
        page: this.dataForm.currentPage,
        personName: this.dataForm.searchKey,
        community: this.dataForm.searchCommunity,
        controlStatus: this.dataForm.searchState,
        personType: this.dataForm.searchType,
        controlStep: this.dataForm.searchStep,
        statusApp: this.dataForm.searchStatus,
        deviceImei: this.dataForm.searchDevice,
        transportStatus: this.dataForm.searchTransStatus,
        grid: this.dataForm.searchGrid,
        unsealed: this.dataForm.unsealed,
        isTe: this.role == "GridPerson" ? 2 : null,
      }
      if(this.$route.query.isfive=='HealthTesting'){
        json.isTe=2;
      }
      if(this.dataForm.searchState=='WorkableControl'||this.dataForm.searchState=='WorkableControlNum') delete json.controlStatus;
      Api.getPersonList(json).then((res) => {
        this.dataForm.data = res.data;
        this.isLoading = false;
      });
    },
    onSearch(val) {
      this.dataForm.currentPage = 1;
      this.dataForm.searchKey = val;
      this.getDateList();
    },
  },
};
</script>
<style scoped>
.dataList {
  background: #f8f8f8ff;
}
.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
  padding-bottom: 10px;
}
.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}
.dataItem .r1 .leftTag {
  margin-right: 5px;
}
.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}
.dataItem .r2 .itemTitle {
  color: #999999;
}
.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
  min-height: 90vh;
}

.about >>> .dropitem {
  font-size: 12px !important;
}
</style>
