<template>
<van-pull-refresh style="min-height:100vh;"
  v-model="isLoading"
  success-text="刷新成功"
  @refresh="getDateList"
>
 
      <div class="about">
        <van-search
          v-model="dataForm.searchKey"
          @search="onTabs2_2Search"
          placeholder="请输入搜索关键词"
        />
            <van-dropdown-menu  >

        <van-dropdown-item
          v-model="dataForm.searchTransStatus"
          :options="dataForm.searchTransStatusData"
          @change="
            dataForm.currentPage = 1;
            getDateList();
          "
             title-class="dropitem"
        /> 

          <van-dropdown-item
          v-model="dataForm.searchCommunity"
          :options="dataForm.searchCommunityData"
          @change="
            dataForm.currentPage = 1;
            getGridData(); 
            getDateList();
          "
            title-class="dropitem"
        />
           <van-dropdown-item
          v-model="dataForm.searchGrid"
          :options="dataForm.searchGridData"
          @change="
            dataForm.currentPage = 1; 
            getDateList();
          "
            title-class="dropitem"
        /> 

            </van-dropdown-menu>
        <div
          style="
            font-size: 14px;
            color: #999;
            background: #f7f8fa;
            text-align: left;
            padding-left: 20px;
          "
        >
          为你筛选出<font
            style="color: red"
            v-html="dataForm.data.total"
          ></font
          >条结果
        </div>
      </div>
      <div class="dataList">
        <div
          class="dataItem" 
          v-for="(item, index) in dataForm.data.list"
          :key="index"
        >
          <router-link :to="{ name: '转运单填报', query: { id: item.id } }">
            <van-row class="r1">
              <span class="title">{{ item.personName }}</span>
              <van-tag class="rightTag" type="warning">{{
                this.getDictLabel("TransportType", item.carType)
              }}</van-tag>
                 <van-tag class="rightTag" color="#E8EBFF" text-color="#5776E6">{{
                this.getDictLabel("TransportStatus", item.status)
              }}</van-tag>
            </van-row>
            <van-row class="r2">
              <van-col span="10">
                <div class="itemTitle">证件号码</div>
                <div class="itemValue">{{ item.idCard }}</div>
              </van-col>
              <van-col class="colMiddle" span="6">
                <div class="itemTitle">手机号码</div>
                <div class="itemValue">{{ item.phone }}</div>
              </van-col>
              <van-col span="8">
                <div class="itemTitle">转运开始时间</div>
                <div class="itemValue">{{ item.transportTime }}</div> 
              </van-col>
            </van-row>
            <!-- <van-row class="r2">
              <van-col span="8">
                <div class="itemTitle">出发地</div>
              </van-col>
              <van-col span="16">
                <div>{{ item.startPlace }}</div>
              </van-col>
            </van-row> -->
            <van-row class="r2">
              <van-col span="8">
                <div class="itemTitle">目的地</div>
              </van-col>
              <van-col span="16">
                <div>{{ item.destination }}</div>
              </van-col>
            </van-row>
          </router-link>
        </div>

        <van-pagination
          v-if="dataForm.data.total > 0"
          v-model="dataForm.currentPage"
          :total-items="dataForm.data.total"
          :items-per-page="10"
          :show-page-size="5"
          @change="getTab2_2DateList"
        /></div
    > 
  </van-pull-refresh>
</template>

<script> 
// @ is an alias to /src 
import { reactive,ref } from 'vue';
import Api from '../util/App'
import { Dialog, Notify } from 'vant'
export default {
  name: 'Home',
  components: {

  },
inject:['setActive'],
  setup() {
    const dataForm = reactive({
  
      searchCommunity: '',
       searchGrid: '',
        searchTransStatus:'',
            searchCommunityData: [],
      searchDeviceData: [],
         searchTransStatusData: [],
         searchGridData:[],
 
        searchKey: '',
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //   {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ]
     
      },  
    });

   
    const communityList = ref([
      // {
      //   "id": "",
      //   "pid": "",
      //   "children": [],
      //   "name": "",
      //   "lon": null,
      //   "lat": null,
      //   "sort": 1,
      //   "createDate": null,
      //   "parentName": null,
      //   "remark": null,
      //   "isDelete": 0,
      //   "userLists": null
      // }
    ]);
    const SensorDataTypeArray = ["已关门", "故障", "已开门"]
    const SensorDeviceStateArray = ["正常", "故障", "报警", "离线"]
    const isLoading=ref(false)

    return { dataForm,isLoading, SensorDataTypeArray, SensorDeviceStateArray,communityList }

  },
  mounted() {
 


 this.dataForm.searchCommunity = this.$route.query.community == undefined ? '' : this.$route.query.community
 this.  getGridData()
     this.dataForm.searchGrid = this.$route.query.grid == undefined ? '' : this.$route.query.grid
    this.dataForm.searchTransStatus = this.$route.query.tsstatus == undefined ? '' : this.$route.query.tsstatus

   this.getCommunityList()
    this.getTab2_2DateList();
   

  },
  activated() { 
   this.dataForm.searchCommunity = this.$route.query.community == undefined ? '' : this.$route.query.community
    this.  getGridData()
     this.dataForm.searchGrid = this.$route.query.grid == undefined ? '' : this.$route.query.grid
    this.dataForm.searchTransStatus = this.$route.query.tsstatus == undefined ? '' : this.$route.query.tsstatus

    this.getTab2_2DateList();
   
  },

  methods:
  {
     initSelectData() { 
      this.dataForm.searchTransStatusData.push({ text: '不筛选转运', value: '' })
      //:未转运NotTransshipped,转运中InTransit,Transshipped已转运
      this.dataForm.searchTransStatusData.push({ text: '未转运', value: 'NotTransshipped' })
      this.dataForm.searchTransStatusData.push({ text: '转运中', value: 'InTransit' })
      this.dataForm.searchTransStatusData.push({ text: '已转运', value: 'Transshipped' })
         this.dataForm.searchGridData=[]
          this.dataForm.searchGridData.push({ text: '全部网格', value: '' })
 
 

    },
    getCommunityList() {
      this.communityList = [];
      Api.getOrgCommunityList().then(res => {
        this.communityList = res.data
        this.dataForm.searchCommunityData.push({ text: '全部社区', value: '' })
        this.communityList.forEach(element => {
          this.dataForm.searchCommunityData.push({ text: element.name, value: element.id })
        });
  
        this.initSelectData()
      })
    },    getGridData()
    {
        this.dataForm.searchGrid=''
         this.dataForm.searchGridData=[]
          this.dataForm.searchGridData.push({ text: '全部网格', value: '' })
        if(this.dataForm.searchCommunity!=''){
        Api.getOrgLiveList({communityId:this.dataForm.searchCommunity}).then((res)=>{
          res.data.forEach(element => {
            this.dataForm.searchGridData.push({ text: element.name, value: element.gridId })
          }); 
        })
       } 
    },
  getDateList(){

     
    this.getTab2_2DateList();
 

          let that =this; 
setTimeout(that.isLoading=false,1000)
  },
 
    onTabs2_2Search(val) {
      this.dataForm.searchKey = val
      this.getTab2_2DateList()
    },
    getTab2_2DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.currentPage, personName: this.dataForm.searchKey, status: this.dataForm.searchTransStatus, community: this.dataForm.searchCommunity,gridId:this.dataForm.searchGrid }).then(res => {
        this.dataForm.data = res.data
      })
    },

    
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(p => p.dictType == type).dataList.find(p => p.dictValue == value).dictLabel
      } catch {
        return ''

      }
    },

  }
}
</script>
<style scoped>
.dataList {
  background: #f8f8f8ff;
}
.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
  padding-bottom: 10px;
 
}
.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}
.dataItem .r1 .leftTag {
  margin-right: 5px;
}
.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}
.dataItem .r2 .itemTitle {
  color: #999999;
}
.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
min-height: 90vh;

}
</style>