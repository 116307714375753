<template>
  <div class="about">
    <van-search
      v-model="dataForm.searchKey"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />
    <div
      style="
        font-size: 14px;
        color: #999;
        background: #f7f8fa;
        text-align: left;
        padding-left: 20px;
      "
    >
      为你筛选出<font style="color: red">{{ dataForm.data.total }}</font
      >条结果
    </div>
  </div>
  <div class="dataList">
    <router-link
      :to="{ name: '任务填报', query: { id: index } }"
      v-for="(item, index) in dataForm.data.list"
      :key="index"
    >
      <div class="dataItem">
        <van-row class="r1">
          <span class="title">张华满</span>
          <van-tag class="leftTag" type="primary">居家隔离</van-tag>
          <van-tag class="rightTag" type="warning">逾期5天</van-tag>
        </van-row>

        <van-row class="r2">
          <van-col span="10">
            <div>证件号码</div>
            <div>3408965699269210211</div>
          </van-col>
          <van-col class="colMiddle" span="6">
            <div>手机号码</div>
            <div>15126532652</div>
          </van-col>
          <van-col span="8">
            <div>上次填报时间</div>
            <div>2022-4-13 11:58</div>
          </van-col>
        </van-row>
      </div>
    </router-link>
    <van-pagination
      v-model="dataForm.currentPage"
      :total-items="dataForm.data.total"
      :items-per-page="10"
      :show-page-size="5"
      @change="getDateList"
    />
  </div>
</template>

<script>
// @ is an alias to /src 
import { reactive } from 'vue'; 
//import { getPersoninfoDataList,posttest } from '../util/Api'
export default {
  name: 'homelist',
  components: {
  },
  setup() {
    const dataForm = reactive({
      searchKey: '',
      currentPage: 1,
      data: {
        total: 0,
        list: []
      }
    });
    let getDateList = () => {
      //posttest({"username":"admin","password":"admin","uuid":"e50547e4-1135-4bc6-8030-f9c54032a847","captcha":"www"})
      //dataForm.data=  getPersoninfoDataList({limit:10,listType:1,page:dataForm.currentPage,personName:dataForm.searchKey})
    }
    const onSearch = (val) => {
      dataForm.searchKey = val
      getDateList()
    };
    return { dataForm, getDateList, onSearch }
  },
  mounted() {

    this.getDateList();
  },

}
</script>
<style scoped>
.dataItem {
  margin: 15px 10px 15px 10px;
  border: 1px solid rgb(212, 212, 212);
  padding-top: 10px;
  background: #f1e8e88f;
}
.dataItem .r1 .title {
  color: #1c1c1c;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}
</style>
