<template>
  <van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh="
      dataForm.currentPage = 1;
      getDataList();
    "
  >
    <div class="about">
      <van-search
        v-model="dataForm.searchKey"
        @search="onSearch"
        placeholder="请输入姓名"
      />
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="dataForm.crowdType"
          :options="dataForm.crowdTypeList"
          @change="
            dataForm.currentPage = 1;
            getDataList();
          "
          title-class="dropitem"
        />
        <van-dropdown-item
          v-model="dataForm.community"
          :options="dataForm.communityList"
          @change="
            dataForm.currentPage = 1;
            getDataList();
          "
          title-class="dropitem"
        />
      </van-dropdown-menu>
      <van-button
        type="danger"
        @click="delDate()"
        size="mini"
        style="float: right; right: 10px; padding: 15px 20px; display: block;margin-top:10px"
      >删除</van-button>
      <div style="
          font-size: 14px;
          color: #999;
          background: #f7f8fa;
          text-align: left;
          padding-left: 20px;
          margin: 20px 0 3px 0;
          height: 14px;
          line-height: 24px;
        ">
        为你筛选出<label
          style="color: red"
          v-html="dataForm.data.total"
        ></label>条结果
      </div>
    </div>

    <div class="dataList">
      <div
        @click="details(item)"
        v-for="(item, index) in dataForm.data.list"
        :key="index"
      >
        <div class="dataItem">
          <van-checkbox-group
            ref="checkboxGroup"
            v-model="dataForm.checkedList"
            @change="(o) => checkChange(o)"
          >
            <van-checkbox
              style="text-align: right"
              :name="item.id"
              @click.stop="() => {}"
            ></van-checkbox>
          </van-checkbox-group>
          <van-row class="r1">
            <span class="title">{{ item.personName }}</span>

            <van-tag
              class="leftTag"
              :color="TagColors.controlStep[item.controlStep].bgColor"
              :text-color="TagColors.controlStep[item.controlStep].color"
              v-if="item.controlStep != null && item.controlStep != ''"
              type="primary"
            >{{ this.getDictLabel("controlStep", item.controlStep) }}</van-tag>
            <van-tag
              class="leftTag"
              color="#E8EBFF"
              text-color="#5776E6"
              v-if="item.personType != null && item.personType != ''"
            >{{ this.getDictLabel("PeopleType", item.personType) }}</van-tag>
            <van-tag
              class="rightTag"
              color="#E8EBFF"
              text-color="#5776E6"
              v-if="item.controlStatus != null && item.controlStatus != ''"
            >{{
                this.getDictLabel("controlStatus", item.controlStatus)
              }}</van-tag>
          </van-row>
          <van-row class="r2">
            <van-col span="10">
              <div class="itemTitle">证件号码</div>
              <div class="itemValue">{{ item.idCard }}</div>
            </van-col>
            <van-col
              class="colMiddle"
              span="6"
            >
              <div class="itemTitle">手机号码</div>
              <div class="itemValue">{{ item.phone }}</div>
            </van-col>
            <van-col span="8">
              <div class="itemTitle">
                {{ dicBeginType[this.dicBeginTime[item.personType]] }}
              </div>
              <div class="itemValue">
                {{ item[this.dicBeginTime[item.personType]] }}
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
      <van-pagination
        v-if="dataForm.data.total > 0"
        v-model="dataForm.currentPage"
        :total-items="dataForm.data.total"
        :items-per-page="10"
        :show-page-size="5"
        @change="getDataList"
      />
    </div>
  </van-pull-refresh>
</template>

<script>
// @ is an alias to /src
import { reactive, ref } from "vue";
import Api from "../util/App";
import { Notify, Dialog } from "vant";

export default {
  name: "Home",
  components: {},
  inject: ["setActive"],
  setup() {
    const dataForm = reactive({
      crowdType: "",
      checkedList: [],
      searchKey: "",
      community: "",
      communityList: [],
      crowdTypeList: [],
      currentPage: 1,
      data: {
        total: 0,
        list: [],
      },
    });

    const communityList = ref([]);
    const crowdTypeList = ref([]);

    const TagColors = {
      controlStep: {
        NoSegregation: {
          color: "#61D65E",
          bgColor: "#D6FFD5",
        },
        InHomeSegregation: {
          color: "#FF881C",
          bgColor: "#FFE1C5",
        },
        FocusSegregation: {
          color: "#FA5555",
          bgColor: "#FFE0E0",
        },
        HealthTest: {
          color: "#5ED6C8",
          bgColor: "#E7FFFC",
        },
        CancelReturnHf: {
          color: "#5ED6C8",
          bgColor: "#E7FFFC",
        },
      },
    };
    const isLoading = ref(false);
    const dicBeginTime = {
      Other: "returnDate",
      RedAndYellow: "redYellowDate",
      HighRisk: "returnDate",
      BjStudent: "arriveHomeTime",
      OutsideCity: "returnDate",
      OutsideProvince: "returnDate",
      Abroad: "returnDate",
      PositiveExposure: "lastContactDate",
      SecondTouch: "lastContactDate",
      Touch: "lastContactDate",
    };
    const dicBeginType = {
      returnDate: "返肥时间",
      redYellowDate: "红黄码转码时间",
      lastContactDate: "末次接触时间",
      arriveHomeTime: "到家时间",
    };
    return {
      dataForm,
      communityList,
      crowdTypeList,
      TagColors,
      isLoading,
      dicBeginTime,
      dicBeginType,
    };
  },
  mounted() {
    this.$emit("setActive", "alllist");
    this.$emit("changeTitle", "全部人员列表");
    this.dataForm.community =
      this.$route.query.community == undefined
        ? ""
        : this.$route.query.community;
    this.dataForm.crowdType =
      this.$route.query.crowdType == undefined
        ? ""
        : this.$route.query.crowdType;
    this.getCommunityList();
    this.getDataList();
  },
  activated() {
    this.$emit("setActive", "alllist");
    this.dataForm.searchKey = "";
    this.dataForm.community =
      this.$route.query.community == undefined
        ? ""
        : this.$route.query.community;
    this.dataForm.crowdType =
      this.$route.query.crowdType == undefined
        ? ""
        : this.$route.query.crowdType;
    this.getDataList();
  },

  methods: {
    getDataList() {
      Api.get28PersonList({
        limit: 10,
        page: this.dataForm.currentPage,
        community: this.dataForm.community,
        crowdType: this.dataForm.crowdType,
        personName: this.dataForm.searchKey,
      }).then((res) => {
        this.dataForm.data = res.data;
        this.isLoading = false;
      });
    },
    delDate() {
      if (this.dataForm.checkedList.length == 0) {
        Notify({ type: "danger", message: "至少选择一项" });
        return;
      }
      let ids = this.dataForm.checkedList;
      let that = this;
      Dialog.confirm({
        title: "标题",
        message: "确定删除选定数据？",
      }).then(() => {
        Api.deldata(ids).then((res) => {
          if (res.code == 0) {
            Notify({ type: "success", message: "成功删除!" });
            that.getDataList();
          }
        });
      });
    },
    details(item) {
      this.$router.push({
        name: "人员详细信息向导",
        query: {
          id: item.id,
          status: item.controlStatus,
          personType: "TwentyEight",
        },
      });
    },
    checkChange() {},
    initSelectData() {
      this.crowdTypeList = [];
      this.crowdTypeList = this.$store.state.Dict.find(
        (p) => p.dictType == "TwentyEightType"
      ).dataList;

      this.dataForm.crowdTypeList.push({ text: "50类人员", value: "" });
      this.crowdTypeList.forEach((element) => {
        this.dataForm.crowdTypeList.push({
          text: element.dictLabel,
          value: element.dictValue,
        });
      });
    },
    getCommunityList() {
      this.communityList = [];
      Api.getOrgCommunityList().then((res) => {
        this.communityList = res.data;
        this.dataForm.communityList.push({ text: "全部社区", value: "" });
        this.communityList.forEach((element) => {
          this.dataForm.communityList.push({
            text: element.name,
            value: element.id,
          });
        });
        setTimeout(() => {
          this.initSelectData();
          this.$forceUpdate();
        }, 1000);
      });
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(
          (p) => p.dictType == type
        ).dataList.find((p) => p.dictValue == value).dictLabel;
      } catch {
        return "";
      }
    },

    onSearch(val) {
      this.dataForm.currentPage = 1;
      this.dataForm.searchKey = val;
      this.getDataList();
    },
  },
};
</script>
<style scoped>
.dataList {
  background: #f8f8f8ff;
}
.van-checkbox-group {
  position: absolute;
  left: 9px;
  top: 45%;
}
.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
  padding-bottom: 10px;
  padding-left: 25px;
  position: relative;
}
.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}
.dataItem .r1 .leftTag {
  margin-right: 5px;
}
.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}
.dataItem .r2 .itemTitle {
  color: #999999;
}
.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
  min-height: 90vh;
}

.about >>> .dropitem {
  font-size: 12px !important;
}
</style>
