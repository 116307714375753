<template>
  <div class="about">
    <div
      style="
        font-size: 14px;
        color: #999;
        background: #f7f8fa;
        text-align: left;
        padding-left: 20px;
      "
    >
      统计截至 <font style="color: red">{{ timeFormat(new Date()) }}</font>
    </div>

    <van-row gutter="5" class="reportRow">
      <van-col
        :span="24 / transportDict.dataList.length"
        v-for="(item, index) in transportDict.dataList"
        :key="index"
      >
        <div class="reportCol">
          <van-row class="r1">
            <router-link
            :to="{
              name: '全部转运单',
              query: { tsstatus: item.dictValue },
            }" >{{
            dataForm.data[item.dictValue + "Total"]
          }}</router-link></van-row>
          <van-row class="r2">{{ item.dictLabel }}</van-row>
        </div>
      </van-col>
    </van-row>
    <van-row class="ListHead" gutter="20">
      <van-col span="8"> 社区/小区 </van-col>
      <van-col
        span="4"
        v-for="(item, index) in transportDict.dataList"
        v-html="item.dictLabel"
        :key="index"
      >
      </van-col>
       <van-col span="4"> </van-col>
    </van-row>
    <van-collapse v-model="activeNames">
      <van-collapse-item
        :name="index"
        v-for="(item, index) in dataForm.data.tjDtoList"
        :key="index"
      >
        <template #title>
          <van-row class="ListRow" gutter="20">
            <van-col span="8"> {{ item.name }} </van-col>
            <van-col
              span="4"
              v-for="(col, colindex) in transportDict.dataList"
              :key="colindex"
            >  <router-link
            :to="{
              name: '全部转运单',
              query: { tsstatus: col.dictValue,
              community:item.id },
            }" >
              {{ item[col.dictValue] }}</router-link>
            </van-col>
               <van-col span="4"> </van-col>
          </van-row>
        </template>

        <van-row
          class="ListRow ListChildren"
          gutter="20"
          v-for="(children, index) in item.childrenList"
          :key="index"
        >
          <van-col span="8"> {{ children.name }} </van-col>
          <van-col
            span="4"
            v-for="(col, colindex) in transportDict.dataList"
            :key="colindex"
          >
           <router-link
            :to="{
              name: '全部转运单',
              query: { tsstatus: col.dictValue,
              community:item.id,
              grid:children.id },
            }" >
            {{ children[col.dictValue] }}</router-link>
          </van-col>
           <van-col span="4"> </van-col>
        </van-row>
      </van-collapse-item>
    </van-collapse>
  </div>
</template> 
 
<script>
// @ is an alias to /src 
import { reactive, ref } from 'vue';
import Api from '../util/App';
//import { getPersoninfoDataList,posttest } from '../util/Api'
export default {
  name: 'Home',
  components: {
  },
  inject:['setActive'],
  setup() {
    const dataForm = reactive({
      params: {

        community: '',
        gridId: '',
        limit: 100,
        order: '',
        orderField: '',
        page: 1,
        status: ''
      },
      data: {
        "list": [
          //   {
          //     "id": "3401044000010000",
          //     "name": "万泽社区",
          //     "CentralizedIsolation": 0,
          //     "InHomeIsolation": 0,
          //     "HealthTesting": 0,
          //     "ReleasedControl": 0
          //   }
        ],

      }
    });
    let getDateList = () => {
      //posttest({"username":"admin","password":"admin","uuid":"e50547e4-1135-4bc6-8030-f9c54032a847","captcha":"www"})

      //dataForm.data=  getPersoninfoDataList({limit:10,listType:1,page:dataForm.currentPage,personName:dataForm.searchKey})
    }
    const onSearch = (val) => {
      dataForm.searchKey = val
      getDateList()
    };
    const timeFormat = function (time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return year + '年' + month + '月' + day + '日'  
    }
    const transportDict = ref({
      "id": "1513719424860971009",
      "dictType": "controlStatus",
      "dataList": [
        {
          "dictTypeId": "1513719424860971009",
          "dictLabel": "居家隔离中",
          "dictValue": "InHomeIsolation"
        },
        {
          "dictTypeId": "1513719424860971009",
          "dictLabel": "集中隔离中",
          "dictValue": "CentralizedIsolation"
        },
        {
          "dictTypeId": "1513719424860971009",
          "dictLabel": "已解控",
          "dictValue": "ReleasedControl"
        },
        {
          "dictTypeId": "1513719424860971009",
          "dictLabel": "健康检测中",
          "dictValue": "HealthTesting"
        }
      ]
    })
    const activeNames = ref([])
    return { dataForm, getDateList, onSearch, timeFormat, transportDict, activeNames }
  },
  mounted() {
    this.$emit('changeTitle', "全部人员列表",)
    this.controlPrsonnelTj();



  }, methods:
  {
    controlPrsonnelTj() {
      Api.getTransportinfoList(this.dataForm.params).then((res) => {
        this.dataForm.data = res.data
        this.transportDict = this.$store.state.Dict.find(p => p.dictType == 'TransportStatus')

      })

    }, getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(p => p.dictType == type).dataList.find(p => p.dictValue == value).dictLabel
      } catch {
        return ''
      }
    },

  }

}
</script>
<style scoped>
.about {
  background: #f8f8f8;
}
.dataItem {
  margin: 15px 10px 15px 10px;
  border: 1px solid rgb(212, 212, 212);
  padding-top: 10px;
  background: #f1e8e88f;
}
.dataItem .r1 .title {
  color: #1c1c1c;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}
.reportRow {
  width: 100%;
  text-align: center;
}
.reportRow .reportCol {
  width: 100%;
  margin: 5px;
  background: #ffffff;
  height: 80px;
  align-items: center;
}

.reportRow .reportCol .r1 {
  width: 100%;
  font-size: 18px;
  height: 60%;
  justify-content: center;
  align-items: flex-end;
  color: #5776e6;
  font-weight: 600;
  color: #5776e6;
  line-height: 24px;
}
.reportRow .reportCol .r2 {
  justify-content: center;
  align-items: center;
  height: 40%;
  font-size: 12px;
  font-weight: 500;
  color: #1b2026;
  line-height: 24px;
}
.reportRow .reportCol .r3 {
  width: 100%;
  font-size: 12px;
  height: 30%;
  justify-content: center;
  align-items: flex-start;
  color: #858ebd;
}
.reportRow .reportCol .r3 label {
  font-size: 12px;
  line-height: 18px;
}
.ListRow {
  
  padding: 5px;
  background: #ffffff;
}
.ListChildren{
  font-size:10px;
}
.ListHead {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px;
  background: #ffffff;
  font-size: 12px;
  color: #5a6080;
  font-weight: 400;
}
</style>
