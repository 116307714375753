<template>
  <van-form
    @failed="onFailed"
    @submit="submit"
  >
    <div style="background-color: #FFF;">
      <van-image
        width="48"
        height="48"
        style="margin-top: 20%;"
        :src="require('@/assets/jujia.png')"
      />
      <div style="font-size:18px;text-align:center;color: #5776E6;margin-top: 20px;font-weight: 600;">重点人群管控系统</div>
      <van-field
        v-model="phone"
        name="手机号"
        label="手机号"
        style="margin: 50px 0 50px 20px;"
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '请输入手机号'}]"
      />
      <van-field
        v-model="code"
        name="密码"
        label="密码"
        style="margin: 50px 0 50px 20px;"
        placeholder="请输入密码"
        :rules="[{ required: true, message: '请输入密码'}]"
      />

      <div style="margin: 16px">
        <van-button
          round
          block
          type="primary"
          :disabled="disBtn"
          native-type="submit"
        >
          进入体验
        </van-button>
      </div>
    </div>
  </van-form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
export default {
  setup() {
    const phone = ref("");
    const code = ref("");
    const router = useRouter();
    return { router, phone, code };
  },
  mounted() {
    window.localStorage.setItem("token", "");
    window.localStorage.setItem("role", "");
  },
  activated() {
    window.localStorage.setItem("token", "");
    window.localStorage.setItem("role", "");
  },
  methods: {
    submit() {
      window.location.href = "/?phone=" + this.phone + "&&code=" + this.code;
    },
  },
};
</script>

<style>
#app {
  font-family: ”Microsoft YaHei”, Arial, Helvetica, sans-serif, ”宋体”;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
  background: #f8f8f8;
  height: 100vh;
}
</style>