<template>
  <van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh="getDateList"
  >
    <div class="home">
      <div v-if="showTab == '待核查'">
        <div class="about">
          <van-search
            v-model="dataForm.tabs1.searchKey"
            @search="onTabs1Search"
            placeholder="请输入搜索关键词"
          />
          <div
            style="
              font-size: 14px;
              color: #999;
              background: #f7f8fa;
              text-align: left;
              padding-left: 20px;
            "
          >
            为你筛选出<font style="color: red">{{
              dataForm.tabs1.data.total
            }}</font
            >条结果
          </div>
        </div>
        <div class="dataList">
          <router-link
            :to="{
              name: '人员详细信息向导',
              query: { id: item.id, status: item.controlStatus },
            }"
            v-for="(item, index) in dataForm.tabs1.data.list"
            :key="index"
          >
           <div class="dataItem">
          <van-row class="r1">
            <span class="title">{{ item.personName }}</span>

            <van-tag
              class="leftTag"
              :color="TagColors.controlStep[item.controlStep].bgColor"
              :text-color="TagColors.controlStep[item.controlStep].color"
              v-if="item.controlStep != null&&item.controlStep != ''"
              type="primary"
              >{{ this.getDictLabel("controlStep", item.controlStep) }}</van-tag
            >
            <van-tag class="leftTag" color="#E8EBFF" text-color="#5776E6"
               v-if="item.personType != null&&item.personType != ''"
            >{{
              this.getDictLabel("PeopleType", item.personType)
            }}</van-tag>
            <van-tag class="rightTag" color="#E8EBFF" text-color="#5776E6"
                v-if="item.controlStatus != null&&item.controlStatus != ''"
            >{{
              this.getDictLabel("controlStatus", item.controlStatus)
            }}</van-tag>
          </van-row>
          <van-row class="r2">
            <van-col span="10">
              <div class="itemTitle">证件号码</div>
              <div class="itemValue">{{ item.idCard }}</div>
            </van-col>
            <van-col class="colMiddle" span="6">
              <div class="itemTitle">手机号码</div>
              <div class="itemValue">{{ item.phone }}</div>
            </van-col>
            <van-col span="8">
              <div class="itemTitle">
                {{ dicBeginType[this.dicBeginTime[item.personType]] }}
              </div>
              <div class="itemValue">
                {{ item[this.dicBeginTime[item.personType]] }}
              </div>
            </van-col>
          </van-row>
        </div>
          </router-link>
          <van-pagination
            v-if="dataForm.tabs1.data.total > 0"
            v-model="dataForm.tabs1.currentPage"
            :total-items="dataForm.tabs1.data.total"
            :items-per-page="10"
            :show-page-size="5"
            @change="getTab1DateList"
          />
        </div>
      </div>
      <div v-if="showTab == '转运执行'">
        <van-tabs style="margin-top: 10px" v-model:active="activeTransport">
          <van-tab title="待转运">
            <div class="about">
              <van-search
                v-model="dataForm.tabs2.searchKey"
                @search="onTabs2Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font style="color: red">{{
                  dataForm.tabs2.data.total
                }}</font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <div
                class="dataItem"
                v-for="(item, index) in dataForm.tabs2.data.list"
                :key="index"
              >
                <router-link
                  :to="{ name: '转运单详情', query: { id: item.id } }"
                >
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel("TransportType", item.carType)
                    }}</van-tag>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="10">
                      <div class="itemTitle">证件号码</div>
                      <div class="itemValue">{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div class="itemTitle">手机号码</div>
                      <div class="itemValue">{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <!-- <div class="itemTitle">所属社区</div>
                <div class="itemValue">{{ item.communityName }}</div> -->
                    </van-col>
                  </van-row>
                  <!-- <van-row class="r2">
              <van-col span="8">
                <div class="itemTitle">出发地</div>
              </van-col>
              <van-col span="16">
                <div>{{ item.startPlace }}</div>
              </van-col>
            </van-row> -->
                  <van-row class="r2">
                    <van-col span="8">
                      <div class="itemTitle">目的地</div>
                    </van-col>
                    <van-col span="16">
                      <div>{{ item.destination }}</div>
                    </van-col>
                  </van-row>
                </router-link>
              </div>
              <van-pagination
                v-if="dataForm.tabs2.data.total > 0"
                v-model="dataForm.tabs2.currentPage"
                :total-items="dataForm.tabs2.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab2DateList"
              />
            </div>
          </van-tab>
          <van-tab title="转运中">
            <div class="about">
              <van-search
                v-model="dataForm.tabs2_2.searchKey"
                @search="onTabs2_2Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font
                  style="color: red"
                  v-html="dataForm.tabs2_2.data.total"
                ></font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <router-link
                :to="{ name: '转运单填报', query: { id: item.id } }"
                v-for="(item, index) in dataForm.tabs2_2.data.list"
                :key="index"
              >
                <div class="dataItem">
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel("TransportType", item.carType)
                    }}</van-tag>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="10">
                      <div class="itemTitle">证件号码</div>
                      <div class="itemValue">{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div class="itemTitle">手机号码</div>
                      <div class="itemValue">{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <div class="itemTitle">转运开始时间</div>
                      <div class="itemValue">{{ item.transportTime }}</div>
                    </van-col>
                  </van-row>
                  <!-- <van-row class="r2">
                    <van-col span="8">
                      <div class="itemTitle">出发地</div>
                    </van-col>
                    <van-col span="14">
                      <div class="itemValueH">{{ item.startPlace }}</div>
                    </van-col>
                  </van-row> -->
                  <van-row class="r2">
                    <van-col span="8">
                      <div class="itemTitle">目的地</div>
                    </van-col>
                    <van-col span="14">
                      <div class="itemValueH">{{ item.destination }}</div>
                    </van-col>
                  </van-row>
                </div>
              </router-link>
              <van-pagination
                v-if="dataForm.tabs2_2.data.total > 0"
                v-model="dataForm.tabs2_2.currentPage"
                :total-items="dataForm.tabs2_2.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab2_2DateList"
              /></div
          ></van-tab>
          <van-tab title="已转运">
            <div class="about">
              <van-search
                v-model="dataForm.tabs2_3.searchKey"
                @search="onTabs2_3Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font
                  style="color: red"
                  v-html="dataForm.tabs2_3.data.total"
                ></font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <router-link
                :to="{ name: '转运单详情', query: { id: item.id } }"
                v-for="(item, index) in dataForm.tabs2_3.data.list"
                :key="index"
              >
                <div class="dataItem">
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel("TransportType", item.carType)
                    }}</van-tag>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="10">
                      <div class="itemTitle">证件号码</div>
                      <div class="itemValue">{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div class="itemTitle">手机号码</div>
                      <div class="itemValue">{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <div class="itemTitle">转运完成时间</div>
                      <div class="itemValue">{{ item.arriveTime }}</div>
                    </van-col>
                  </van-row>
                  <!-- <van-row class="r2">
                    <van-col span="8">
                      <div class="itemTitle">出发地</div>
                    </van-col>
                    <van-col span="16">
                      <div>{{ item.startPlace }}</div>
                    </van-col>
                  </van-row> -->
                  <van-row class="r2">
                    <van-col span="8">
                      <div class="itemTitle">目的地</div>
                    </van-col>
                    <van-col span="16">
                      <div>{{ item.destination }}</div>
                    </van-col>
                  </van-row>
                </div>
              </router-link>
              <van-pagination
                v-if="dataForm.tabs2_3.data.total > 0"
                v-model="dataForm.tabs2_3.currentPage"
                :total-items="dataForm.tabs2_3.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab2_3DateList"
              /></div
          ></van-tab>
        </van-tabs>
      </div>
      <div v-if="showTab == '每日上报'">
        <van-tabs style="margin-top: 10px" v-model:active="activeVh">
          <van-tab title="填报任务">
            <div class="about">
              <van-search
                v-model="dataForm.tabs3.searchKey"
                @search="onTabs3Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font style="color: red">{{
                  dataForm.tabs3.data.total
                }}</font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <router-link
                :to="{
                  name: '任务填报',
                  params: { pinfo: JSON.stringify(item) },
                }"
                v-for="(item, index) in dataForm.tabs3.data.list"
                :key="index"
              >
                <div class="dataItem">
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="leftTag" type="primary">
                      {{
                        this.getDictLabel("controlStatus", item.controlStatus)
                      }}
                    </van-tag>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel("controlStep", item.controlStep)
                    }}</van-tag>
                  </van-row>

                  <van-row class="r2">
                    <van-col span="10">
                      <div class="itemTitle">证件号码</div>
                      <div class="itemValue">{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div class="itemTitle">手机号码</div>
                      <div class="itemValue">{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <div class="itemTitle">上次填报时间</div>
                      <div class="itemValue">{{ item.voucherDate }}</div>
                    </van-col>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="6">
                      <div class="itemTitle">第一次体温</div>
                    </van-col>
                    <van-col span="6">
                      <div>{{ item.firTemp }}℃</div>
                    </van-col>
                    <van-col span="6">
                      <div class="itemTitle">第二次体温</div>
                    </van-col>
                    <van-col span="6">
                      <div>{{ item.secTemp }}℃</div>
                    </van-col>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="6">
                      <div class="itemTitle">核酸证明图片</div>
                    </van-col>
                    <van-col span="6">
                      <div>
                        <van-image
                          width="60"
                          height="60"
                          :src="item.voucherImg"
                          @click="
                            ImagePreview({
                              images: [item.voucherImg],
                              closeable: true,
                            })
                          "
                        />
                      </div>
                    </van-col>

                    <van-col span="6">
                      <div class="itemTitle">解除隔离证明</div>
                    </van-col>
                    <van-col span="6">
                      <div>
                        <van-image
                          width="60"
                          height="60"
                          :src="item.releaseCertificate"
                          @click="
                            ImagePreview({
                              images: [item.releaseCertificate],
                              closeable: true,
                            })
                          "
                        />
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </router-link>
              <van-pagination
                v-if="dataForm.tabs3.data.total > 0"
                v-model="dataForm.tabs3.currentPage"
                :total-items="dataForm.tabs3.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab3DateList"
              /></div
          ></van-tab>
          <van-tab title="填报记录">
            <div class="about">
              <van-search
                v-model="dataForm.tabs3_2.searchKey"
                @search="onTabs3_2Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font style="color: red">{{
                  dataForm.tabs3_2.data.total
                }}</font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <div
                class="dataItem"
                v-for="(item, index) in dataForm.tabs3_2.data.list"
                :key="index"
              >
                <van-row class="r1">
                  <span class="title">{{ item.personName }}</span>
                  <van-tag class="leftTag" type="primary">
                    {{ this.getDictLabel("controlStatus", item.controlStatus) }}
                  </van-tag>
                  <van-tag class="rightTag" type="warning">{{
                    this.getDictLabel("controlStep", item.controlStep)
                  }}</van-tag>
                </van-row>
                <van-row class="r2">
                  <van-col span="10">
                    <div class="itemTitle">证件号码</div>
                    <div class="itemValue">{{ item.idCard }}</div>
                  </van-col>
                  <van-col class="colMiddle" span="6">
                    <div class="itemTitle">手机号码</div>
                    <div class="itemValue">{{ item.phone }}</div>
                  </van-col>
                  <van-col span="8">
                    <div class="itemTitle">上次填报时间</div>
                    <div class="itemValue">{{ item.voucherDate }}</div>
                  </van-col>
                </van-row>
                <van-row class="r2">
                  <van-col span="6">
                    <div class="itemTitle">第一次体温</div>
                  </van-col>
                  <van-col span="6">
                    <div>{{ item.firTemp }}℃</div>
                  </van-col>
                  <van-col span="6">
                    <div class="itemTitle">第二次体温</div>
                  </van-col>
                  <van-col span="6">
                    <div>{{ item.secTemp }}℃</div>
                  </van-col>
                </van-row>
                <van-row class="r2">
                  <van-col span="6">
                    <div class="itemTitle">核酸证明图片</div>
                  </van-col>
                  <van-col span="6">
                    <div>
                      <van-image
                        width="60"
                        height="60"
                        :src="item.voucherImg"
                        @click="
                          ImagePreview({
                            images: [item.voucherImg],
                            closeable: true,
                          })
                        "
                      />
                    </div>
                  </van-col>

                  <van-col span="6">
                    <div class="itemTitle">解除隔离证明</div>
                  </van-col>
                  <van-col span="6">
                    <div>
                      <van-image
                        width="60"
                        height="60"
                        :src="item.releaseCertificate"
                        @click="
                          ImagePreview({
                            images: [item.releaseCertificate],
                            closeable: true,
                          })
                        "
                      />
                    </div>
                  </van-col>
                </van-row>
              </div>

              <van-pagination
                v-if="dataForm.tabs3_2.data.total > 0"
                v-model="dataForm.tabs3_2.currentPage"
                :total-items="dataForm.tabs3_2.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab3_2DateList"
              /></div
          ></van-tab>
        </van-tabs>
      </div>
      <div v-if="showTab == '门磁监控'">
        <div class="about">
          <van-search
            v-model="dataForm.tabs4.searchKey"
            @search="onTabs4Search"
            placeholder="请输入搜索关键词"
          />
          <div
            style="
              font-size: 14px;
              color: #999;
              background: #f7f8fa;
              text-align: left;
              padding-left: 20px;
            "
          >
            为你筛选出<font style="color: red">{{
              dataForm.tabs4.data.total
            }}</font
            >条结果
          </div>
        </div>
        <div class="dataList">
          <div
            class="dataItem"
            v-for="(item, index) in dataForm.tabs4.data.list"
            :key="index"
          >
            <van-row class="r1">
              <span class="title">{{ item.personName }}</span>
              <van-tag
                class="leftTag"
                v-if="item.voucherType != null"
                type="primary"
                >{{ SensorDataTypeArray[item.dataType] }}</van-tag
              >
              <van-tag class="rightTag" type="warning">{{
                this.getDictLabel("controlStatus", item.controlStatus)
              }}</van-tag>
            </van-row>
            <van-row class="r2">
              <van-col span="10">
                <div class="itemTitle">报警时间</div>
                <div class="itemValue">{{ item.signTime }}</div>
              </van-col>
              <van-col class="colMiddle" span="6">
                <div class="itemTitle">手机号码</div>
                <div class="itemValue">{{ item.phone }}</div>
              </van-col>
              <van-col span="8">
                <div class="itemTitle">报警事件</div>
                <div class="itemValue">{{ item.eventName }}</div>
              </van-col>
            </van-row>
          </div>

          <van-pagination
            v-if="dataForm.tabs4.data.total > 0"
            v-model="dataForm.tabs4.currentPage"
            :total-items="dataForm.tabs4.data.total"
            :items-per-page="10"
            :show-page-size="5"
            @change="getTab4DateList"
          />
        </div>
      </div>
    </div>
  </van-pull-refresh>
</template>

<script>
// @ is an alias to /src
import { reactive, ref } from "vue";
import Api from "../util/App";
export default {
  name: "Home",
  components: {},
  inject: ["setActive"],
  setup() {
    const dataForm = reactive({
      tabs1: {
        searchKey: "",
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //  {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ],
        },
      },
      tabs2: {
        searchKey: "",
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //  {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ],
        },
      },
      tabs2_2: {
        searchKey: "",
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ],
        },
      },
      tabs2_3: {
        searchKey: "",
        currentPage: 1,
        data: {
          total: 0,
          list: [
            // {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ],
        },
      },
      tabs3: {
        searchKey: "",
        currentPage: 1,
        data: {
          total: 0,
          list: [
            // {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ],
        },
      },
      tabs3_2: {
        searchKey: "",
        currentPage: 1,
        data: {
          total: 0,
          list: [
            // {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ],
        },
      },
      tabs4: {
        searchKey: "",
        currentPage: 1,
        data: {
          total: 0,
          list: [
            // {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ],
        },
      },
      tabs5: {
        searchKey: "",
        currentPage: 1,
        data: {
          total: 0,
          list: [
            // {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ],
        },
      },
    });

    const dicPersonTypeLabel = [
       "阳性病例",
      "红码人员",
      "返肥人员",
      "中高风险地区返回",
      "境外人员",
      "次密接",
      "密接",
      "北京/上海大学生",
    ];

    const dicPersonType = [
      'Positive',
      "RedCode",
      "ReturnHf",
      "HighRisk",
      "Abroad",
      "SecondTouch",
      "Touch",
      "BjStudent",
    ];
    const dicBeginType = { 'returnDate': "返肥时间", 'redYellowDate': "转码时间", 'lastContactDate': "末次接触时间",'arriveHomeTime':'到家时间' }
    const TagColors = {
      controlStep: {
        NoSegregation: {
          color: "#61D65E",
          bgColor: "#D6FFD5"
        }, InHomeSegregation: {
          color: "#FF881C",
          bgColor: "#FFE1C5"
        },
        FocusSegregation: {
          color: "#FA5555",
          bgColor: "#FFE0E0"
        },
        HealthTest: {
          color: "#5ED6C8",
          bgColor: "#E7FFFC"
        },
            CancelReturnHf:{
           color: "#5ED6C8",
          bgColor: "#E7FFFC"
        }
      }
    }
    const dicBeginTime = { 'Other': "returnDate", 'RedAndYellow': "redYellowDate", 'HighRisk': "returnDate",  'BjStudent': "arriveHomeTime", "OutsideCity": "returnDate", "OutsideProvince": "returnDate", "Abroad": "returnDate", 'PositiveExposure': "lastContactDate", 'SecondTouch': "lastContactDate", 'Touch': "lastContactDate" }

    const SensorDataTypeArray = ["已关门", "故障", "已开门"];
    const SensorDeviceStateArray = ["正常", "故障", "报警", "离线"];
    const activeTransport = ref("");
    const role = ref("");
    const isLoading = ref(false);
    const showTab = ref("");
    return {
      TagColors,
      dicBeginTime,
      dicBeginType,
      dataForm,
      isLoading,
      showTab,
      dicPersonTypeLabel,
      dicPersonType,
      activeTransport,
      SensorDataTypeArray,
      SensorDeviceStateArray,
      role,
    };
  },
  mounted() {
    this.dataForm.tabs3.searchKey = "";
    this.dataForm.tabs3_2.searchKey = "";
    this.$emit("changeTitle", "待核查人员信息");
    this.$emit("setActive", "todolist");
    localStorage.setItem(
      "personType",
      this.$router.currentRoute.value.params.personType
    );
    this.role = window.localStorage.getItem("role");
    this.showTab = this.$router.currentRoute.value.params.type;
    if(this.showTab=='待核查'){
      document.title='未读人员'
    }
     if(this.showTab=='转运执行'){
      document.title='转运列表'
    }
     if(this.showTab=='门磁监控'){
      document.title='门磁列表'
    }
    this.getTab1DateList();
    this.getTab2DateList();
    this.getTab2_2DateList();
    this.getTab2_3DateList();
    this.getTab3DateList();
    this.getTab3_2DateList();
    this.getTab4DateList();
    this.getTab5DateList();
  },
  activated() {
    this.$emit("setActive", "todolist");
    this.role = window.localStorage.getItem("role");
    if (this.$router.currentRoute.value.params.type != null) {
      this.showTab = this.$router.currentRoute.value.params.type;
    }
    if (this.$router.currentRoute.value.params.personType!=undefined) {
      localStorage.setItem(
        "personType",
        this.$router.currentRoute.value.params.personType
      );
    }
     if(this.showTab=='待核查'){
      document.title='未读人员'
    }
     if(this.showTab=='转运执行'){
      document.title='转运列表'
    }
     if(this.showTab=='门磁监控'){
      document.title='门磁列表'
    }
    this.dataForm.tabs3.searchKey = "";
    this.dataForm.tabs3_2.searchKey = "";
    this.getTab1DateList();
    this.getTab2DateList();
    this.getTab2_2DateList();
    this.getTab2_3DateList();
    this.getTab3DateList();
    this.getTab3_2DateList();
    this.getTab4DateList();
    this.getTab5DateList();
  },
  methods: {
    getDateList() {
      this.getTab1DateList();
      this.getTab2DateList();
      this.getTab2_2DateList();
      this.getTab2_3DateList();
      this.getTab3DateList();
      this.getTab3_2DateList();
      this.getTab4DateList();
      this.getTab5DateList();
      let that = this;
      setTimeout((that.isLoading = false), 1000);
    },
    onTabs1Search(val) {
      this.dataForm.tabs1.searchKey = val;
      this.getTab1DateList();
    },
    getTab1DateList() {
      let json={
         limit: 10,
        listType: 2,
        page: this.dataForm.tabs1.currentPage,
        personName: this.dataForm.tabs1.searchKey,
        isRead: 0,
      }
      if(this.role == "GridPerson" )json.isLeader=0;
      Api.getPersonList(json).then((res) => {
        this.dataForm.tabs1.data = res.data;
      });
    },
    onTabs2Search(val) {
      this.dataForm.tabs2.searchKey = val;
      this.getTab2DateList();
    },
    getTab2DateList() {
      console.log(2)
      let json={
        limit: 10,
        page: this.dataForm.tabs2.currentPage,
        personName: this.dataForm.tabs2.searchKey,
        status: "NotTransshipped",
        transportType: 1,
      }
      if(this.role == "GridPerson" )json.isLeader=0;
      Api.gettspage(json).then((res) => {
        this.dataForm.tabs2.data = res.data;
      });
    },

    onTabs2_2Search(val) {
      this.dataForm.tabs2_2.searchKey = val;
      this.getTab2_2DateList();
    },
    getTab2_2DateList() {
       console.log(3)
        let json={
         limit: 10,
        page: this.dataForm.tabs2_2.currentPage,
        personName: this.dataForm.tabs2_2.searchKey,
        status: "InTransit",
      }
      if(this.role == "GridPerson" )json.isLeader=0;
      Api.gettspage(json).then((res) => {
        this.dataForm.tabs2_2.data = res.data;
      });
    },

    onTabs2_3Search(val) {
      this.dataForm.tabs2_2.searchKey = val;
      this.getTab2_3DateList();
    },
    getTab2_3DateList() {
       console.log(4)
        let json={
          limit: 10,
        page: this.dataForm.tabs2_3.currentPage,
        personName: this.dataForm.tabs2_3.searchKey,
        status: "Transshipped",
      }
      if(this.role == "GridPerson" )json.isLeader=0;
      Api.gettspage(json).then((res) => {
        this.dataForm.tabs2_3.data = res.data;
      });
    },

    onTabs3Search(val) {
      this.dataForm.tabs3.searchKey = val;
      this.getTab3DateList();
    },
    getTab3DateList() {
      console.log(1)
      if (localStorage.getItem("personType") == "TwentyEight") {
        Api.getvhpage28({
          limit: 10,
          dataType: 1,
          page: this.dataForm.tabs3.currentPage,
          personName: this.dataForm.tabs3.searchKey,
        }).then((res) => {
          this.dataForm.tabs3.data = res.data;
        });
      } else {
        let json={
           limit: 10,
          dataType: 1,
          page: this.dataForm.tabs3.currentPage,
          personName: this.dataForm.tabs3.searchKey,
        }
         if(this.role == "GridPerson" )json.isLeader=0;
        Api.getvhpage(json).then((res) => {
          this.dataForm.tabs3.data = res.data;
        });
      }
    },
    onTabs3_2Search(val) {
      this.dataForm.tabs3_2.searchKey = val;
      this.getTab3_2DateList();
    },
    getTab3_2DateList() {
      if (localStorage.getItem("personType") == "TwentyEight") {
        Api.getvhpage28({
          limit: 10,
          dataType: 2,
          page: this.dataForm.tabs3.currentPage,
          personName: this.dataForm.tabs3_2.searchKey,
        }).then((res) => {
          this.dataForm.tabs3_2.data = res.data;
        });
      } else {
         console.log(2)
          let json={
           limit: 10,
          dataType: 2,
          page: this.dataForm.tabs3_2.currentPage,
          personName: this.dataForm.tabs3_2.searchKey,
        }
         if(this.role == "GridPerson" )json.isLeader=0;
        Api.getvhpage(json).then((res) => {
          this.dataForm.tabs3_2.data = res.data;
        });
      }
    },
    onTabs4Search(val) {
      this.dataForm.tabs4.searchKey = val;
      this.getTab4DateList();
    },
    getTab4DateList() {
      Api.getRecentList({
        limit: 10,
        page: this.dataForm.tabs4.currentPage,
        keyword: this.dataForm.tabs4.searchKey,
      }).then((res) => {
        this.dataForm.tabs4.data = res.data;
      });
    },
    onTabs5Search(val) {
      this.dataForm.tabs5.searchKey = val;
      this.getTab5DateList();
    },
    getTab5DateList() {
      Api.getPersonList({
        limit: 10,
        listType: 2,
        page: this.dataForm.tabs5.currentPage,
        personName: this.dataForm.tabs5.searchKey,
        status: "",
        approveStatus: 1,
      }).then((res) => {
        this.dataForm.tabs5.data = res.data;
      });
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(
          (p) => p.dictType == type
        ).dataList.find((p) => p.dictValue == value).dictLabel;
      } catch {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.dataList {
  background: #f8f8f8ff;
}
.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
  padding-bottom: 10px;
}
.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}
.dataItem .r1 .leftTag {
  margin-right: 5px;
}
.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}
.dataItem .r2 .itemTitle {
  color: #999999;
}
.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
  min-height: 90vh;
}
</style>