<template>
  <van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh=" 
      controlPrsonnelTj();
    "
  >
    <div class="about">
      <div style="
        font-size: 12px;
        color: #999;
        background: #f7f8fa;
        text-align: left;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
      ">
        统计截至 <font style="color: red">{{ timeFormat(new Date()) }}</font>
      </div>
      <van-tabs color="#5776e6">

        <van-tab :title="'人员状态('+dataForm.totalPerson+')'" >
          <van-row class="reportRow">
            <van-col
              span="8"
              v-for="(item, index) in controlStatusDict.dataList"
              :key="index"
            >
              <div class="reportCol" style="height:100px">
                <router-link
                  :to="{
              name: '全部人员列表',
              query: { controlStatus: item.dictValue,isfive:item.dictValue },
            }"
                  v-if="
              item.dictValue != 'NotChecked' &&
              item.dictValue != 'NoSegregation'
            "
                >
                  <van-row :class="'r1 item' + index" style="padding-top:0">{{
              dataForm.data[item.dictValue + "-total"]
            }}</van-row>
                </router-link>
                <router-link
                  :to="{
              name: '全部人员列表',
              query: {
                controlStep:
                  item.dictValue == 'NoSegregation' ? 'NoSegregation' : '',
                status: item.dictValue == 'NotChecked' ? '1' : '',
              },
            }"
                  v-if="
              item.dictValue == 'NotChecked' ||
              item.dictValue == 'NoSegregation'
            "
                >
                  <van-row :class="'r1 item' + index" style="padding-top:0">{{
              dataForm.data[item.dictValue + "-total"]
            }}</van-row>
                </router-link>
                <van-row class="r2">{{ item.dictLabel }}</van-row>
                <van-row class="r2" style="color:#f33;margin:-3px 0">(阳性病例)</van-row>
                <van-row class="r3">今日新增<label style="'color:#61D65E">{{ dataForm.data[item.dictValue + "-today"] }}
                  </label></van-row>
              </div>
            </van-col>

          </van-row>
        </van-tab>

        <van-tab :title="'人员类型('+dataForm.totalPerson+')'">
          <van-row class="reportRow">
            <van-col
              span="8"
              v-for="(item, index) in personTypeDict.dataList"
              :key="index"
            >
              <div class="reportCol">
                <router-link :to="{
              name: '全部人员列表',
              query: { type: item.dictValue },
            }">
                  <van-row :class="'r1 item' + index">{{
              dataForm.data[item.dictValue+'Count']
            }}</van-row>
                </router-link>
                <van-row class="r2">{{ item.dictLabel }}</van-row>
                <van-row class="r3">今日新增<label style="'color:#61D65E">{{ dataForm.data[item.dictValue + "CountToday"] }}
                  </label></van-row>
              </div>
            </van-col>

          </van-row>
        </van-tab>

        <van-tab :title="'门磁('+dataForm.totalSensor+')'">
          <van-row class="reportRow">
            <van-col span="12">
              <div class="reportCol">
                <router-link :to="{
              name: '全部人员列表',
              query: { device: 3 },
            }">
                  <van-row class="r1 item6">{{
              dataForm.data["DoorSensorNum-total"]
            }}</van-row>
                </router-link>
                <van-row class="r2">应安装门磁</van-row>
                <van-row class="r3">今日新增<label style="'color:#61D65E">{{ dataForm.data["DoorSensorNum-today"] }}
                  </label></van-row>
              </div>
            </van-col>
            <van-col span="12">
              <div class="reportCol">
                <router-link :to="{
              name: '全部人员列表',
              query: { device: 2 },
            }">
                  <van-row class="r1 item7">{{
              dataForm.data["HasInstalledDoorSensorNum-total"]
            }}</van-row>
                </router-link>
                <van-row class="r2">已安装门磁</van-row>
                <van-row class="r3">今日新增<label style="'color:#61D65E">{{ dataForm.data["HasInstalledDoorSensorNum-today"] }}
                  </label></van-row>
              </div>
            </van-col>

          </van-row>
        </van-tab>
      </van-tabs>
      <div class="body">
        <van-row style="font-size:14px;color:#313836;border-bottom: 1px solid #EEEEEE;height:20px;padding-bottom: 5px;">
          <div style="background:#5776E6; height: 20px;
    width: 6px;margin-right: 10px;border-radius: 5px;"></div>重点人员类型一览表
        </van-row>
        <van-row
          class="ListHead"
          gutter="20"
        >
          <van-col span="6"> 人员类型 </van-col>
          <van-col
            span="3"
            v-for="(item, index) in controlStatusDict.dataList"
            v-html="item.dictLabel"
            :key="index"
          >
          </van-col>
        </van-row>
        <van-row
          class="ListRow group"
          gutter="10"
        >
          <van-col span="6">接触人群<van-icon name="arrow-up" @click="zk=!zk" v-if="zk"/><van-icon name="arrow-down" @click="zk=!zk" v-else /></van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            {{
            parseInt(
              this.dataForm.data.list.find((p) => p.personType == "Touch")[
                col.dictValue
              ]
            ) +
            parseInt(
              this.dataForm.data.list.find(
                (p) => p.personType == "SecondTouch"
              )[col.dictValue]
            )+
              parseInt(
              this.dataForm.data.list.find(
                (p) => p.personType == "Positive"
              )[col.dictValue]
            )
          }}
          </van-col>
        </van-row>
        <div v-if="zk">
           <van-row
          class="ListRow"
          gutter="20"
        >
          <van-col span="6">阳性</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'Positive' },
            }">
              {{
              this.dataForm.data.list.find((p) => p.personType == "Positive")[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col>
        </van-row>
        <van-row
          class="ListRow"
          gutter="20"
        >
          <van-col span="6">密接</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'Touch' },
            }">
              {{
              this.dataForm.data.list.find((p) => p.personType == "Touch")[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col>
        </van-row>
        <van-row
          class="ListRow"
          gutter="20"
        >
          <van-col span="6">次密接</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'SecondTouch' },
            }">
              {{
              this.dataForm.data.list.find(
                (p) => p.personType == "SecondTouch"
              )[col.dictValue]
            }}
            </router-link>
          </van-col>
        </van-row>
        </div>
        <van-row
          class="ListRow group"
          gutter="10"
        >
          <van-col span="6">外地返回<van-icon name="arrow-up" @click="zk2=!zk2" v-if="zk2"/><van-icon name="arrow-down" @click="zk2=!zk2" v-else /></van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            {{
            parseInt(
              this.dataForm.data.list.find((p) => p.personType == "Abroad")[
                col.dictValue
              ]
            ) +
            parseInt(
              this.dataForm.data.list.find((p) => p.personType == "HighRisk")[
                col.dictValue
              ]
            ) +
            parseInt(
              this.dataForm.data.list.find((p) => p.personType == "ReturnHf")[
                col.dictValue
              ]
            )
          }}
          </van-col>
        </van-row>
<div v-show="zk2">
        <van-row
          class="ListRow"
          gutter="10"
        >
          <van-col span="6">境外</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'Abroad' },
            }">
              {{
              this.dataForm.data.list.find((p) => p.personType == "Abroad")[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col>
        </van-row>
        <van-row
          class="ListRow"
          gutter="20"
        >
          <van-col span="6">中高风险</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'HighRisk' },
            }">
              {{
              this.dataForm.data.list.find((p) => p.personType == "HighRisk")[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col>
        </van-row>
        <van-row
          class="ListRow"
          gutter="20"
        >
          <van-col span="6">返肥</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'ReturnHf' },
            }">
              {{
              this.dataForm.data.list.find((p) => p.personType == "ReturnHf")[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col>
        </van-row>
      </div>
        <!-- <van-row
          class="ListRow"
          gutter="20"
        >
          <van-col span="6">北京/上海大学生</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'BjStudent' },
            }">
              {{
              this.dataForm.data.list.find((p) => p.personType == "BjStudent")[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col>
        </van-row> -->
        <van-row
          class="ListRow group"
          gutter="10"
        >
          <van-col span="6">红码<van-icon name="arrow-up" @click="zk3=!zk3" v-if="zk3"/><van-icon name="arrow-down" @click="zk3=!zk3" v-else /></van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            {{
            parseInt(
              this.dataForm.data.list.find((p) => p.personType == "RedCode")[
                col.dictValue
              ]
            )
          }}
          </van-col>
        </van-row>
        <van-row
          class="ListRow"
          gutter="20"
          v-show="zk3"
        >
          <van-col span="6">红码</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'RedCode' },
            }">
              {{
              this.dataForm.data.list.find((p) => p.personType == "RedCode")[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col>
        </van-row>
        <!-- 28 -->
        <van-row
          class="ListRow group"
          gutter="10"
        >
          <van-col span="6">50类人群<van-icon name="arrow-up" @click="zk4=!zk4" v-if="zk4"/><van-icon name="arrow-down" @click="zk4=!zk4" v-else /></van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            {{
            parseInt(
              this.dataForm.data.list.find((p) => p.personType == "TwentyEight")[
                col.dictValue
              ]
            )
          }}
          </van-col>
        </van-row>
        <div  v-show="zk4">
        <van-row class="ListRow">
        <van-col span="6">50类人群<van-icon name="arrow-up" @click="zk4_=!zk4_" v-if="zk4_"/><van-icon name="arrow-down" @click="zk4_=!zk4_" v-else /></van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            {{
            parseInt(
              this.dataForm.data.list.find((p) => p.personType == "TwentyEight")[
                col.dictValue
              ]
            )
          }}
          </van-col>
        </van-row>
        <van-row
          class="ListRow"
          style="background:#F7F9FF"
          gutter="20"
          v-show="zk4_"
        >

         <!-- <van-col span="6">红码</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'RedCode' },
            }">
              {{
              this.dataForm.data.list.find((p) => p.personType == "RedCode")[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col> -->
         <template v-for="(item,index) in this.crowdList" :key="index">
          <van-col span="6">{{this.getDictLabel('TwentyEightType',item.crowdType)}}</van-col>
          <van-col
            span="3"
            v-for="(col, colindex) in controlStatusDict.dataList"
            :key="colindex"
          >
            <router-link :to="{
              name: '管控状态统计',
              query: { personType: 'TwentyEight',crowdType:item.crowdType },
            }">
              {{
             item[
                col.dictValue
              ]
            }}
            </router-link>
          </van-col>
        </template>
        </van-row>
      </div>
      </div>
    </div>
  </van-pull-refresh>
</template> 
 
<script>
// @ is an alias to /src
import { reactive, ref } from "vue";
import Api from "../util/App";
import parseJSON from "date-fns/esm/fp/parseJSON";
//import { getPersoninfoDataList,posttest } from '../util/Api'
export default {
  name: "Home",
  components: {},
  inject: ["setActive"],
  setup() {
    const zk=ref(false);
    const zk2=ref(false)
    const zk3=ref(false)
    const zk4=ref(false)
    const zk4_=ref(false)
    const crowdList=ref([]);
    const dataForm = reactive({
      searchKey: "",
      searchState: 0,
      searchType: 0,
      currentPage: 1,
      totalPerson: 0,
      totalSensor: 0,
      data: {
        "InHomeIsolation-today": 0,
        "CentralizedIsolation-total": 0,
        list: [
          {
            ReleasedControl: 0,
            NoSegregation: 0,
            CentralizedIsolation: 0,
            InHomeIsolation: 0,
            NotChecked: 0,
            HealthTesting: 0,
            personType: "Abroad",
          },
          {
            ReleasedControl: 0,
            NoSegregation: 0,
            CentralizedIsolation: 0,
            InHomeIsolation: 0,
            NotChecked: 0,
            HealthTesting: 0,
            personType: "HighRisk",
          },
          {
            ReleasedControl: 0,
            NoSegregation: 0,
            CentralizedIsolation: 0,
            InHomeIsolation: 0,
            NotChecked: 0,
            HealthTesting: 0,
            personType: "RedCode",
          },
          {
            ReleasedControl: 0,
            NoSegregation: 0,
            CentralizedIsolation: 0,
            InHomeIsolation: 0,
            NotChecked: 0,
            HealthTesting: 0,
            personType: "ReturnHf",
          },
          {
            ReleasedControl: 0,
            NoSegregation: 0,
            CentralizedIsolation: 0,
            InHomeIsolation: 0,
            NotChecked: 1,
            HealthTesting: 0,
            personType: "SecondTouch",
          },
          {
            ReleasedControl: 0,
            NoSegregation: 0,
            CentralizedIsolation: 0,
            InHomeIsolation: 0,
            NotChecked: 0,
            HealthTesting: "0",
            personType: "Touch",
          },
          {
            ReleasedControl: 0,
            NoSegregation: 0,
            CentralizedIsolation: 0,
            InHomeIsolation: 0,
            NotChecked: 0,
            HealthTesting: 0,
            personType: "BjStudent",
          },
        ],
        BjStudentCount: 0,
        BjStudentCountToday: 0,
        "ReleasedControl-total": 0,
        "ReleasedControl-today": 0,
        "NotCheckedTodayNum-today": 0,
        "NotCheckedTodayNum-total": 0,
        "DoorSensorNum-total": 0,
        "HasInstalledDoorSensorNum-today": "0",
        "CentralizedIsolation-today": 0,
        "DoorSensorNum-today": "0",
        "HasInstalledDoorSensorNum-total": 0,
        "HealthTesting-today": 0,
        "NoSegregation-today": 0,
        "InHomeIsolation-total": 0,
        "NoSegregation-total": 0,
        "HealthTesting-total": 0,
      },
    });

    const timeFormat = function (time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return year + "年" + month + "月" + day + "日";
    };
    const controlStatusDict = ref({});
    const personTypeDict = ref({});
    const isLoading = ref(false);
    return {
      zk,
      zk2,
      zk3,
      zk4,
      zk4_,
      crowdList,
      dataForm,
      timeFormat,
      isLoading,
      controlStatusDict,
      personTypeDict,
    };
  },
  mounted() {
    this.$emit("setActive", "reportPersonType");
    this.$emit("changeTitle", "全部人员列表");
    this.controlPrsonnelTj();
  },
  activated() {
    this.$emit("setActive", "reportPersonType");
  },
  methods: {
    controlPrsonnelTj() {
      this.dataForm.totalSensor = 0;
      this.dataForm.totalPerson = 0;
      Api.contactReportByPersonType().then((res) => {
        this.dataForm.data = res.data;
        this.crowdList= this.dataForm.data.list[7].crowdList;
        this.isLoading = false;
        this.controlStatusDict = JSON.parse(
          JSON.stringify(
            this.$store.state.Dict.find((p) => p.dictType == "controlStatus")
          )
        );
        this.aa = JSON.parse(
          JSON.stringify(
            this.$store.state.Dict.find((p) => p.dictType == "controlStatus")
          )
        );
        this.controlStatusDict.dataList.push({
          dictLabel: "管控措施未明确",
          dictValue: "NotChecked",
        });
        this.controlStatusDict.dataList.push({
          dictLabel: "未落实管控",
          dictValue: "WorkableControl",
        });
        // this.controlStatusDict.dataList.push({
        //   dictLabel: "未落实管控今日",
        //   dictValue: "NotChecked",
        // });
        // this.controlStatusDict.dataList.push({
        //   dictLabel: "不用隔离",
        //   dictValue: "NoSegregation",
        // });
        this.personTypeDict = this.$store.state.Dict.find(
          (p) => p.dictType == "PeopleType"
        );
        this.personTypeDict.dataList = this.personTypeDict.dataList.filter(
          (item) => item.dictValue !== "BjStudent"
        );
        this.personTypeDict.dataList.forEach((dictItem) => {
          this.dataForm.totalPerson +=
            this.dataForm.data[dictItem.dictValue + "Count"];
        });

        this.dataForm.totalSensor += this.dataForm.data["DoorSensorNum-total"];
        this.dataForm.totalSensor +=
          this.dataForm.data["HasInstalledDoorSensorNum-total"];
      });
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(
          (p) => p.dictType == type
        ).dataList.find((p) => p.dictValue == value).dictLabel;
      } catch {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.van-icon{margin-left: 4px;font-size: 16px;}
.about {
  background: #f8f8f8;
}
.body {
  background: #ffffff;
  padding: 10px;
}
.dataItem {
  margin: 15px 10px 15px 10px;
  border: 1px solid rgb(212, 212, 212);
  padding-top: 10px;
  background: #f1e8e88f;
}
.dataItem .r1 .title {
  color: #1c1c1c;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}
.reportRow {
  margin: 0 10px 0 10px;
  text-align: center;
  padding: 10px 0 10px 0;
}
.reportRow .van-col {
  background: #eee;
  padding: 3px;
}

.reportRow .reportCol {
  width: 100%;
  background: #ffffff;
  height: 80px;
  align-items: center;
}

.reportRow .reportCol .r1 {
  width: 100%;
  font-size: 18px;
  height: 30%;
  justify-content: center;
  align-items: flex-end;
  color: #5776e6;
  font-weight: 600;
  color: #5776e6;
  line-height: 24px;
  padding-top:10px;
}

.reportRow .reportCol .item0 {
  color: #fa5555;
}

.reportRow .reportCol .item1 {
  color: #ff881c;
}

.reportRow .reportCol .item2 {
  color: #5ed6c8;
}

.reportRow .reportCol .item3 {
  color: #51d74e;
}
.reportRow .reportCol .item4 {
  color: #5776e6;
}
.reportRow .reportCol .item5 {
  color: #51d74e;
}
.reportRow .reportCol .item6 {
  color: #ff881c;
}
.reportRow .reportCol .item7 {
  color: #51d74e;
}

.reportRow .reportCol .r2 {
  justify-content: center;
  align-items: center;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  color: #1b2026;
  line-height: 24px;
}
.reportRow .reportCol .r3 {
  width: 100%;
  font-size: 11px;
  height: 30%;
  justify-content: center;
  align-items: flex-start;
  color: #858ebd;
}
.reportRow .reportCol .r3 label {
  font-size: 12px;
  line-height: 18px;
}
.ListRow {
  padding: 10px;
  background: #ffffff;
  font-size: 12px;
  color: #333;
}
.ListRow.group {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 12px;
  background: #eeeeee !important;
  border-radius: 14px !important;
  color: #666;
}
.ListRow a {
  color: #5776e6;
}
.ListHead {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px;
  background: #ffffff;
  font-size: 12px;
  color: #5a6080;
  font-weight: 400;
}
</style>
