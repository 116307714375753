<template>
  <div class="about">
    <van-cell-group title="人员信息">
      <van-cell title="姓名" value="张华丽" />
      <van-cell title="性别" value="男" />
      <!-- <van-cell title="年龄" value="26" /> -->
      <van-cell title="证件号码" value="340898511512412111" />
      <van-cell title="手机号码" value="15165565242" />
      <van-cell title="工作单位" value="合肥XXX科技有限公司" />
      <van-cell title="所属社区" value="万泽社区" />
      <van-cell title="所属网格" value="蜀麓苑" />
      <van-cell title="现居住地" value="科学家园10#203" />
    </van-cell-group>
    <!-- 密接次密接 需填写病例 、密接姓名、m末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
    <!-- 阳性物品暴露人员 需填写 末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
    <!-- 高风险地区来（返）肥人员 需填写  返回合肥时间、返回前所在地区、安康码、行程码-->
    <!-- 红黄码人员 需填写 安康码、行程码-->
    <!-- 其他情况 需填写  其他说明、安康码、行程码-->

    <van-cell-group
      :title="dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]"
    >
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 4"
        title="密接病例"
        value="李四"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 4"
        title="接触方式"
        value="G3086"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 3"
        title="末次接触时间"
        value="2022-4-14 14:44:31"
      />

      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
        title="返回合肥时间"
        value="2022-4-14 14:45:38"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
        title="返回前所在地区"
        value="上海"
      />

      <van-cell
        title="安康码"
        v-if="dicPersonType.indexOf(personInfo.personType) >= 1"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            src="https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg"
            @click="
              ImagePreview({
                images: ['https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg'],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        title="行程码"
        v-if="dicPersonType.indexOf(personInfo.personType) >= 1"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.tripCode"
            @click="
              ImagePreview({
                images: [personInfo.tripCode],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) == 0"
        title="其他说明"
        :label="personInfo.remark"
      />
    </van-cell-group>
    <van-cell-group title="管控措施">
      <van-cell title="管控状态" value="居家隔离中" />
      <van-cell title="解除居家隔离日期" value="2022-4-14" />
      <van-cell title="解除健康监测日期" value="2022-4-14" />
    </van-cell-group>
    <van-cell-group title="健康监测期核酸监测凭证">
      <div class="imageListRoll box">
        <div
          class="imgHozItem"
          title=""
          v-for="item in [0, 0, 0, 0, 0, 0, 0]"
          :key="item"
        >
          <van-image
            width="100"
            height="100"
            @click="
              ImagePreview({
                images: ['https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg'],
                closeable: true,
              })
            "
            src="https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg"
          />
          <div class="imgHozItemName">2022-4-14 10:34</div>
        </div>
      </div>
    </van-cell-group>
    <van-cell-group title="居家隔离期期核酸监测凭证">
      <div class="imageListRoll box">
        <div
          class="imgHozItem"
          title=""
          v-for="item in [0, 0, 0, 0, 0, 0, 0]"
          :key="item"
        >
          <van-image
            width="100"
            height="100"
            @click="
              ImagePreview({
                images: ['https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg'],
                closeable: true,
              })
            "
            src="https://cdn.jsdelivr.net/npm/@vant/assets/cat.jpeg"
          />
          <div class="imgHozItemName">2022-4-14 10:34</div>
        </div>
      </div>
    </van-cell-group>
  </div>
  <div style="margin: 16px">
    <van-button round block type="primary" native-type="submit">
      提交
    </van-button>
  </div>
</template> 

<script>
import { ref } from '@vue/reactivity'
// @ is an alias to /src 
import { useRouter } from 'vue-router'
import { ImagePreview } from 'vant';
import { getPersoninfo } from '../util/Api'
export default {
  name: 'Home',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {

    const personInfo = ref(
      {
        personType: '',
         ankangCodeList: [''],
          tripCodeList: ['']
      }
    )

    const dicPersonTypeLabel = ["其他情况", "红黄码人员", "高风险地区来（返）肥人员", "阳性物品暴露人员", "密接、次密接"]

    const dicPersonType = ['OtherCases', 'CodePersonnel', 'Highrisk', 'PositiveArticles', 'Touch']

    const dicPersonTypeList = [{ value: 'OtherCases', label: '其他情况' }, { value: 'CodePersonnel', label: '红黄码人员' }, { value: 'Highrisk', label: '高风险地区来（返）肥人员' }, { value: 'PositiveArticles', label: '阳性物品暴露人员' }, { value: 'Touch', label: '密接、次密接' },]
    const showPicker = ref(false);

    const router = useRouter()
    return { router, personInfo, dicPersonType, dicPersonTypeLabel, ImagePreview, showPicker, dicPersonTypeList }
  },
  mounted() {

    this.$emit('changeTitle', "居家隔离人员详情",)
    let id = this.router.currentRoute.value.query.id;
    this.initdata(id)
  },
  methods:
  {
    initdata(id) {
      // this.persionInfo = getPersoninfo(id);
    }
  }

}
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
}
.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}
.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}
.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}
.box::-webkit-scrollbar-corner {
  background: transparent;
}
</style>