<template>
  <van-pull-refresh
    style="min-height: 100vh"
    v-model="isLoading"
    success-text="刷新成功"
    @refresh="getDateList"
  >
    <van-tabs style="margin-top: 10px" v-model:active="activeTransport">
      <van-tab title="待指派">
        <div class="about">
          <van-search
            v-model="dataForm.tabs1.searchKey"
            @search="onTabs1Search"
            placeholder="请输入搜索关键词"
          />

          <div
            style="
              font-size: 14px;
              color: #999;
              background: #f7f8fa;
              text-align: left;
              padding: 5px;
              padding-left: 20px;
            "
          >
            当前选中/总条数
            {{
              dataForm.tabs1.checkedList.length +
              "/" +
              dataForm.tabs1.data.total
            }}
            <van-button
              v-if="dataForm.tabs1.data.total != 0"
              type="primary"
              @click="getTransfer()"
              size="mini"
              style="float: right; right: 10px"
              >指派司机</van-button
            >
          
            <!-- <van-button
              v-if="dataForm.tabs1.data.total != 0"
              type="primary"
              style="float: right; right: 20px"
              @click="checkAll"
              size="mini"
              >{{ dataForm.tabs1.isCheckAll ? "全消" : "全选" }}</van-button
            > -->
          </div>
        </div>

        <div class="dataList">
          <van-checkbox-group
            ref="checkboxGroup"
            v-model="dataForm.tabs1.checkedList"
            @change="(o) => checkChange(o)"
          >
            <div
              class="dataItem"
              v-for="(item, index) in dataForm.tabs1.data.list"
              :key="index"
              @click.stop="tohref(item)"
            >
              <van-row class="r1">
                <van-col span="6">
                  <span class="title">{{ item.personName }}</span>
                </van-col>
                <van-col span="14">{{ item.communityName }} </van-col>
                <van-col span="4">
                  <van-checkbox
                   @click.stop="()=>{}"
                    style="text-align: right"
                    :name="item.id"
                  ></van-checkbox>
                </van-col>
              </van-row>
              <van-row class="r2">
                <van-col span="10">
                  <div class="itemTitle">证件号码</div>
                  <div class="itemValue">{{ item.idCard }}</div>
                </van-col>
                <van-col class="colMiddle" span="6">
                  <div class="itemTitle">手机号码</div>
                  <div class="itemValue">{{ item.phone }}</div>
                </van-col>
                <van-col span="8">
                  <div class="itemTitle">人员类型</div>
                  <div class="itemValue">{{ getDictLabel('PeopleType',item.personType ) }}</div>
                </van-col>
              </van-row>
            </div>
          </van-checkbox-group>
        </div>
        <!-- <van-pagination
          v-if="dataForm.tabs2.data.total > 0"
          v-model="dataForm.tabs2.currentPage"
          :total-items="dataForm.tabs2.data.total"
          :items-per-page="1000"
          :show-page-size="5"
          @change="getTab2DateList"
        /></div -->
      </van-tab>
      <van-tab title="待转运">
        <div class="about">
          <van-search
            v-model="dataForm.tabs2.searchKey"
            @search="onTabs2Search"
            placeholder="请输入搜索关键词"
          />
          <div
            style="
              font-size: 14px;
              color: #999;
              background: #f7f8fa;
              text-align: left;
              padding-left: 20px;
            "
          >
            为你筛选出<font
              style="color: red"
              v-html="dataForm.tabs2.data.total"
            ></font
            >条结果
          </div>
        </div>
        <div class="dataList">
          <div
            class="dataItem"
            v-for="(item, index) in dataForm.tabs2.data.list"
            :key="index"
          >
            <router-link :to="{ name: '转运单详情', query: { id: item.id } }">
              <van-row class="r1">
                <span class="title">{{ item.personName }}</span>
                <van-tag class="rightTag" type="warning">{{
                  this.getDictLabel("TransportType", item.carType)
                }}</van-tag>
              </van-row>
              <van-row class="r2">
                <van-col span="10">
                  <div class="itemTitle">证件号码</div>
                  <div class="itemValue">{{ item.idCard }}</div>
                </van-col>
                <van-col class="colMiddle" span="6">
                  <div class="itemTitle">手机号码</div>
                  <div class="itemValue">{{ item.phone }}</div>
                </van-col>
                <van-col span="8">
                  <div class="itemTitle">司机:{{ item.driverName }}</div>
                  <div class="itemValue">{{ item.driverPhone }}</div>
                </van-col>
              </van-row>
              <!-- <van-row class="r2">
                <van-col span="8">
                  <div class="itemTitle">出发地</div>
                </van-col>
                <van-col span="16">
                  <div>{{ item.startPlace }}</div>
                </van-col>
              </van-row> -->
              <van-row class="r2">
                <van-col span="12">
                  <div class="itemTitle">目的地</div>
                  <div class="itemValue">{{item.destination}}</div>
                </van-col>
                <van-col span="12">
                  <div class="itemTitle">人员类型</div>
                  <div class="itemValue">{{ getDictLabel('PeopleType',item.personType ) }}</div>
                </van-col>
              </van-row>
            </router-link>
          </div>
          <van-pagination
            v-if="dataForm.tabs2.data.total > 0"
            v-model="dataForm.tabs2.currentPage"
            :total-items="dataForm.tabs2.data.total"
            :items-per-page="10"
            :show-page-size="5"
            @change="getTab2DateList"
          /></div
      ></van-tab>
      <van-tab title="转运中">
        <div class="about">
          <van-search
            v-model="dataForm.tabs2_2.searchKey"
            @search="onTabs2_2Search"
            placeholder="请输入搜索关键词"
          />
          <div
            style="
              font-size: 14px;
              color: #999;
              background: #f7f8fa;
              text-align: left;
              padding-left: 20px;
            "
          >
            为你筛选出<font
              style="color: red"
              v-html="dataForm.tabs2_2.data.total"
            ></font
            >条结果
          </div>
        </div>
        <div class="dataList">
          <div
            class="dataItem"
            v-for="(item, index) in dataForm.tabs2_2.data.list"
            :key="index"
          >
            <router-link :to="{ name: '转运单详情', query: { id: item.id } }">
              <van-row class="r1">
                <span class="title">{{ item.personName }}</span>
                <van-tag class="rightTag" type="warning">{{
                  this.getDictLabel("TransportType", item.carType)
                }}</van-tag>
              </van-row>
              <van-row class="r2">
                <van-col span="10">
                  <div class="itemTitle">证件号码</div>
                  <div class="itemValue">{{ item.idCard }}</div>
                </van-col>
                <van-col class="colMiddle" span="6">
                  <div class="itemTitle">手机号码</div>
                  <div class="itemValue">{{ item.phone }}</div>
                </van-col>
                <van-col span="8">
                  <div class="itemTitle">转运开始时间</div>
                  <div class="itemValue">{{ item.transportTime }}</div>
                </van-col>
              </van-row>
              <!-- <van-row class="r2">
                <van-col span="8">
                  <div class="itemTitle">出发地</div>
                </van-col>
                <van-col span="16">
                  <div>{{ item.startPlace }}</div>
                </van-col>
              </van-row> -->
              <van-row class="r2">
               <van-col span="12">
                  <div class="itemTitle">目的地</div>
                  <div class="itemValue">{{item.destination}}</div>
                </van-col>
                <van-col span="12">
                  <div class="itemTitle">人员类型</div>
                  <div class="itemValue">{{ getDictLabel('PeopleType',item.personType ) }}</div>
                </van-col>
              </van-row>
            </router-link>
          </div>

          <van-pagination
            v-if="dataForm.tabs2_2.data.total > 0"
            v-model="dataForm.tabs2_2.currentPage"
            :total-items="dataForm.tabs2_2.data.total"
            :items-per-page="10"
            :show-page-size="5"
            @change="getTab2_2DateList"
          /></div
      ></van-tab>
      <van-tab title="已转运">
        <div class="about">
          <van-search
            v-model="dataForm.tabs2_3.searchKey"
            @search="onTabs2_3Search"
            placeholder="请输入搜索关键词"
          />
          <div
            style="
              font-size: 14px;
              color: #999;
              background: #f7f8fa;
              text-align: left;
              padding-left: 20px;
            "
          >
            为你筛选出<font
              style="color: red"
              v-html="dataForm.tabs2_3.data.total"
            ></font
            >条结果
          </div>
        </div>
        <div class="dataList">
          <router-link
            :to="{ name: '转运单详情', query: { id: item.id } }"
            v-for="(item, index) in dataForm.tabs2_3.data.list"
            :key="index"
          >
            <div class="dataItem">
              <van-row class="r1">
                <span class="title">{{ item.personName }}</span>
                <van-tag class="rightTag" type="warning">{{
                  this.getDictLabel("TransportType", item.carType)
                }}</van-tag>
              </van-row>
              <van-row class="r2">
                <van-col span="10">
                  <div class="itemTitle">证件号码</div>
                  <div class="itemValue">{{ item.idCard }}</div>
                </van-col>
                <van-col class="colMiddle" span="6">
                  <div class="itemTitle">手机号码</div>
                  <div class="itemValue">{{ item.phone }}</div>
                </van-col>
                <van-col span="8">
                  <div class="itemTitle">转运完成时间</div>
                  <div class="itemValue">{{ item.arriveTime }}</div>
                </van-col>
              </van-row>
              <!-- <van-row class="r2">
                <van-col span="8">
                  <div class="itemTitle">出发地</div>
                </van-col>
                <van-col span="16">
                  <div>{{ item.startPlace }}</div>
                </van-col>
              </van-row> -->
              <van-row class="r2">
                <van-col span="12">
                  <div class="itemTitle">目的地</div>
                  <div class="itemValue">{{item.destination}}</div>
                </van-col>
                <van-col span="12">
                  <div class="itemTitle">人员类型</div>
                  <div class="itemValue">{{ getDictLabel('PeopleType',item.personType ) }}</div>
                </van-col>
              </van-row>
            </div>
          </router-link>
          <van-pagination
            v-if="dataForm.tabs2_3.data.total > 0"
            v-model="dataForm.tabs2_3.currentPage"
            :total-items="dataForm.tabs2_3.data.total"
            :items-per-page="10"
            :show-page-size="5"
            @change="getTab2_3DateList"
          /></div
      ></van-tab>
    </van-tabs>
    <van-popup
      v-model:show="ShowSetDriver"
      overlay
      id="driverPopup"
      position="bottom"
      closeable
    >
      <van-form
        @failed="onFailed"
        @submit="setDriver"
        style="margin-top: 30px; margin-bottom: 30px"
      >
        <van-field
          v-model="transport.destination"
          name="转运目的地"
          label="转运目的地"
          placeholder="转运目的地"
          :rules="[{ required: true, message: '转运目的地' }]"
        />
        <van-field
          name="转运专员"
          label="转运专员"
          v-model="transport.userName"
          is-link
          readonly
          placeholder="选择转运专员"
          :rules="[{ required: true, message: '选择转运专员' }]"
          @click="
            userPickShow = true;
            DatetimePickerField = 'userId';
          "
        />
        <van-field
          name="司机姓名"
          label="司机姓名"
          v-model="transport.driverName"
          is-link
          readonly
          placeholder="选择司机"
          :rules="[{ required: true, message: '选择司机' }]"
          @click="
            driverPickShow = true;
            DatetimePickerField = 'driverId';
          "
        />
        <van-field
          v-model="transport.driverPhone"
          name="手机号码"
          label="手机号码"
          placeholder="手机号码"
          readonly
        />
        <van-button size="small" type="primary" native-type="submit"
          >指派司机</van-button
        >
      </van-form>
    </van-popup>
    <van-popup v-model:show="driverPickShow" position="bottom" closeable>
      <van-picker
        style="z-index: 30000"
        title="司机"
        :columns-field-names="{ text: 'driverName', value: 'id' }"
        :columns="DriverList"
        @confirm="onConfirmDriver"
        @cancel="driverPickShow = false"
        @change="onChange"
      />
    </van-popup>
    <van-popup v-model:show="userPickShow" overlay position="bottom" closeable>
      <van-picker
        style="z-index: 30000"
        title="转运专员"
        :columns-field-names="{ text: 'username', value: 'id' }"
        :columns="TransferList"
        @confirm="onConfirmTransfer"
        @cancel="userPickShow = false"
        @change="onChangeUser"
      />
    </van-popup>
  </van-pull-refresh>
</template>

<script> 
// @ is an alias to /src 
import { reactive, ref } from 'vue';
import Api from '../util/App'
import { Dialog, Notify, Toast } from 'vant'
import { pick } from 'vant/lib/utils';
export default {
  name: 'Home',
  components: {

  },
  inject: ['setActive'],
  setup() {
    const dataForm = reactive({
      tabs1: {
        searchKey: '',
        currentPage: 1,
        checkedList: ref([]),
        isCheckAll: ref(false),
        data: {
          total: 0,
          list: [
            //   {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ]
        }
      },
      tabs2: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //   {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ]
        }
      },

      tabs2_2: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //   {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ]
        }
      }, tabs2_3: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ]
        }
      },
    });


    const SensorDataTypeArray = ["已关门", "故障", "已开门"]
    const SensorDeviceStateArray = ["正常", "故障", "报警", "离线"]
    const isLoading = ref(false)

    const checkboxGroup = ref(null);

    const ShowSetDriver = ref(false)
    const userPickShow = ref(false)

    const transport = ref({
      destination: '',
      driverName: '',
      userName: '',
      userId: '',
      driverId: '',
      driverPhone: '',
      transportIds: ''
    })

    const driverPickShow = ref(false)
    return { dataForm, isLoading, userPickShow, SensorDataTypeArray, SensorDeviceStateArray, checkboxGroup, ShowSetDriver, transport, driverPickShow }

  },
  mounted() {
    this.$emit('changeTitle', "待核查人员信息")
    this.$emit("setActive", "transitManager");
    this.getTab1DateList();
    this.getTab2DateList();
    this.getTab2_2DateList();
    this.getTab2_3DateList();
    this.loadDriverList()

  },
  activated() {
    this.$emit("setActive", "transitManager");
    this.getTab1DateList();
    this.getTab2DateList();
    this.getTab2_2DateList();
    this.getTab2_3DateList();
  },

  methods:
  {
    tohref(item){
       this.$router.push({ name: '人员详细信息', params: { type: '管控措施', id: item.personId } })
    },
    loadDriverList() {
      Api.getDriverList().then((res) => {
        this.DriverList = res.data
      })

    },
    onConfirmDriver(data) {
      this.driverPickShow = false;
      this.transport[this.DatetimePickerField] = data.id;
      this.transport.driverName = data.driverName
      this.transport.driverPhone = data.driverPhone


    },
    onConfirmTransfer(data) {
      this.userPickShow = false;
      this.transport[this.DatetimePickerField] = data.id;
      this.transport.userName = data.username
    },
    getDateList() {
      this.getTab1DateList();
      this.getTab2DateList();
      this.getTab2_2DateList();
      this.getTab2_3DateList();

      let that = this;
      setTimeout(that.isLoading = false, 1000)
    }
    ,
    onTabs1Search(val) {
      this.dataForm.tabs1.searchKey = val
      this.getTab1DateList()
    },
    getTab1DateList() {
      Api.gettspage({ limit: 1000, page: this.dataForm.tabs1.currentPage, personName: this.dataForm.tabs1.searchKey, status: "NotTransshipped", transportType: 2 }).then(res => {
        this.dataForm.tabs1.data = res.data
      })
    },
    onTabs2Search(val) {
      this.dataForm.tabs2.searchKey = val
      this.getTab2DateList()
    },
    getTab2DateList() {
      Api.gettspage({ limit: 1000, page: this.dataForm.tabs2.currentPage, personName: this.dataForm.tabs2.searchKey, status: "NotTransshipped", transportType: 1 }).then(res => {
        this.dataForm.tabs2.data = res.data
      })
    },
    onTabs2_2Search(val) {
      this.dataForm.tabs2_2.searchKey = val
      this.getTab2_2DateList()
    },
    getTab2_2DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.tabs2_2.currentPage, personName: this.dataForm.tabs2_2.searchKey, status: "InTransit" }).then(res => {
        this.dataForm.tabs2_2.data = res.data
      })
    },

    onTabs2_3Search(val) {
      this.dataForm.tabs2_2.searchKey = val
      this.getTab2_3DateList()
    }
    ,
    getTab2_3DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.tabs2_3.currentPage, personName: this.dataForm.tabs2_3.searchKey, status: "Transshipped" }).then(res => {
        this.dataForm.tabs2_3.data = res.data
      })
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(p => p.dictType == type).dataList.find(p => p.dictValue == value).dictLabel
      } catch {
        return ''

      }
    },
    setDriver() {
      if (this.dataForm.tabs1.checkedList.length == 0) {
        Notify({ type: 'danger', message: '请至少选择一条转运单！' });
        return
      }
      Dialog.confirm({
        title: '系统消息',
        message:
          '确认指派司机？',
      })
        .then(() => {
          // on confirm 

          const toast = Toast.loading({
            duration: 0,
            forbidClick: true,
            message: '数据传输中',
          });

          this.transport.transportIds = this.dataForm.tabs1.checkedList.join(',')
          Api.transitOrder(this.transport).then((res) => {
            toast.clear()
            if (res.code == 0) {
              Notify({ type: 'success', message: '成功指派司机!' });
              // 清掉表单

              this.transport = {
                destination: '',
                driverName: '',
                userName: '',
                userId: '',
                driverId: '',
                driverPhone: '',
                transportIds: ''
              }

              this.ShowSetDriver = false;
              this.dataForm.tabs1.checkedList = []
              this.getDateList()
            } else {
              Notify({ type: 'danger', message: res.msg });
            }
          })


        })
        .catch(() => {
          // on cancel

        });
    }, checkChange(CheckList) {
      if (CheckList.length > 1) {
        let id = CheckList[CheckList.length - 1]
        if (this.dataForm.tabs1.data.list.find(p => p.id == CheckList[0]).communityName !=
          this.dataForm.tabs1.data.list.find(p => p.id == id).communityName) {
          Dialog.alert({
            title: '消息提醒',
            message: '不同社区的人员不可同批次转运',
          }).then(() => {
            // on close
          });
          CheckList.splice(CheckList.length - 1, 1)
        }
      }

    }, getTransfer() {
      if (this.dataForm.tabs1.checkedList.length == 0) {
        Notify({ type: 'danger', message: '请至少选择一条转运单！' });
        return
      }
      this.ShowSetDriver = true;
      let community = this.dataForm.tabs1.data.list.find(p => p.id == this.dataForm.tabs1.checkedList[0]).community
      community = community != null ? community : ''
      Api.getTransferList(community).then((res) => {
        if (res.code == 0) {
          this.TransferList = res.data

        }

      })


    }, checkAll() {

      if (this.dataForm.tabs1.data.list.length > 0 && 
      this.dataForm.tabs1.data.checkedList.filter(p => p.communityName == this.dataForm.tabs1.data.checkedList[0].communityName).length == this.dataForm.tabs1.data.checkedList.length) {
        this.dataForm.tabs1.isCheckAll = !this.dataForm.tabs1.isCheckAll;

        this.checkboxGroup.toggleAll(this.dataForm.tabs1.isCheckAll);
      } else {
        Dialog.alert({
          title: '消息提醒',
          message: '不同社区的人员不可同批次转运',
        })

      }
    }

  }
}
</script>
<style scoped>
.dataList {
  background: #f8f8f8ff;
}
.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
  padding-bottom: 10px;
}
.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}
.dataItem .r1 .leftTag {
  margin-right: 5px;
}
.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}
.dataItem .r2 .itemTitle {
  color: #999999;
}
.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
  min-height: 90vh;
}
</style>