<template>
<div style="padding-top:30px;">
  <van-cell-group inset  class="gridMenu" >
       
<van-cell title-style="text-align:left;font-size:18px;" title="查看基本信息" value="" is-link     :to="{ name: '人员详细信息', params: { type: '人员信息', id: $router.currentRoute.value.query.id } }" />
<van-cell title-style="text-align:left;font-size:18px;" title="查看管控措施" value="" is-link     :to="{ name: '人员详细信息', params: { type: '管控措施', id: $router.currentRoute.value.query.id  } }" />
<van-cell title-style="text-align:left;font-size:18px;" title="查看每日填报"  value=""  v-if=" $router.currentRoute.value.query.status!=null&&($router.currentRoute.value.query.status=='InHomeIsolation'||$router.currentRoute.value.query.status=='HealthTesting'||$router.currentRoute.value.query.status=='ReleasedControl')" is-link   :to="{ name: '人员详细信息', params: { type: '每日填报' , id: $router.currentRoute.value.query.id } }" />
<template v-if="$router.currentRoute.value.query.personType!='TwentyEight'">
<van-cell title-style="text-align:left;font-size:18px;" title="查看转运记录" value="" is-link  :to="{ name: '人员详细信息', params: { type: '转运记录', id: $router.currentRoute.value.query.id  } }" /> 
<van-cell title-style="text-align:left;font-size:18px;" title="查看变更记录" value="" is-link  :to="{ name: '人员详细信息', params: { type: '变更申请', id: $router.currentRoute.value.query.id  } }" /> 
<van-cell title-style="text-align:left;font-size:18px;" title="查看门磁记录" value="" is-link :to="{ name: '人员详细信息', params: { type: '门磁记录', id: $router.currentRoute.value.query.id  } }" /> 
</template>
<van-cell title-style="text-align:left;font-size:18px;" title="查看人员时间轴"   value="" is-link :to="{ name: '人员详细信息', params: { type: '时间轴', id: $router.currentRoute.value.query.id  } }" /> 
<van-cell title-style="text-align:left;font-size:18px;" title="查看核酸报告"   value="" is-link :to="{ name: '人员详细信息', params: { type: '核酸记录', id: $router.currentRoute.value.query.id  } }" /> 
</van-cell-group>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.gridMenu{
  background: #F8F8F8FF !important;
}
.gridMenu .van-cell{
  margin:8px 0 8px 0 !important;
  height:44px;
}

 
</style>