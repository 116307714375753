import axios from "axios";
import { Notify } from "vant";

// 获取环境变量
const getBaseURI = () => {
  let env = process.env.VUE_APP_NODE_ENV || process.env.NODE_ENV;

  //    开发环境
  if (env === "dev" || env == "development") {
    //    return 'http://112.30.213.162:8082/stayhome-app'
    //return 'http://192.168.1.101:9013/stayhome-app';
    // return 'https://s-stg.ringzle.com/stayhome-app'
    //    return 'http://192.168.1.102:9013/stayhome-app';
    return "https://s.ringzle.com/stayhome-app";
  }

  // 集成测试环境
  if (env === "prod:sit") {
    return "https://s.ringzle.com/stayhome-app";
  }

  if (env === "prod:uat") {
    return "https://s.ringzle.com/stayhome-app";
  }
  // 生产环境
  if (env === "prod" || env === "production") {
    return "/stayhome-app";
  }
};

// 默认超时时间
axios.defaults.timeout = 10000;
axios.defaults.timeoutErrorMessage = "请求超时，请检查网络后重试";
// 请求接口根目录
axios.defaults.baseURL = getBaseURI();

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // 配置头部
    let token = window.localStorage.getItem("token");
    if (token != "") {
      config.headers["token"] = token;
    }

    return config;
  },
  (err) => {
    Notify({ type: "danger", message: JSON.stringify(err) });
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    Notify({ type: "danger", message: JSON.stringify(err) });
    return Promise.reject(err);
  }
);

export function fetch(options) {
  return new Promise((resolve, reject) => {
    axios
      .get(options.url, {
        params: options.data,
        timeout: options.timeout,
        responseType: options.responseType || "json",
      })
      .then((response) => {
        resolve(options.responseType ? response : response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
