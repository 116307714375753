<template>
  <div
    class="success"
    v-if="success"
  >
    <van-empty
      :image="require('@/assets/success.png')"
      image-size="120"
      description="填报成功"
    />
  </div>
  <van-form
    @failed="onFailed"
    @submit="submit"
    v-else
  >
    <div class="about">
      <van-cell-group title="人员信息">
        <van-field
          v-model="personInfo.idCard"
          name="证件号码"
          label="证件号码"
          placeholder="证件号码"
          required
          :rules=" [{
            required: true,
            message: '必填项不能为空',
            trigger: 'onBlur'
          }, {

            validator: idCardValid,
            message: IdCardMessage,
            trigger: 'onBlur'
          }]"
        />

        <van-field
          v-model="personInfo.personName"
          name="姓名"
          label="姓名"
          placeholder="姓名"
          required
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />

        <van-field
          v-model="personInfo.phone"
          type="tel"
          name="手机号码"
          label="手机号码"
          placeholder="手机号码"
          required
          :rules=" [{
            required: true,
            message: '必填项不能为空',
            trigger: 'onBlur'
          }, {
            validator: value => {
              return /^(0|86|17951)?(1)[0-9]{10}$/
                .test(value)
            },
            message: '请输入正确的手机号码',
            trigger: 'onBlur'
          }]"
        />

        <van-field
          v-model="personInfo.personLabel"
          readonly
          name="personLabel"
          label="人员类型"
          placeholder=""
        />
        <van-field
          v-model="personInfo.crowdTypeLabel"
          is-link
          readonly
          label="人群类型"
          placeholder="人群类型"
          required
          :rules="[{ required: true, message: '必填项不能为空' }]"
          @click="showCrowdTypePicker = true"
        />
        <van-popup
          v-model:show="showCrowdTypePicker"
          position="bottom"
        >
          <van-picker
            :columns="crowdTypeList"
            :default-index="personInfo.crowdTypeIndex"
            @confirm="
              (value) => {
                personInfo.crowdTypeLabel = value.text
                personInfo.crowdType = value.value;
                showCrowdTypePicker = false;
              }
            "
            @cancel="showCrowdTypePicker = false"
          />
        </van-popup>

        <van-field
          v-model="personInfo.communityLabel"
          is-link
          readonly
          required
          label="所属社区"
          placeholder="所属社区"
          :rules="[{ required: true, message: '必填项不能为空' }]"
          @click="showCommunityPicker = true"
        />
        <van-popup
          v-model:show="showCommunityPicker"
          position="bottom"
        >
          <van-picker
            :columns="communityList"
            :default-index="personInfo.communityIndex"
            @confirm="
              (value) => {
                personInfo.communityLabel = value.text
                personInfo.community = value.value;
                showCommunityPicker = false;
                getGridList()
              }
            "
            @cancel="showCommunityPicker = false"
          />
        </van-popup>
        <van-field
          v-model="personInfo.gridLabel"
          is-link
          readonly
          label="所属网格"
          placeholder="所属网格"
          required
          :disabled="!personInfo.community"
          @click="()=>{
            if(personInfo.community){
            showGridPicker = true
            }
          }"
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-popup
          v-model:show="showGridPicker"
          position="bottom"
        >
          <van-picker
            :columns="gridList"
            v-if="gridList.length>0"
            :default-index="personInfo.gridIdIndex"
            @confirm="
              (value) => {
                personInfo.gridLabel = value.text
                personInfo.gridId = value.value;
                showGridPicker = false;
              }
            "
            @cancel="showGridPicker = false"
          />
        </van-popup>

        <!-- <van-field
          v-model="personInfo.workUnit"
          name="工作单位"
          label="工作单位"
          placeholder="工作单位"
          required
          :rules="[{ required: true, message: '必填项不能为空' }]"
        /> -->
        <van-field
          v-model="personInfo.workAddress"
          name="单位地址"
          label="单位地址"
          placeholder="单位地址"
          required
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          v-model="personInfo.industryType"
          name="所属行业"
          label="所属行业"
          placeholder="所属行业"
          required
          :rules="[{ required: true, message: '必填项不能为空' }]"
        />
        <van-field
          v-model="personInfo.remark"
          rows="2"
          autosize
          label="其他说明"
          type="textarea"
          maxlength="50"
          placeholder="其他说明"
          show-word-limit
        />
      </van-cell-group>
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        :disabled="disBtn"
        native-type="submit"
      >
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { Notify } from "vant";
import Api from "../util/App";
export default {
  setup() {
    const personInfo = ref({
      community: "",
      communityLabel: "",
      communityIndex: "",
      gridId: 0,
      gridIdIndex: "",
      gridLabel: "",
      id: "",
      idCard: "",
      phone: "",
      personName: "",
      personType: "TwentyEight",
      personLabel: "",
      crowdType: "",
      crowdTypeLabel: "",
      crowdTypeIndex: "",
      industryType: "",
      workAddress: "",
      workUnit: "",
      remark: "",
    });
    const success = ref(false);
    const showCrowdTypePicker = ref(false);
    const showCommunityPicker = ref(false);
    const showGridPicker = ref(false);
    const router = useRouter();
    const crowdTypeList = ref([]);
    const communityList = ref([]);
    const gridList = ref([]);
    const disBtn = ref(false);
    const IdCardMessage = ref("");
    return {
      success,
      router,
      disBtn,
      IdCardMessage,
      personInfo,
      showCrowdTypePicker,
      showCommunityPicker,
      showGridPicker,
      communityList,
      gridList,
      crowdTypeList,
    };
  },
  mounted() {
    this.$emit("changeTitle", "居家隔离人员详情");
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      for (const key in this.personInfo) {
        this.personInfo[key] = "";
      }
      this.personInfo.personType = "TwentyEight";
      setTimeout(() => {
        let tempCrowdTypeList = this.$store.state.Dict.find(
          (p) => p.dictType == "TwentyEightType"
        ).dataList;
        tempCrowdTypeList.forEach((item) => {
          this.crowdTypeList.push({
            text: item.dictLabel,
            value: item.dictValue,
          });
        });
        //回显编辑页面
        let id = this.router.currentRoute.value.query.id;
        if (id) {
          this.getBaseInfo(id);
        }else{
          this.getCommunityList()
        }
        this.personInfo.personLabel = this.getDictLabel(
          "PeopleType",
          "TwentyEight"
        );
      }, 1000);
    },
    getBaseInfo(id) {
      Api.getPersoninfo(id).then(async (res) => {
        for (const key in this.personInfo) {
          if (res.data[key]) {
            this.personInfo[key] = res.data[key];
          }
        }
        this.crowdTypeList.forEach((item, index) => {
          if (item.value == this.personInfo.crowdType) {
            this.personInfo.crowdTypeLabel = item.text;
            this.personInfo.crowdTypeIndex = index;
          }
        });
       await this.getCommunityList();
       await this.getGridList();
      });
    },
    getDictLabel(type, value) {
      return this.$store.state.Dict.find(
        (p) => p.dictType == type
      ).dataList.find((p) => p.dictValue == value).dictLabel;
    },
    getCommunityList() {
      this.communityList = [];
      Api.getOrgCommunityList().then((res) => {
        res.data.forEach((item, index) => {
          if (item.id == this.personInfo.community) {
            this.personInfo.communityLabel = item.name;
            this.personInfo.communityIndex = index;
          }
          this.communityList.push({ text: item.name, value: item.id });
        });
      });
    },

    getGridList() {
      this.gridList = [];
      this.personInfo.gridLabel = "";
      this.personInfo.gridIdIndex = "";
      Api.getGridList({ community: this.personInfo.community }).then(
        (res) => {
          res.data.forEach((item, index) => {
            if (item.gridId == this.personInfo.gridId) {
              this.personInfo.gridLabel = item.name;
              this.personInfo.gridIdIndex = index;
            }
            this.gridList.push({ text: item.name, value: item.gridId });
          });
        }
      );
    },

    idCardValid(value) {
      if (!value) {
        this.IdCardMessage = "请输入证件号码";
        return false;
      }
      if (!/(^\d{8}$)|(^\d{9}$)|(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        this.IdCardMessage = "你输入的身份证、台胞证、护照编号长度或格式错误";
        return false;
      }
      if (value.length < 10) {
        return true;
      }
      var aCity = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外",
      };
      if (!aCity[parseInt(value.substr(0, 2))]) {
        this.IdCardMessage = "你的身份证地区非法";
        return false;
      }
      // 出生日期验证
      var sBirthday = (
        value.substr(6, 4) +
        "-" +
        Number(value.substr(10, 2)) +
        "-" +
        Number(value.substr(12, 2))
      ).replace(/-/g, "/");
      var d = new Date(sBirthday);
      if (
        sBirthday !=
        d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()
      ) {
        this.IdCardMessage = "身份证上的出生日期非法";
        return false;
      }

      // 身份证号码校验
      var sum = 0;
      var weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      var codes = "10X98765432";
      for (var i = 0; i < value.length - 1; i++) {
        sum += value[i] * weights[i];
      }
      var last = codes[sum % 11]; // 计算出来的最后一位身份证号码
      if (value[value.length - 1] != last) {
        this.IdCardMessage = "你输入的身份证号非法";
        return false;
      }
      return true;
    },

    submit() {
      this.disBtn = true;
      if (!this.router.currentRoute.value.query.id) {
        this.add();
      } else {
        this.update();
      }
    },
    add() {
      Api.add28codeperson(this.personInfo)
        .then((res) => {
          if (res.code == 0) {
            Notify({ type: "success", message: "填报成功!" });
            // 成功后 清空表单数据
            for (const key in this.personInfo) {
              this.personInfo[key] = "";
            }
            //this.router.push("blank");
            this.success = true;
          } else {
            Notify({ type: "danger", message: res.msg });
            this.disBtn = false;
          }
        })
        .catch((err) => {
          Notify({ type: "danger", message: JSON.stringify(err) });
        });
      setTimeout(() => {
        this.disBtn = false;
      }, 3000);
    },
    update() {
      Api.update28PersonInfo(this.personInfo)
        .then((res) => {
          if (res.code == 0) {
            Notify({ type: "success", message: "修改成功" });
            this.$router.back();
          } else {
            Notify({ type: "danger", message: res.msg });
            this.disBtn = false;
          }
        })
        .catch((err) => {
          Notify({ type: "danger", message: JSON.stringify(err) });
        });
      setTimeout(() => {
        this.disBtn = false;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
}
.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}
.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}
.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}
.box::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
