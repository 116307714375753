<template>
<van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh=" 
     controlPrsonnelTj({ personType:dataForm.personType });
    "
  >
  <div class="about">
    <div
      style="
        font-size: 14px;
        color: #999;
        background: #f7f8fa;
        text-align: left;
        padding: 5px 0 10px 10px;
      "
    >
      统计截至 <font style="color: red">{{ timeFormat(new Date()) }}</font>
    </div>

    <!-- <van-row  gutter="5"  class="reportRow">
    <van-col span="6"    v-for="(item, index) in controlStatusDict.dataList"
        :key="index">
      <div
        class="reportCol"
     
      >   <router-link replace
        :to="{
          name: '全部人员列表',
          query: {   controlStatus: item.dictValue },
        }"
    
      >
        <van-row :class="'r1 item'+index">{{
          dataForm.data[item.dictValue + "TotalNum"]
        }}</van-row>
        </router-link>
        <van-row class="r2">{{ item.dictLabel }}</van-row>
            <van-row class="r3"
          >较昨日<label   :style="'color:'+(dataForm.data[item.dictValue + 'CompareNum']>=0?'#FA5555':'#61D65E')" >{{ dataForm.data[item.dictValue + "CompareNum"]>=0?("+"+dataForm.data[item.dictValue + "CompareNum"]):dataForm.data[item.dictValue + "CompareNum"] }} </label></van-row
        >
      </div>
    </van-col>
  </van-row> -->

    <van-row class="ListHead" gutter="10">
      <van-col span="6"> 社区 </van-col>
      <van-col
        span="3"
        v-for="(item, index) in controlStatusDict.dataList"
        v-html="item.dictLabel"
        :key="index"
      >
      </van-col>
    </van-row>
    <van-row
      class="ListRow"
      gutter="10"
      v-for="(item, index) in dataForm.data.list"
      :key="index"
    >
      <van-col span="6"> {{ item.name }} </van-col>
      <van-col
        span="3"
        v-for="(col, colindex) in controlStatusDict.dataList"
        :key="colindex"
      >
      <template v-if='this.$route.query.personType=="TwentyEight"'>
     
        <router-link
     v-if="col.dictValue!='NotCheckNum'&&col.dictValue!='NoSegregation'"
          :to="{
            name: '50类人员列表',
            query: {community: item.id, type:dataForm.personType,crowdType:dataForm.crowdType},
          }"
        >
          {{ item[col.dictValue] }}
        </router-link>
           <router-link v-else 
          :to="{
            name: '50类人员列表',
            query: {community: item.id, type:dataForm.personType,crowdType:dataForm.crowdType },
          }">
          {{ item[col.dictValue] }} </router-link>
           </template>
           <template v-else>
               <router-link
     v-if="col.dictValue!='NotCheckNum'&&col.dictValue!='NoSegregation'"
          :to="{
            name: '全部人员列表',
            query: { community: item.id, controlStatus: col.dictValue,type:dataForm.personType },
          }"
        >
          {{ item[col.dictValue] }}
        </router-link>
           <router-link v-else 
          :to="{
            name: '全部人员列表',
            query: { status: col.dictValue!='NotCheckNum'?'':1, controlStep: col.dictValue!='NoSegregation'?'':'NoSegregation' ,community: item.id, type:dataForm.personType },
          }">
          {{ item[col.dictValue] }} </router-link>
           </template>
      </van-col>
    </van-row>
  </div>
  </van-pull-refresh>
  
</template> 
 
<script>
// @ is an alias to /src 
import { reactive, ref } from 'vue';
import Api from '../util/App';
//import { getPersoninfoDataList,posttest } from '../util/Api'
export default {
  name: 'Home',
  components: {
  },
  inject: ['setActive'],
  setup() {
    const dataForm = reactive({
      searchKey: '',
      searchState: 0,
      searchType: 0,
      currentPage: 1,
      personType:'',
      crowdType:'',
      data: {
        "list": [
          //   {
          //     "id": "3401044000010000",
          //     "name": "万泽社区",
          //     "CentralizedIsolation": 0,
          //     "InHomeIsolation": 0,
          //     "HealthTesting": 0,
          //     "ReleasedControl": 0
          //   }
        ],
        "CentralizedIsolationTotalNum": 0,
        "InHomeIsolationTotalNum": 0,
        "HealthTestingTotalNum": 0,
        "ReleasedControlTotalNum": 0,
        "CentralizedIsolationCompareNum": 0,
        "InHomeIsolationCompareNum": 0,
        "HealthTestingCompareNum": 0,
        "ReleasedControlCompareNum": 0
      }
    });
    let getDateList = () => {
      //posttest({"username":"admin","password":"admin","uuid":"e50547e4-1135-4bc6-8030-f9c54032a847","captcha":"www"})

      //dataForm.data=  getPersoninfoDataList({limit:10,listType:1,page:dataForm.currentPage,personName:dataForm.searchKey})
    }
    const onSearch = (val) => {
      dataForm.searchKey = val
      getDateList()
    };
    const timeFormat = function (time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return year + '年' + month + '月' + day + '日'
    }
    const controlStatusDict = ref({
      "id": "1513719424860971009",
      "dictType": "controlStatus",
      "dataList": [
        {
          "dictTypeId": "1513719424860971009",
          "dictLabel": "居家隔离中",
          "dictValue": "InHomeIsolation"
        },
        {
          "dictTypeId": "1513719424860971009",
          "dictLabel": "集中隔离中",
          "dictValue": "CentralizedIsolation"
        },
        {
          "dictTypeId": "1513719424860971009",
          "dictLabel": "已解控",
          "dictValue": "ReleasedControl"
        },
        {
          "dictTypeId": "1513719424860971009",
          "dictLabel": "健康检测中",
          "dictValue": "HealthTesting"
        }
      ]
    })
    const isLoading =ref(false)
    return { dataForm, isLoading,getDateList, onSearch, timeFormat, controlStatusDict }
  },
  mounted() {
    this.$emit('changeTitle', "全部人员列表",)
    this.$emit("setActive", "report");
   
    if (this.$route.query.personType != null & this.$route.query.personType != "") {
      this.dataForm.personType = this.$route.query.personType;
      this.dataForm.crowdType = this.$route.query.crowdType;
    }
    document.title = (this.dataForm.personType == "" ? "全部类型" : this.getDictLabel('PeopleType', this.dataForm.personType)) + "-统计报表"
    this.controlPrsonnelTj({ personType: this.dataForm.personType,crowdType:this.dataForm.crowdType  });
    

  },
  activated() {
    this.$emit("setActive", "report");
   
    if (this.$route.query.personType != null & this.$route.query.personType != "") {
      this.dataForm.personType = this.$route.query.personType;
      this.dataForm.crowdType = this.$route.query.crowdType;


    }
    document.title = (this.dataForm.personType == "" ? "全部类型" : this.getDictLabel('PeopleType',this.dataForm.personType)) + "-统计报表"
    this.controlPrsonnelTj({ personType: this.dataForm.personType,crowdType:this.dataForm.crowdType });

  }, methods:
  {
    controlPrsonnelTj(params) {
      Api.controlPrsonnelTj(params).then((res) => {
        this.dataForm.data = res.data
        this.isLoading = false
              this.controlStatusDict =  JSON.parse( JSON.stringify( this.$store.state.Dict.find(p => p.dictType == 'controlStatus')))
        this.controlStatusDict.dataList.push({dictLabel:"管控措施未明确",dictValue:'NotCheckNum'})
         this.controlStatusDict.dataList.push({dictLabel:"未落实管控",dictValue:'WorkableControlNum'})
      })

    }, getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(p => p.dictType == type).dataList.find(p => p.dictValue == value).dictLabel
      } catch {
        return ''
      }
    },

  }

}
</script>
<style scoped>
.about {
  background: #ffffff;
  padding: 10px;
}
.dataItem {
  margin: 15px 10px 15px 10px;
  border: 1px solid rgb(212, 212, 212);
  padding-top: 10px;
  background: #f1e8e88f;
}
.dataItem .r1 .title {
  color: #1c1c1c;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}
.reportRow {
  margin: 0 10px 0 10px;
  text-align: center;
  padding: 10px 0 10px 0;
}
.reportRow .reportCol {
  width: 100%;

  background: #ffffff;
  height: 80px;
  align-items: center;
}

.reportRow .reportCol .r1 {
  width: 100%;
  font-size: 18px;
  height: 30%;
  justify-content: center;
  align-items: flex-end;
  color: #5776e6;
  font-weight: 600;
  color: #5776e6;
  line-height: 24px;
  padding-top: 10px;
}

.reportRow .reportCol .item1 {
  color: #fa5555;
}

.reportRow .reportCol .item2 {
  color: #ff881c;
}

.reportRow .reportCol .item3 {
  color: #5ed6c8;
}

.reportRow .reportCol .item4 {
  color: #51d74e;
}
.reportRow .reportCol .r2 {
  justify-content: center;
  align-items: center;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  color: #1b2026;
  line-height: 24px;
}
.reportRow .reportCol .r3 {
  width: 100%;
  font-size: 11px;
  height: 30%;
  justify-content: center;
  align-items: flex-start;
  color: #858ebd;
}
.reportRow .reportCol .r3 label {
  font-size: 12px;
  line-height: 18px;
}
.ListRow {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 12px;
  background: #f6f6f6;
  border-radius: 14px;
}
.ListHead {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px;
  background: #ffffff;
  font-size: 12px;
  color: #5a6080;
  font-weight: 400;
}
</style>
