<template>
  <van-pull-refresh
    v-model="isLoading"
    success-text="刷新成功"
    @refresh="
      dataForm.currentPage = 1;
      getDateList(1);
    "
  >
    <van-tabs style="margin-top: 10px" v-model:active="activeTransport">
      <van-tab title="待审核">
    <div class="about">
      <van-search
        v-model="dataForm.searchKey1"
        @search="onSearch1"
        placeholder="请输入姓名"
      />
      <div
        style="
          font-size: 14px;
          color: #999;
          background: #f7f8fa;
          text-align: left;
          padding-left: 20px;
          margin: 3px 0 3px 0;
          height: 14px;
          line-height: 24px;
        "
      >
        为你筛选出<label style="color: red" v-html="dataForm.data.total"></label
        >条结果
      </div>
    </div>
    <div class="dataList">
      <router-link
        :to="{
          path: '/changePersonDetial',
          query: { id: item.personId},
        }"
        v-for="(item, index) in dataForm.data.list"
        :key="index"
      >
        <div class="dataItem">
          <van-row class="r1">
            <span class="title">{{ item.personInfo.personName }}</span>
            <van-tag
              class="leftTag"
              color="#5776e6"
              text-color="#e8ebff"
              v-if="
                item.personInfo.personType != null &&
                item.personInfo.personType != ''
              "
              >{{
                this.getDictLabel("PeopleType", item.personInfo.personType)
              }}</van-tag
            >   
            <span
              @click.stop.prevent="
                showApprovebox(
                  item.personInfo.community,
                  item.dataType,
                  item.id,
                  item.gridId,
                  item.personId,
                )
              "
              class="approveBtn"
              >审核</span
            >

          </van-row>
          <van-row class="r2">
            <van-col span="10">
              <div class="itemTitle">变更类型</div>
              <div class="itemValue">
                {{
                 item.dataType === 2
                    ? "管控措施变更"
                    : item.dataType === 0
                    ? "暂无变更"
                    : item.dataType === 3
                    ? "其他"
                    : "取消转运变更"
                }}
<!--                item.dataType === 1
                ? "网格变更"
                :-->
              </div>
            </van-col>
            <van-col class="colMiddle" span="6">
              <div class="itemTitle">申请人</div>
              <div class="itemValue">{{ item.creatorName }}</div>
            </van-col>
            <van-col class="colMiddle" span="6">
              <div class="itemTitle">审批结果</div>
              <div class="itemValue">{{ item.approveResult }}</div>
            </van-col>
          </van-row>
          <div class="reason"><span style="color:#f44">申请理由：</span>{{item.content}}</div>
        </div>
      </router-link>
      <van-pagination
        v-if="dataForm.data.total > 0"
        v-model="dataForm.currentPage"
        :total-items="dataForm.data.total"
        :items-per-page="10"
        :show-page-size="5"
        @change="getDateList(1)"
      />
    </div>
    <van-popup
      v-model:show="showApprove"
      overlay
      id="driverPopup"
      position="bottom"
      closeable
    >
      <van-form style="margin-top: 30px; margin-bottom: 30px">
        <van-field
          v-model="dataChangeForm.approveResult"
          name="审批结果"
          label="审批结果"
          placeholder="审批结果"
          :rules="[{ required: true, message: '审批结果必填' }]"
        />
         <van-row style="margin-top: 15px">
          <van-col
            style="font-size: 14px; color: #646566;margin-left:-3px;margin-bottom:5px"
            span="6"
            >变更类型</van-col
          >
          <van-col span="18">
             <el-select v-model="dataChangeForm.dataType" clearable style="width:100%" disabled>
<!--            <el-option label="网格变更"  :value=1></el-option>-->
            <el-option label="管控措施变更"  :value=2></el-option>
            <el-option label="其他"  :value=3></el-option>
			<el-option label="取消转运变更"  :value=4></el-option>
       </el-select>
          </van-col>

        </van-row>
        <van-row style="margin-top: 15px" v-if="dataChangeForm.dataType==1">
          <van-col
            style="font-size: 14px; color: #646566;margin-left:-3px;margin-bottom:5px"
            span="6"
            >所属社区</van-col
          >
          <van-col span="18">
            <el-select
              style="width: 98%; margin-top: -2px"
              v-model="dataChangeForm.community"
              ref="gridField"
              placeholder="请选择社区"
            >
              <el-option
                v-for="item in communityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </van-col>
        </van-row>
        <van-row style="margin-top: 15px" v-if="dataChangeForm.dataType==1">
          <van-col
            style="font-size: 14px; color: #646566;margin-left:-3px;margin-bottom:5px"
            span="6"
            >所属网格</van-col
          >
          <van-col span="18">
            <el-select
              style="width: 98%; margin-top: -2px"
              v-model="dataChangeForm.gridId"
              ref="gridField"
              placeholder="请选择网格"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </van-col>
        </van-row>
        <van-field
          v-model="dataChangeForm.remark"
          name="备注"
          label="备注"
          placeholder="备注"
        />
        <div style="margin-top: 22px">
          <van-button
            size="small"
            type="default"
            @click="
              showApprove = false;
              initApprove();
            "
            >取消</van-button
          >
          <van-button
            size="small"
            type="success"
            @click="setApprove(1)"
            style="margin: 0 10px"
            >通过</van-button
          >
          <van-button size="small" type="warning" @click="setApprove(2)"
            >驳回</van-button
          >
        </div>
      </van-form>
    </van-popup>
      </van-tab>
       <van-tab title="已完成">
    <div class="about">
      <van-search
        v-model="dataForm.searchKey2"
        @search="onSearch2"
        placeholder="请输入姓名"
      />
      <div
        style="
          font-size: 14px;
          color: #999;
          background: #f7f8fa;
          text-align: left;
          padding-left: 20px;
          margin: 3px 0 3px 0;
          height: 14px;
          line-height: 24px;
        "
      >
        为你筛选出<label style="color: red" v-html="dataForm.data2.total"></label
        >条结果
      </div>
    </div>
    <div class="dataList">
      <router-link
        :to="{
          path: '/changePersonDetial',
          query: { id: item.personId},
        }"
        v-for="(item, index) in dataForm.data2.list"
        :key="index"
      >
        <div class="dataItem">
          <van-row class="r1">
            <span class="title">{{ item.personInfo.personName }}</span>
            <van-tag
              class="leftTag"
              color="#5776e6"
              text-color="#e8ebff"
              v-if="
                item.personInfo.personType != null &&
                item.personInfo.personType != ''
              "
              >{{
                this.getDictLabel("PeopleType", item.personInfo.personType)
              }}</van-tag
            >
         
          </van-row>
          <van-row class="r2">
            <van-col span="10">
              <div class="itemTitle">变更类型</div>
              <div class="itemValue">
                {{
                 item.dataType === 2
                    ? "管控措施变更"
                    : item.dataType === 0
                    ? "暂无变更"
                    : item.dataType === 3
                    ? "其他"
                    : "取消转运变更"
                }}
<!--                item.dataType === 1
                ? "网格变更"
                :-->
              </div>
            </van-col>
            <van-col class="colMiddle" span="6">
              <div class="itemTitle">申请人</div>
              <div class="itemValue">{{ item.creatorName }}</div>
            </van-col>
            <van-col class="colMiddle" span="6">
              <div class="itemTitle">审批结果</div>
              <div class="itemValue">{{ item.approveResult }}</div>
            </van-col>
          </van-row>
          <div class="reason"><span style="color:#f44">申请理由：</span>{{item.content}}</div>
        </div>
      </router-link>
      <van-pagination
        v-if="dataForm.data2.total > 0"
        v-model="dataForm.currentPage2"
        :total-items="dataForm.data2.total"
        :items-per-page="10"
        :show-page-size="5"
        @change="getDateList(2)"
      />
    </div>
      </van-tab>
    </van-tabs>
  </van-pull-refresh>
</template>

<script>
// @ is an alias to /src
import { reactive, ref } from "vue";
import Api from "../util/App";
import { Notify } from 'vant';
//import { getPersoninfoDataList,posttest } from '../util/Api'
export default {
  name: "ApproveList",
  components: {},
  inject: ["setActive"],
  setup() {
    const showApprove = ref(false);
    const dataChangeForm = ref({
      dataType: "",
        approveResult: "",
        remark: "",
        gridId: "",
        ids:[],
        changeType:1,
        status:'',
        community:''
    });
    const options = ref([]);
    const role = ref("");
    const dataForm = reactive({
      searchKey1: "",
      searchKey2: "",
      currentPage: 1,
      currentPage2: 1,
      data: {
        total: 0,
        list: [],

      },
      data2: {
        total: 0,
        list: [],

      },
    });
    const communityList=ref([]);
    const TagColors = {
      status: {
        1: {
          color: "#e6a23c",
          bgColor: "#fdf6ec",
        },
        2: {
          color: "#67c23a",
          bgColor: "#f0f9eb",
        },
        3: {
          color: "#fde2e2",
          bgColor: "#fef0f0",
        },
      },
    };
    const isLoading = ref(false);
    return {
      communityList,
      dataChangeForm,
      options,
      showApprove,
      role,
      dataForm,
      TagColors,
      isLoading,
    };
  },
  mounted() {
    this.getDateList(1);
    this.getDateList(2);
  },
  activated() {
    this.role = window.localStorage.getItem("role");
    this.$emit("setActive", "ApproveList");
    this.getDateList(1);
    this.getDateList(2);
  },
  watch:{
    "dataChangeForm.community"(newval,oldval){
        if(newval!=oldval){
          this.getAllGrid(newval);
        }
    }
  },
  methods: {
    initApprove() {
      this.dataChangeForm = {
        dataType: "",
        approveResult: "",
        remark: "",
        gridId: "",
        ids:[],
        changeType:1,
        status:'',
        personId:'',
      }
    },
     getCommunityList() {
      this.communityList = [];
      Api.getOrgCommunityList().then((res) => {
        console.log(res)
         this.communityList=res.data;
        // res.data.forEach((item, index) => {
        //   this.communityList.push({ text: item.name, value: item.id });
        // });
        console.log(this.communityList)
      });
    },
    showApprovebox(community, type,id,gridid,personId) {
      this.showApprove = true;
      this.getAllGrid(community);
      this.dataChangeForm.community=community;
      this.getCommunityList();
      this.dataChangeForm.ids=[id];
      this.dataChangeForm.dataType = type;
      this.dataChangeForm.gridId=gridid;
      this.dataChangeForm.personId=personId;
    },
    setApprove(t) {
      if(this.dataChangeForm.approveResult==''){
       Notify({ type: "danger", message: "请选择填写审批结果!" });
        return false;
      }
       if(this.dataChangeForm.gridId==''){
       Notify({ type: "danger", message: "请选择所属网格!" });
        return false;
      }
      if (t == 1) {
        this.dataChangeForm.status=2;
      } else {
        this.dataChangeForm.status=3;
      }
      Api.datachange(this.dataChangeForm).then(res=>{
        console.log(res)
        if(res.code == 0){
          if(t==1&&this.dataChangeForm.dataType!=1){
          this.$router.push({name: '人员信息调整',query: { id: this.dataChangeForm.personId}})
          }
          this.showApprove=false;
          this.initApprove();
          this.getDateList(1);
        }
      })
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(
          (p) => p.dictType == type
        ).dataList.find((p) => p.dictValue == value).dictLabel;
      } catch {
        return "";
      }
    },
    getAllGrid(id) {
      console.log(id);
      let that = this;
      Api.getGridList({ community: id }).then((res) => {
        that.options = res.data;
      });
    },
    getDateList(t) {
      let page=t==1?this.dataForm.currentPage:this.dataForm.currentPage2;
      let searchKey=t==1?this.dataForm.searchKey1:this.dataForm.searchKey2;
      let json = {
        limit: 10,
        page:page,
        personName:searchKey,
        statusApp:t
      };
      Api.PersonPageList(json).then((res) => {
        if(t==1){
           this.dataForm.data = res.data;
        }else{
           this.dataForm.data2 = res.data;
        }
        this.isLoading = false;
      });
    },
    
    onSearch1(val) {
      this.dataForm.currentPage1 = 1;
      this.dataForm.searchKey1 = val;
      this.getDateList(1);
    },
    onSearch2(val) {
      this.dataForm.currentPage2 = 1;
      this.dataForm.searchKey2 = val;
      this.getDateList(2);
    },
  },
};
</script>
<style scoped>
.reason{
  text-align: left;
    padding: 5px 10px;
    font-size: 14px;
    color: #3c3c3c;
}
.approveBtn {
  font-size: 13px;
  background: #2377d6;
  color: #fff;
  border-radius: 4px;
  padding: 3px 5px;
  position: absolute;
  right: 15px;
  top: auto;
  z-index: 10;
}
.dataList {
  background: #f8f8f8ff;
}
.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
  padding-bottom: 10px;
}
.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}
.dataItem .r1 .leftTag {
  margin-right: 5px;
}
.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}
.dataItem .r2 .itemTitle {
  color: #999999;
}
.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
  min-height: 90vh;
}
</style>
