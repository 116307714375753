<template>
  <div class="about">
    <div v-if="showTab == '人员信息'">
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="姓名"
        :value="personInfo.personName"
      />
      <template v-if="personInfo.personType!='TwentyEight'">
        <!-- <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="性别"
          :value="personInfo.sex"
        /> -->
      </template>

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="证件号码"
        :value="personInfo.idCard"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="手机号码"
      >
        <template #value>
          <a :href="'tel:' + personInfo.phone">{{ personInfo.phone }} </a>
        </template>
      </van-cell>
      <div v-show="isShowDetail">
        <template v-if="personInfo.personType=='TwentyEight'">
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="人员类型"
            :value="personInfo.personLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="人群类型"
            :value="personInfo.crowdTypeLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属社区"
            :value="personInfo.communityLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属网格"
            :value="personInfo.gridLabel"
          />
          <!-- <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="工作单位"
            :value="personInfo.workUnit"
          /> -->
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="单位地址"
            :value="personInfo.workAddress"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属行业"
            :value="personInfo.industryType"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            :value="personInfo.remark"
            title="其他说明"
          />
        </template>
        <template v-else>
          <!-- <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="工作单位"
            :value="personInfo.workUnit"
          /> -->
          <!-- <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="职业"
            :value="personInfo.occupation"
          /> -->
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="现居住地"
            :value="personInfo.address"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属社区"
            :value="DataInfo.Community"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属网格"
            :value="personInfo.gridLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属小区"
            :value="DataInfo.Live"
          />
          <van-cell
            title-class="van-cell_title"
            v-if="role != 'GridPerson'"
            value-class="van-cell_value"
            title="网格长"
            :value="personInfo.gridPersonName"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="居住楼栋"
            :value="DataInfo.Store"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="居住房屋号"
            :value="DataInfo.House"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="户籍地址"
            :value="personInfo.permanentAddress"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="基础疾病"
            :value="personInfo.disease"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="数据来源"
            :value="this.getDictLabel('DataSourceType', personInfo.dataSoucre)"
          />
        </template>
      </div>

      <div
        style="
          width: 100%;
          text-align: center;
          font-size: 12px;
          backgroup-color: #eee;
          padding: 5px 0 5px 0;
        "
        @click="isShowDetail = !isShowDetail"
      >
        {{ isShowDetail ? "收起详细" : "展开详细" }}&nbsp;
        <van-icon :name="isShowDetail ? 'arrow-up' : 'arrow-down'" />
      </div>
      <div style="margin-top: 10px; text-align: center">
        <van-button
          v-if="(role == 'GridPerson' || role == 'swjAdmin')&&personInfo.personType!='TwentyEight'"
          plain
          hairline
          type="primary"
          style="margin-right: 20px; margin-top: 5px"
          :to="{
            name: '人员信息调整',
            query: { id: personInfo.id,type:2 },
          }"
        >人员详细信息调整</van-button>
        <van-button
          v-if="(role == 'GridPerson' || role == 'swjAdmin')&&personInfo.personType=='TwentyEight'"
          plain
          hairline
          type="primary"
          style="margin-top: 20px"
          :to="{
            name:'50类上报',
            query: { id: personInfo.id },
          }"
        >调整</van-button>
        <!-- <van-button 
          v-if="role == 'GridPerson' && personInfo.controlMsg == null"
          plain
          hairline
          type="primary"
          style="margin-right: 20px"
          @click="
            active = 'approvetab';
            approveActive = 'addApprove';
            showTab = '变更申请';
            addApprove = true;
            approveInfo.changeType = 1;
          "
        >申请变更网格信息</van-button>-->
        <van-button
          v-if="role == 'GridPerson' && personInfo.isTransport == 1"
          plain
          hairline
          type="primary"
          style="margin-right: 20px;"
          @click="
            active = 'approvetab';
            approveActive = 'addApprove';
            showTab = '变更申请';
            addApprove = true;
            approveInfo.changeType = 1;
            approveInfo.dataType = '4';
          "
        >取消转运变更</van-button>
      </div>
    </div>
    <div v-if="showTab == '管控措施'">
      <!-- 密接次密接 需填写病例 、密接姓名、m末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 阳性物品暴露人员 需填写 末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 高风险地区来（返）肥人员 需填写  返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 红黄码人员 需填写 安康码、行程码-->
      <!-- 其他情况 需填写  其他说明、安康码、行程码-->

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="人员类型"
        :value="
          dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]
        "
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="密接病例"
        :value="personInfo.touchCase"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) == 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="推送次密接地区"
        :value="DataInfo.pushRegion"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) == 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="密接所在地区"
        :value="DataInfo.touchRegion"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="接触方式"
        :value="personInfo.releaseMode"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="末次密接日期"
        :value="personInfo.lastContactDate"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="是否转运"
        :value="personInfo.isTransport == 1 ? '是' : personInfo.isTransport==2?'否':personInfo.isTransport==3?'道口转运':''"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) == 2 ||
          personInfo.personType == 'BjStudent'
        "
        title="是否抵肥"
        :value="personInfo.isDraft"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          (dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) <= 4) ||
          personInfo.personType == 'BjStudent'
        "
        title="返回合肥时间"
        :value="personInfo.returnDate"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="personInfo.personType == 'BjStudent'"
        title="到家时间"
        :value="personInfo.arriveHomeTime"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          (dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) <= 4) ||
          personInfo.personType == 'BjStudent'
        "
        title="离肥时间"
        :value="personInfo.leaveTime"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if=" personInfo.personType == 'ReturnHf'||personInfo.personType == 'HighRisk'"
        title="末次接触日期"
        :value="personInfo.lastContactDate"
      />
       <template v-if="personInfo.personType =='Touch'||personInfo.personType =='SecondTouch'">
        <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="病例所在市县区"
        :value="personInfo.caseRegion"
      />
       <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="第一次采样时间"
        :value="personInfo.firstTime"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="第二次采样时间"
        :value="personInfo.secondTime"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="第三次采样时间"
        :value="personInfo.thirdTime"
      />
       </template>
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          (dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) <= 4) ||
          personInfo.personType == 'BjStudent'
        "
        title="来源区域"
        :value="personInfo.soureRegion"
      />

      <van-cell
        v-if="
          personInfo.personType == 'ReturnHf' ||
            personInfo.personType == 'HighRisk'
        "
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="风险等级"
        :value="personInfo.riskName"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="personInfo.personType == 'HighRisk'"
        title="数据类型"
        :value="personInfo.dataType"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="personInfo.personType == 'HighRisk'"
        title="住宿信息"
        :value="personInfo.stayMsg"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 2 &&
          dicPersonType.indexOf(personInfo.personType) !=3
        "
        title="交通工具"
        :value="personInfo.vehicle"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 2 &&
          dicPersonType.indexOf(personInfo.personType) != 3
        "
        title="航班车次号"
        :value="personInfo.flightNumber"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="dicPersonType.indexOf(personInfo.personType) == 0||dicPersonType.indexOf(personInfo.personType) == 1"
        title="转码时间"
        :value="personInfo.redYellowDate"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="安康码"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.ankangCode"
            @click="
              ImagePreview({
                images: [personInfo.ankangCode],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="行程码"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.tripCode"
            @click="
              ImagePreview({
                images: [personInfo.tripCode],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="隔离城市"
        v-if="dicPersonType.indexOf(personInfo.personType) == 4"
        :value="personInfo.isolatedCity"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="隔离酒店"
        :value="personInfo.isolatedHotel"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          (dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) <= 4) ||
          personInfo.personType == 'BjStudent'
        "
        :title="
          dicPersonType.indexOf(personInfo.personType) <= 3
            ? '返回后核酸'
            : '核酸检测照片'
        "
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.nucleinImg"
            @click="
              ImagePreview({
                images: [personInfo.nucleinImg],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="dicPersonType.indexOf(personInfo.personType) == 4"
        title="解除隔离证明"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.isolatedProve"
            @click="
              ImagePreview({
                images: [personInfo.isolatedProve],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="其他说明"
        :label="personInfo.remark"
      />

      <div v-if="personInfo.controlMsg != null">
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控措施"
          :value="
            this.getDictLabel('controlStep', personInfo.controlMsg.controlStep)
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控状态"
          :value="
            this.getDictLabel(
              'controlStatus',
              personInfo.controlMsg.controlStatus
            )
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="隔离模式"
          :value="
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null
              ? IsolatedProveList.find(
                  (p) => p.id == personInfo.controlMsg.isolationMode
                ).patternName
              : ''
          "
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="解除集中隔离日期"
          v-if="
            personInfo.controlMsg.controlStep == 'FocusSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).focusDay != 0
          "
          :value="personInfo.controlMsg.relieveCentralizeDate"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="集中隔离填报周期"
          v-if="
            personInfo.controlMsg.controlStep == 'FocusSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).focusDay != 0
          "
          :value="
            this.getDictLabel(
              'ReportingCycle',
              personInfo.controlMsg.centralizeCycle
            )
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="解除居家隔离日期"
          v-if="
            (personInfo.controlMsg.controlStep == 'FocusSegregation' ||
              personInfo.controlMsg.controlStep == 'InHomeSegregation') &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).homeDay != 0
          "
          :value="personInfo.controlMsg.relieveHomeDate"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="居家隔离填报周期"
          v-if="
            (personInfo.controlMsg.controlStep == 'FocusSegregation' ||
              personInfo.controlMsg.controlStep == 'InHomeSegregation') &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).homeDay != 0
          "
          :value="
            this.getDictLabel('ReportingCycle', personInfo.controlMsg.homeCycle)
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          v-if="
            personInfo.controlMsg.controlStep != 'NoSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).healthDay != 0
          "
          title="解除健康监测日期"
          :value="personInfo.controlMsg.relieveHealthDate"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="健康监测填报周期"
          v-if="
            personInfo.controlMsg.controlStep != 'NoSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).healthDay != 0
          "
          :value="
            this.getDictLabel(
              'ReportingCycle',
              personInfo.controlMsg.healthCycle
            )
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          v-if="personInfo.controlMsg.controlStep != 'NoSegregation'"
          title="管控方式"
          :value="
            this.getDictLabel('controlType', personInfo.controlMsg.controlType)
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          v-if="personInfo.controlMsg.controlStep != 'NoSegregation'"
          title="管控设备序列号"
          :value="personInfo.controlMsg.deviceImei"
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控备注"
          :value="personInfo.controlMsg.remark"
        />
      </div>
      <div style="text-align: center">
        <van-button
          v-if="role == 'SwjAdmin'||role == 'GridPerson'"
          plain
          hairline
          type="primary"
          style="margin-top: 20px"
          :to="{
            name: personInfo.status == 2 ? '人员信息审核' : '人员信息调整',
            query: { id: personInfo.id },
          }"
        >{{ personInfo.status == 2 ? "审核" : "调整" }}</van-button>

        <!-- @click="controlEdit ? submitContorl() : (controlEdit = true)" -->
        <van-button
          v-if="role == 'GridPerson' && personInfo.controlMsg != null&&personInfo.personType!='TwentyEight'"
          plain
          hairline
          type="primary"
          style="margin-right: 20px; width: 142px"
          @click="
            active = 'approvetab';
            approveActive = 'addApprove';
            showTab = '变更申请';
            addApprove = true;
            approveInfo.changeType = 1;
          "
        >申请变更管控信息</van-button>
        <van-button
          v-if="role == 'GridPerson' && personInfo.controlMsg != null&&personInfo.personType!='TwentyEight'"
          plain
          hairline
          type="primary"
          style="margin-right: 20px; width: 142px"
          @click="
            active = 'approvetab';
            approveActive = 'addApprove';
            showTab = '变更申请';
            addApprove = true;
            approveInfo.changeType = 2;
          "
        >申请继续集中隔离</van-button>

        <van-button
          plain
          hairline
          type="primary"
          style="margin-right: 20px; width: 142px; margin-top: 5px"
          v-if="role == 'GridPerson' && personInfo.controlMsg != null&&personInfo.personType!='TwentyEight'"
          @click="isShowControlType = true"
        >
          管 控 方 式 录 入
        </van-button>
      </div>
    </div>
    <div v-if="showTab == '每日填报'">
      <van-cell-group
        title="今日填报"
        v-if="
          personInfo.controlMsg != null &&
          personInfo.controlMsg.controlStatus != 'ReleasedControl' &&
          role == 'GridPerson'
        "
        inset
      >
        <!-- 每日填报 -->
        <div v-if="personInfo.dailyMsg != null">
          <van-form
            @failed="onFailed"
            @submit="submit"
          >
            <div class="about">
              <van-cell title="第一次体温">
                <template #value>
                  <van-stepper
                    v-model="personInfo.dailyMsg.firTemp"
                    step="0.1"
                    :default-value="36.5"
                    :decimal-length="1"
                    max="42"
                    min="33"
                    :rules="[{ required: true, message: '第一次体温' }]"
                  />
                </template>
              </van-cell>
              <van-cell title="第二次体温">
                <template #value>
                  <van-stepper
                    v-model="personInfo.dailyMsg.secTemp"
                    step="0.1"
                    :default-value="36.5"
                    :decimal-length="1"
                    max="42"
                    min="33"
                    :rules="[{ required: true, message: '第二次体温' }]"
                  />
                </template>
              </van-cell>

              <van-cell title="核酸照片">
                <template #value>
                  <van-uploader
                    width="100"
                    height="100"
                    v-model="personInfo.dailyMsg.voucherImgList"
                    :after-read="(file) => afterRead(file, 'voucherImg')"
                    max-count="1"
                  />
                </template>
              </van-cell>
              <van-cell title="解除隔离证明">
                <template #value>
                  <van-uploader
                    width="100"
                    height="100"
                    v-model="personInfo.dailyMsg.releaseCertificateList"
                    :after-read="
                      (file) => afterRead(file, 'releaseCertificate')
                    "
                    max-count="1"
                  />
                </template>
              </van-cell>
            </div>
            <div style="margin: 16px">
              <van-button
                round
                block
                type="primary"
                native-type="submit"
              >
                提交
              </van-button>
            </div>
          </van-form>
        </div>
      </van-cell-group>
      <van-cell-group
        title="历史填报"
        inset
      >
        <!-- 填报记录 -->
        <div class="dataList">
          <div
            v-if="personInfo.healthVouchers == null"
            class="emptyData"
          >
            暂无数据
          </div>
          <div
            class="dataItem"
            v-for="(item, index) in personInfo.healthVouchers"
            :key="index"
          >
            <van-row class="r1">
              <span class="title">{{ item.personName }}</span>
            </van-row>

            <van-row class="r2">
              <van-col span="8">
                <div class="itemTitle">填报时间</div>
                <div class="itemValue">{{ item.voucherDate }}</div>
              </van-col>
              <van-col
                class="colMiddle"
                span="8"
              >
                <div class="itemTitle">第一次体温</div>
                <div class="itemValue">{{ item.firTemp }}℃</div>
              </van-col>
              <van-col span="8">
                <div class="itemTitle">第二次体温</div>
                <div class="itemValue">{{ item.secTemp }}℃</div>
              </van-col>
            </van-row>

            <van-row class="r2">
              <van-col span="6">
                <div class="itemTitle">核酸证明图片</div>
              </van-col>
              <van-col span="6">
                <div>
                  <van-image
                    width="50"
                    height="50"
                    :src="item.voucherImg"
                    @click="
                      ImagePreview({
                        images: item.voucherImg == '' ? [] : [item.voucherImg],
                        closeable: true,
                      })
                    "
                  />
                </div>
              </van-col>
              <van-col span="6">
                <div class="itemTitle">解除隔离证明</div>
              </van-col>
              <van-col span="6">
                <div>
                  <van-image
                    width="50"
                    height="50"
                    :src="item.releaseCertificate"
                    @click="
                      ImagePreview({
                        images:
                          item.releaseCertificate == ''
                            ? []
                            : [item.releaseCertificate],

                        closeable: true,
                      })
                    "
                  />
                </div>
              </van-col>
            </van-row>
          </div>
          <div
            class="dataItem"
            v-for="(item, index) in personInfo.homeVouchers"
            :key="index"
          >
            <van-row class="r1">
              <span class="title">{{ item.personName }}</span>
            </van-row>

            <van-row class="r2">
              <van-col span="8">
                <div class="itemTitle">填报时间</div>
                <div class="itemValue">{{ item.voucherDate }}</div>
              </van-col>
              <van-col
                class="colMiddle"
                span="8"
              >
                <div class="itemTitle">第一次体温</div>
                <div class="itemValue">{{ item.firTemp }}℃</div>
              </van-col>
              <van-col span="8">
                <div class="itemTitle">第二次体温</div>
                <div class="itemValue">{{ item.secTemp }}℃</div>
              </van-col>
            </van-row>

            <van-row class="r2">
              <van-col span="6">
                <div class="itemTitle">核酸证明图片</div>
              </van-col>
              <van-col span="6">
                <div>
                  <van-image
                    width="50"
                    height="50"
                    :src="item.voucherImg"
                    @click="
                      ImagePreview({
                        images: item.voucherImg == '' ? [] : [item.voucherImg],
                        closeable: true,
                      })
                    "
                  />
                </div>
              </van-col>

              <van-col span="6">
                <div class="itemTitle">解除隔离证明</div>
              </van-col>
              <van-col span="6">
                <div>
                  <van-image
                    width="50"
                    height="50"
                    :src="item.releaseCertificate"
                    @click="
                      ImagePreview({
                        images:
                          item.releaseCertificate == ''
                            ? []
                            : [item.releaseCertificate],

                        closeable: true,
                      })
                    "
                  />
                </div>
              </van-col>
            </van-row>
          </div>
          <div
            class="dataItem"
            v-for="(item, index) in personInfo.centralizeVouchers"
            :key="index"
          >
            <van-row class="r1">
              <span class="title">{{ item.personName }}</span>
            </van-row>

            <van-row class="r2">
              <van-col span="8">
                <div class="itemTitle">填报时间</div>
                <div class="itemValue">{{ item.voucherDate }}</div>
              </van-col>
              <van-col
                class="colMiddle"
                span="8"
              >
                <div class="itemTitle">第一次体温</div>
                <div class="itemValue">{{ item.firTemp }}℃</div>
              </van-col>
              <van-col span="8">
                <div class="itemTitle">第二次体温</div>
                <div class="itemValue">{{ item.secTemp }}℃</div>
              </van-col>
            </van-row>

            <van-row class="r2">
              <van-col span="6">
                <div class="itemTitle">核酸证明图片</div>
              </van-col>
              <van-col span="6">
                <div>
                  <van-image
                    width="50"
                    height="50"
                    :src="item.voucherImg"
                    @click="
                      ImagePreview({
                        images: item.voucherImg == '' ? [] : [item.voucherImg],
                        closeable: true,
                      })
                    "
                  />
                </div>
              </van-col>

              <van-col span="6">
                <div class="itemTitle">解除隔离证明</div>
              </van-col>
              <van-col span="6">
                <div>
                  <van-image
                    width="50"
                    height="50"
                    :src="item.releaseCertificate"
                    @click="
                      ImagePreview({
                        images:
                          item.releaseCertificate == ''
                            ? []
                            : [item.releaseCertificate],

                        closeable: true,
                      })
                    "
                  />
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
      </van-cell-group>
    </div>
    <div
      v-if="
        (personInfo.transportInfoList == null ||
          personInfo.transportInfoList.length == 0) &&
        showTab == '转运记录'
      "
      class="emptyData"
    >
      暂无数据
    </div>
    <div v-if="personInfo.transportInfoList != null && showTab == '转运记录'">
      <van-cell-group
        v-for="(item, index) in personInfo.transportInfoList"
        :title="'记录' + (index + 1)"
        :key="index"
      >
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="转运类型"
          :value="this.getDictLabel('TransportType', item.carType)"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="转运状态"
          :value="this.getDictLabel('TransportStatus', item.status)"
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="目的地点"
          :value="item.destination"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="出发时间"
          :value="item.transportTime"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="到达时间"
          :value="item.arriveTime"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="司机姓名"
          :value="item.driverName"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="司机电话"
          :value="item.driverPhone"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="备注"
          :value="item.remark"
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="转运凭证"
        >
          <template #value>
            <van-image
              width="100"
              height="100"
              :src="item.transportImg"
              @click="
                ImagePreview({
                  images: [item.transportImg],
                  closeable: true,
                })
              "
            />
          </template>
        </van-cell>
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="转运单凭证"
        >
          <template #value>
            <van-image
              width="100"
              height="100"
              :src="item.orderImg"
              @click="
                ImagePreview({
                  images: [item.orderImg],
                  closeable: true,
                })
              "
            />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div v-if="showTab == '门磁记录'">
      <div class="dataList">
        <div
          class="dataItem"
          style="height: 80px"
          v-for="(item, index) in SensorList.data.list"
          :key="index"
        >
          <van-row class="r2">
            <van-col span="6">
              <span class="itemTitleL">报警时间：</span>
            </van-col>
            <van-col span="10">
              <span class="itemTitleL">{{ item.signTime }}</span>
            </van-col>
          </van-row>
          <van-row class="r2">
            <van-col span="6">
              <span class="itemTitleL">报警原因：</span>
            </van-col>
            <van-col span="6">
              <van-tag
                class="leftTag"
                :color="SensorColorArray[item.dataType]"
              >{{ SensorDataTypeArray[item.dataType] }}</van-tag>
            </van-col>
            <van-col span="12">
              <span class="itemTitleL">设备状态：</span>
              <van-tag
                class="leftTag"
                type="primary"
                plain
              >{{
                SensorDeviceStateArray[item.deviceState]
              }}</van-tag>
            </van-col>
          </van-row>
        </div>

        <van-pagination
          v-if="SensorList.data.total > 0"
          v-model="SensorList.currentPage"
          :total-items="SensorList.data.total"
          :items-per-page="10"
          :show-page-size="5"
          @change="getSensorList"
        />
        <div
          v-if="SensorList.data.total == 0"
          class="emptyData"
        >暂无数据</div>
      </div>
    </div>
    <div
      v-if="showTab == '变更申请'"
      name="approvetab"
    >
      <div sticky>
        <div
          title="填写申请"
          name="addApprove"
          v-if="role == 'GridPerson' && addApprove"
        >
          <van-form
            @failed="onFailed"
            @submit="submitApprove"
          >
            <van-cell
              title-class="van-cell_title"
              value-class="van-cell_value"
              title="类型"
            >
              <template #value>
                {{
                  approveInfo.changeType == 2
                    ? "申请继续集中隔离"
                    : "申请变更管控人员信息"
                }}
              </template>
            </van-cell>
            <van-cell
              title-class="van-cell_title"
              value-class="van-cell_value"
              title="变更类型"
              v-if="approveInfo.changeType == 1"
            >
              <template #value>
                <el-select
                  v-model="approveInfo.dataType"
                  placeholder="请选择变更类型"
                >
                  <!-- <el-option
                    value="1"
                    label="网格变更"
                  ></el-option> -->
                  <el-option
                    value="2"
                    label="管控措施变更"
                  ></el-option>
                  <el-option
                    value="3"
                    label="其他"
                  ></el-option>
                  <el-option
                    value="4"
                    label="取消转运变更"
                  ></el-option>
                </el-select>
              </template>
            </van-cell>

            <van-uploader
              v-if="approveInfo.changeType == 2"
              accept="*"
              :after-read="(file) => afterReadReportFile(file, 'reportFile')"
              max-count="1"
              style="margin: 10px 0 10px 20px"
            >
              <van-button
                icon="plus"
                plain
                type="primary"
              >上传报告附件</van-button>
              {{ filename }}
            </van-uploader>
            <van-field
              v-model="approveInfo.content"
              rows="5"
              autosize
              label="申请理由"
              type="textarea"
              maxlength="800"
              placeholder="请输入申请理由"
              show-word-limit
              :rules="[{ required: true, message: '申请理由不可为空' }]"
            />

            <div style="text-align: center">
              <van-button
                type="primary"
                block
                native-type="submit"
              >提交申请</van-button>
            </div>
          </van-form>
        </div>
        <div
          title="申请记录"
          name="approveList"
        >
          <div class="dataList">
            <div
              class="dataItem"
              v-for="(item, index) in approveList.data.list"
              :key="index"
            >
              <van-row class="r2">
                <van-col span="6">
                  <span class="itemTitleL">申请时间：</span>
                </van-col>
                <van-col
                  style="text-align: left"
                  span="10"
                >
                  {{ item.createDate }}
                </van-col>
                <van-col span="8">
                  <van-tag
                    class="rightTag"
                    type="primary"
                    plain
                  >{{
                    approveList.stateArray[item.status]
                  }}</van-tag>
                </van-col>
              </van-row>
              <van-row class="r2">
                <van-col span="6">
                  <span class="itemTitleL">类型：</span>
                </van-col>
                <van-col
                  style="text-align: left"
                  span="18"
                >
                  {{
                    item.changeType == 1
                      ? item.dataType == 1
                        ? "网格变更"
                        : item.dataType == 2
                        ? "管控措施变更"
                         : item.dataType == 4
                        ? "取消转运变更"
                        : "其他"
                      : "申请继续集中隔离"
                  }}
                </van-col>
              </van-row>
              <van-row class="r2">
                <van-col span="6">
                  <span class="itemTitleL">申请理由：</span>
                </van-col>
                <van-col
                  style="text-align: left"
                  span="18"
                >
                  {{ item.content }}
                </van-col>
              </van-row>
              <van-row
                class="r2"
                v-if="item.changeType == 2"
              >
                <van-col span="6">
                  <span class="itemTitleL">附件：</span>
                </van-col>
                <van-col
                  style="text-align: left"
                  span="18"
                >
                  <a
                    v-if="item.reportFile != null && item.reportFile != ''"
                    :href="item.reportFile"
                    target="_blank"
                  >下载附件</a>
                </van-col>
              </van-row>
              <van-row
                class="r2"
                v-if="item.status != 1"
              >
                <van-col span="6">
                  <span class="itemTitleL">审批时间：</span>
                </van-col>
                <van-col
                  style="text-align: left"
                  span="10"
                >
                  {{ item.approveDate }}
                </van-col>
                <van-col span="8"> </van-col>
              </van-row>
              <van-row
                class="r2"
                v-if="item.status != 1"
              >
                <van-col span="6">
                  <span class="itemTitleL">审批批语：</span>
                </van-col>
                <van-col span="18">
                  {{ item.approveResult }}
                </van-col>
              </van-row>
            </div>

            <van-pagination
              v-if="approveList.data.total > 0"
              v-model="approveList.currentPage"
              :total-items="approveList.data.total"
              :items-per-page="10"
              :show-page-size="5"
              @change="getApprovePageList"
            />
            <div
              v-if="approveList.data.total == 0 && !addApprove"
              class="emptyData"
            >
              暂无数据
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      title=""
      v-if="showTab == '时间轴'"
    >
      <div class="dataItem">
        <van-row class="r1">
          <span class="title">{{ personInfo.personName }}</span>
          <van-tag
            class="leftTag"
            color="#E8EBFF"
            text-color="#5776E6"
            v-if="personInfo.personType != null && personInfo.personType != ''"
          >{{
              this.getDictLabel("PeopleType", personInfo.personType)
            }}</van-tag>
          <van-tag
            class="leftTag"
            color="#E8EBFF"
            text-color="#5776E6"
            v-if="
              personInfo.controlMsg != null &&
              personInfo.controlMsg.controlStatus != null &&
              personInfo.controlMsg.controlStatus != ''
            "
          >{{
              this.getDictLabel(
                "controlStatus",
                personInfo.controlMsg.controlStatus
              )
            }}</van-tag>
        </van-row>
        <van-row class="r23">
          <van-col span="24">
            <div
              class="itemTitleL"
              style="padding-left: 10px"
            >
              数据来源：{{
                this.getDictLabel("DataSourceType", personInfo.dataSoucre)
              }}
            </div>
          </van-col>
        </van-row>
        <van-row
          v-if="
            personInfo.controlMsg != null &&
            personInfo.controlMsg.isolationMode != null
          "
          class="r23"
        >
          <van-col span="24">
            <div
              class="itemTitleL"
              style="padding-left: 10px"
            >
              隔离模式：{{
                IsolatedProveList.find(
                  (p) => p.id == personInfo.controlMsg.isolationMode
                ) != null
                  ? IsolatedProveList.find(
                      (p) => p.id == personInfo.controlMsg.isolationMode
                    ).patternName
                  : ""
              }}
            </div>
          </van-col>
        </van-row>
      </div>
      <div
        class="timeaxisBox"
        v-if="TimeAxisList.length > 0"
      >
        <div class="itemLine"></div>
        <div
          class="timeaiosItem"
          v-for="(item, index) in TimeAxisList"
          :key="index"
        >
          <div class="contains">
            <div class="itemCircle"></div>
            <van-row>
              <van-col
                span="14"
                class="Head1"
              >{{ item.opValue }}</van-col>
              <van-col
                style="color: #5776e6ff"
                span="4"
                @click="isopen[index] = !isopen[index]"
              >{{
                  item.children.length > 0 &&
                  (item.status == 2 || item.status == 3)
                    ? isopen[index]
                      ? "展开"
                      : "收缩"
                    : ""
                }}</van-col>
              <van-col class="HeadStatus1">{{
                TimeAxisStatusList[item.status]
              }}</van-col>
            </van-row>
            <div
              class="itembody"
              v-if="
                (item.children.length == 0 && item.status == 2) ||
                item.status == 3
              "
            >
              <van-row v-if="item.opDate != null">
                <van-col span="8">{{
                  item.opValue.substring(item.opValue.length - 2) + "时间"
                }}</van-col>
                <van-col span="16">{{ item.opDate }}</van-col>
              </van-row>
              <van-row v-if="item.opName != null">
                <van-col span="8">操作人</van-col>
                <van-col span="16">{{
                  (item.opName != null ? item.opName : "") +
                  (item.opRole != null ? "(" + item.opRole + ")" : "")
                }}</van-col>
              </van-row>
              <van-row v-if="item.opTime != null">
                <van-col span="8">响应耗时</van-col>
                <van-col span="16">{{ item.opTime }}</van-col>
              </van-row>
              <van-row v-if="item.controlStep != null">
                <van-col span="8">管控措施</van-col>
                <van-col span="16">{{
                  this.getDictLabel("controlStep", item.controlStep)
                }}</van-col>
              </van-row>
              <van-row v-if="item.isolateMode != null">
                <van-col span="8">隔离模式</van-col>
                <van-col span="16">{{ item.isolateMode }}</van-col>
              </van-row>
            </div>
            <transition name="fade">
              <div
                class="ccontains"
                v-show="!isopen[index]"
                v-if="
                  (item.children.length > 0 && item.status == 2) ||
                  item.status == 3
                "
              >
                <div
                  class="citem"
                  v-for="(citem, cindex) in item.children"
                  :key="cindex"
                >
                  <van-row>
                    <van-col class="Head2">{{ citem.opValue }}</van-col>
                  </van-row>
                  <div>
                    <div
                      class="citembody"
                      v-if="citem.children.length == 0"
                    >
                      <van-row v-if="citem.opDate != null">
                        <van-col span="8">{{
                          citem.opValue.substring(citem.opValue.length - 2) +
                          "时间"
                        }}</van-col>
                        <van-col span="16">{{ citem.opDate }}</van-col>
                      </van-row>
                      <van-row v-if="citem.opName != null">
                        <van-col span="8">操作人</van-col>
                        <van-col span="16">{{
                          (citem.opName != null ? citem.opName : "") +
                          (citem.opRole != null ? "(" + citem.opRole + ")" : "")
                        }}</van-col>
                      </van-row>
                      <van-row v-if="citem.opTime != null">
                        <van-col span="8">响应耗时</van-col>
                        <van-col span="16">{{ citem.opTime }}</van-col>
                      </van-row>
                      <van-row v-if="citem.controlStep != null">
                        <van-col span="8">管控措施</van-col>
                        <van-col span="16">{{
                          this.getDictLabel("controlStep", citem.controlStep)
                        }}</van-col>
                      </van-row>
                      <van-row v-if="citem.isolateMode != null">
                        <van-col span="8">隔离模式</van-col>
                        <van-col span="16">{{ citem.isolateMode }}</van-col>
                      </van-row>
                      <van-row v-if="citem.opContent != null">
                        <van-col span="8">{{
                          citem.opValue.substring(citem.opValue.length - 2) +
                          "原因"
                        }}</van-col>
                        <van-col span="16">{{ citem.opContent }}</van-col>
                      </van-row>
                      <van-row v-if="citem.isolateMode != null">
                        <van-col span="8">隔离模式</van-col>
                        <van-col span="16">{{ citem.isolateMode }}</van-col>
                      </van-row>
                      <van-row v-if="citem.isoAddress != null">
                        <van-col span="8">隔离地点</van-col>
                        <van-col span="16">{{ citem.isoAddress }}</van-col>
                      </van-row>
                      <van-row v-if="citem.isoDay != null">
                        <van-col span="8">{{
                            citem.opValue.substring(citem.opValue.length - 2) +
                            "天数"
                          }}
                        </van-col>
                        <van-col span="16">{{ citem.isoDay }}</van-col>
                      </van-row>
                      <van-row v-if="citem.reliveTime != null">
                        <van-col span="8">{{
                            "预计解除" +
                            citem.opValue.substring(citem.opValue.length - 2) +
                            "时间"
                          }}
                        </van-col>
                        <van-col span="16">{{ citem.reliveTime }}</van-col>
                      </van-row>
                    </div>
                    <div
                      class="scontains"
                      v-if="citem.children.length > 0"
                    >
                      <div
                        class="sitem"
                        v-for="(sitem, sindex) in citem.children"
                        :key="sindex"
                      >
                        <div>
                          <van-row>
                            <van-col class="Head3">{{ sitem.opValue }}</van-col>
                          </van-row>
                          <van-row>
                            <van-col span="8">{{
                              sitem.opValue.substring(
                                sitem.opValue.length - 2
                              ) + "时间"
                            }}</van-col>
                            <van-col span="16">{{ sitem.opDate }}</van-col>
                          </van-row>
                          <van-row v-if="sitem.opName != null">
                            <van-col span="8">操作人</van-col>
                            <van-col span="16">{{
                              (sitem.opName != null ? sitem.opName : "") +
                              (sitem.opRole != null
                                ? "(" + sitem.opRole + ")"
                                : "")
                            }}</van-col>
                          </van-row>
                          <van-row v-if="sitem.opTime != null">
                            <van-col span="8">响应耗时</van-col>
                            <van-col span="16">{{ sitem.opTime }}</van-col>
                          </van-row>
                          <van-row v-if="sitem.controlStep != null">
                            <van-col span="8">管控措施</van-col>
                            <van-col span="16">{{
                              this.getDictLabel(
                                "controlStep",
                                sitem.controlStep
                              )
                            }}</van-col>
                          </van-row>
                          <van-row v-if="sitem.isolateMode != null">
                            <van-col span="8">隔离模式</van-col>
                            <van-col span="16">{{ sitem.isolateMode }}</van-col>
                          </van-row>
                          <van-row v-if="sitem.opContent != null">
                            <van-col span="8">{{
                              sitem.opValue.substring(
                                sitem.opValue.length - 2
                              ) + "原因"
                            }}</van-col>
                            <van-col span="16">{{ sitem.opContent }}</van-col>
                          </van-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div
        v-if="TimeAxisList.length == 0"
        class="emptyData"
      >暂无数据</div>
    </div>
    <div
      title=""
      v-if="showTab == '核酸记录'"
    >
      <div style="padding: 15px">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in NucleicList"
            :timestamp="item.collectDatetime"
            placement="top"
            :key="index"
          >
            <van-cell-group :border="true">
              <van-cell
                :border="false"
                title="检测结果"
                :value="item.signName"
              >
                <template #value>
                  <span :style="
                      'color:' +
                      (item.signName.indexOf('阳性') >= 0
                        ? '#FA5555'
                        : '#51D74E')
                    ">{{ item.signName }}</span>
                </template>
              </van-cell>
              <van-cell
                :border="false"
                title="采集时间"
                :value="item.collectDatetime"
              />
              <van-cell
                :border="false"
                title="检测时间"
                :value="item.testingDatetime"
              />
            </van-cell-group>
          </el-timeline-item>
        </el-timeline>
        <van-row style="text-align:center">
          <van-button
            block
            :disabled="NucleicDisabled"
            @click="getNucleic(2);NucleicDisabled=true;"
          >刷新数据</van-button>
        </van-row>
        <div
          v-if="
            (NucleicList == null || NucleicList.length == 0) &&
            showTab == '核酸记录'
          "
          class="emptyData"
        >
          暂无数据
        </div>
      </div>
    </div>
    <van-image-preview />

    <!-- 开启底部安全区适配 -->
    <van-number-keyboard safe-area-inset-bottom />
  </div>
  <van-popup
    v-model:show="isShowControlType"
    id="ControlTypePopup"
    position="bottom"
    closeable
  >
    <van-form
      @failed="onFailed"
      @submit="setControlType"
      style="margin-top: 30px; margin-bottom: 30px"
    >
      <van-cell
        title-style="text-align:left;width:6.2em"
        title="管控方式"
      >
        <template #right-icon>
          <el-select
            v-model="controlTypeInfo.controlType"
            placeholder="管控方式"
          >
            <el-option
              value="DoorMagnet"
              label="门磁"
            ></el-option>
            <el-option
              value="Monitor"
              label="监控"
            ></el-option>
          </el-select>
        </template>
      </van-cell>
      <van-field
        v-model="controlTypeInfo.deviceImei"
        v-if="controlTypeInfo.controlType == 'DoorMagnet'"
        name="门磁编号"
        label="门磁编号"
        placeholder="门磁编号"
      />
      <van-button
        size="small"
        type="primary"
        style="margin-top: 10px"
        native-type="submit"
      >保存管控方式</van-button>
    </van-form>
  </van-popup>
</template>

<script>
import { ref } from "@vue/reactivity";
// @ is an alias to /src
import Api from "../util/App";
import { useRouter } from "vue-router";
import { ImagePreview, Notify, Toast, Dialog } from "vant";
import { addDays, format, differenceInDays } from "date-fns";
import { BaseUrl } from "../util/request";
export default {
  name: "Home",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const personInfo = ref({
      id: "",
      personName: "",
      riskId: "",
      riskName: "",
      idCard: "",
      isDraft: "",
      isTransport: "",
      soureRegion: "",
      phone: "",
      sex: "",
      age: 0,
      releaseMode: "",
      workUnit: "",
      community: "",
      grid: "",
      buildingId: "",
      houseId: "",
      houseCode: "A15",
      address: "",
      permanentAddress: null,
      status: "2",
      disease: "",
      personType: "",
      touchCase: "",
      lastContactDate: "",
      returnRegion: "",
      touchRegion: 0,
      pushRegion: 0,
      returnDate: "",
      arriveHomeTime: "",
      ankangCode: "",
      tripCode: "",
      gridId: null,
      dataSoucre: "Gridreport",
      remark: "",
      isolatedCity: "",
      isolatedHotel: "",
      nucleinImg: "",
      isolatedProve: "",
      vehicle: "",
      flightNumber: "",
      creator: "",
      createDate: "",
      updater: null,
      updateDate: null,
      isDelete: "0",
      controlStep: null,
      controlStatus: null,
      relieveIsolationDate: null,
      isolationCycle: null,
      relieveHealthDate: null,
      healthCycle: null,
      stayMsg: "",
      dataType: "",
      controlMsg: {
        id: "",
        personId: "",
        controlStep: "",
        controlStatus: "",
        relieveCentralizeDate: "",
        centralizeCycle: "",
        relieveHomeDate: null,
        homeCycle: "1",
        relieveHealthDate: "",
        healthCycle: "1",
        isolationMode: "",
        transportType: null,
        controlType: "",
        status: null,
        deviceImei: "",
        remark: "",
        creator: "",
        createDate: "",
        updater: "",
        updateDate: "",
        isDelete: "0",
        centralizeVouchers: null,
        homeVouchers: null,
        healthVouchers: null,
        statusDate: "",
      },
      centralizeVouchers: null,
      homeVouchers: null,
      healthVouchers: null,
    });

    const dicBeginTime = {
      Other: "returnDate",
      RedAndYellow: "redYellowDate",
      HighRisk: "returnDate",
      BjStudent: "arriveHomeTime",
      OutsideCity: "returnDate",
      OutsideProvince: "returnDate",
      Abroad: "returnDate",
      PositiveExposure: "lastContactDate",
      SecondTouch: "lastContactDate",
      Touch: "lastContactDate",
    };

    const dicPersonTypeLabel = [
       "阳性病例",
      "红码人员",
      "返肥人员",
      "中高风险地区返回",
      "境外人员",
      "次密接",
      "密接",
      "北京/上海大学生",
      "50类人群",
    ];

    const dicPersonType = [
       "Positive",
      "RedCode",
      "ReturnHf",
      "HighRisk",
      "Abroad",
      "SecondTouch",
      "Touch",
      "BjStudent",
      "TwentyEight",
    ];

    const router = useRouter();
    const GetAge = function (identityCard) {
      let len = (identityCard + "").length;
      let strBirthday = "";
      if (len == 18) {
        //处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday =
          identityCard.substr(6, 4) +
          "/" +
          identityCard.substr(10, 2) +
          "/" +
          identityCard.substr(12, 2);
      }
      if (len == 15) {
        let birthdayValue = "";
        birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
        if (parseInt(birthdayValue) < 10) {
          strBirthday =
            "20" +
            identityCard.substr(6, 2) +
            "/" +
            identityCard.substr(8, 2) +
            "/" +
            identityCard.substr(10, 2);
        } else {
          strBirthday =
            "19" +
            identityCard.substr(6, 2) +
            "/" +
            identityCard.substr(8, 2) +
            "/" +
            identityCard.substr(10, 2);
        }
      }
      let birthDate = new Date(strBirthday);
      let nowDateTime = new Date();
      let age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return isNaN(age) ? "" : age;
    };

    const communityList = ref([]);
    const DataInfo = ref({
      returnRegion: "",
      Community: "",
      Live: "",
      Store: "",
      House: "",
      touchRegion: "",
      pushRegion: "",
    });

    const timeFormat = (time) => {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return (
        year +
        "-" +
        padLeft(month, 2) +
        "-" +
        padLeft(day, 2) +
        " " +
        padLeft(hour, 2) +
        ":" +
        padLeft(min, 2) +
        ":00"
      );
    };
    const padLeft = (num, n) => {
      let y = "00000000000000000000000000000" + num; //爱几个0就几个，自己够用就行
      return y.substr(y.length - n);
    };

    const controlEdit = ref(false);
    const ControlPickShow = ref(false);
    const isolationModePickShow = ref(false);
    const showPicker1 = ref(false);
    const DatetimePickerField = ref("");
    const DatetimeDefault = ref(new Date());
    const PickerField = ref("");
    const DicPickerField = ref("");
    const SensorList = ref({
      searchKey: "",
      currentPage: 1,
      data: {
        total: 0,
        list: [],
      },
    });
    const IsolatedProveList = ref([]);
    const SensorDataTypeArray = ["关门事件", "故障", "开门事件"];
    const SensorColorArray = ["#FF881C", "red", "#FA5555"];
    const SensorDeviceStateArray = ["正常", "故障", "报警", "", "离线"];
    const role = ref("");
    const active = ref("");
    const isShowDetail = ref(false);
    const approveList = ref({
      stateArray: ["", "待审核", "已通过", "已驳回"],
      searchKey: "",
      currentPage: 1,
      data: {
        total: 0,
        list: [],
      },
    });

    const approveInfo = ref({
      approveDate: "",
      approveResult: "",
      approve_user: 0,
      content: "",
      changeType: 1,
      reportFile: "",
      dataType: "",
      createDate: "",
      creator: 0,
      isDelete: 0,
      personId: 0,
      remark: "",
      status: "",
      updateDate: "",
      updater: 0,
    });
    const approveActive = ref("");
    const approveInfoFileTemp = ref("javascript:void(0)");
    const filename = ref("");
    const TimeAxisList = ref([]);
    const isShowControlType = ref(false);
    const showTab = ref("");
    const controlTypeInfo = ref({
      controlType: "DoorMagnet",
      deviceImei: "",
      id: "",
    });

    const TimeAxisStatusList = ref(["无需进行", "未进行", "进行中", "已完成"]);
    const TagColors = {
      controlStep: {
        NoSegregation: {
          color: "#61D65E",
          bgColor: "#D6FFD5",
        },
        InHomeSegregation: {
          color: "#FF881C",
          bgColor: "#FFE1C5",
        },
        FocusSegregation: {
          color: "#FA5555",
          bgColor: "#FFE0E0",
        },
        HealthTest: {
          color: "#5ED6C8",
          bgColor: "#E7FFFC",
        },
        CancelReturnHf: {
          color: "#5ED6C8",
          bgColor: "#E7FFFC",
        },
      },
    };
    const isopen = ref([]);
    const addApprove = ref(false);
    const NucleicList = ref([]);
    const NucleicDisabled = ref(false);
    return {
      active,
      NucleicDisabled,
      addApprove,
      NucleicList,
      isopen,
      showTab,
      TagColors,
      TimeAxisStatusList,
      approveInfo,
      isShowControlType,
      TimeAxisList,
      controlTypeInfo,
      filename,
      approveInfoFileTemp,
      approveActive,
      approveList,
      isShowDetail,
      router,
      addDays,
      format,
      personInfo,
      role,
      dicBeginTime,
      IsolatedProveList,
      isolationModePickShow,
      dicPersonType,
      dicPersonTypeLabel,
      SensorDataTypeArray,
      SensorColorArray,
      SensorDeviceStateArray,
      ImagePreview,
      timeFormat,
      GetAge,
      communityList,
      DataInfo,
      SensorList,
      controlEdit,
      ControlPickShow,
      DatetimePickerField,
      showPicker1,
      PickerField,
      DicPickerField,
      DatetimeDefault,
    };
  },
  mounted() {
    this.$emit("changeTitle", "居家隔离人员详情");
    this.role = window.localStorage.getItem("role");
    let id = this.router.currentRoute.value.params.id;
    if (id != undefined && id != null) {
      window.localStorage.setItem("personId", id);
    } else {
      id = window.localStorage.getItem("personId");
    }
    this.addApprove = false;
    this.isShowControlType = false;
    this.showTab = this.$route.params.type;
    if (this.showTab != undefined && this.showTab != null) {
      window.localStorage.setItem("showTab", id);
    } else {
      this.showTab = window.localStorage.getItem("showTab");
    }

    this.initdata(id);
    this.initTimeAxis(id);
    this.getReportTemp();
  },
  activated() {
    this.role = window.localStorage.getItem("role");
    let id = this.router.currentRoute.value.params.id;
    if (id != undefined && id != null) {
      window.localStorage.setItem("personId", id);
    } else {
      id = window.localStorage.getItem("personId");
    }
    this.NucleicList = [];
    this.addApprove = false;
    this.isShowControlType = false;
    this.showTab = this.$route.params.type;
    if (this.showTab != undefined && this.showTab != null) {
      window.localStorage.setItem("showTab", this.showTab);
    } else {
      this.showTab = window.localStorage.getItem("showTab");
    }
    this.active = 0;
    this.isShowDetail = false;
    this.initdata(id);
    this.initTimeAxis(id);
    this.NucleicDisabled = false;
  },
  methods: {
    initdata(id) {
      this.DataInfo = {
        returnRegion: "",
        Community: "",
        Live: "",
        Store: "",
        House: "",
        touchRegion: "",
        pushRegion: "",
      };
      Api.getPersoninfo(id).then((res) => {
        this.personInfo = res.data;

        //if (this.personInfo.personType == "TwentyEight") {
          this.getGridList();
        //}
        if (this.personInfo.dailyMsg == null) {
          this.personInfo.dailyMsg = {
            addStatus: "",
            controlId: 0,
            controlStatus: "",
            controlStep: "",
            createDate: "",
            creator: 0,
            firTemp: "36.5",
            idCard: "",
            isDelete: 0,
            personId: 0,
            personName: "",
            phone: "",
            releaseCertificate: "",
            releaseCertificateList: [],
            voucherImgList: [],
            remark: "",
            secTemp: "36.5",
            staffId: 0,
            updateDate: "",
            updater: 0,
            voucherDate: "",
            voucherImg: "",
            voucherType: "",
          };
        }
        this.getNucleic(1);

        if (
          this.personInfo.dailyMsg.releaseCertificate != null &&
          this.personInfo.dailyMsg.releaseCertificate != ""
        )
          this.personInfo.dailyMsg["releaseCertificateList"] = [
            { url: this.personInfo.dailyMsg.releaseCertificate },
          ];
        if (
          this.personInfo.dailyMsg.voucherImg != null &&
          this.personInfo.dailyMsg.voucherImg != ""
        )
          this.personInfo.dailyMsg["voucherImgList"] = [
            { url: this.personInfo.dailyMsg.voucherImg },
          ];
        this.getRiskList();
        this.getRegion();
        this.getCommunityAllList();
        this.getSensorList();
        this.getIsolationModeList();
        this.getApprovePageList();
      });
    },
    getRiskList() {
      Api.getRiskList().then((res) => {
        this.personInfo.riskName =
          this.personInfo.riskId &&
          res.data.find((item) => item.id == this.personInfo.riskId).riskName;
      });
    },
    getNucleic(isForce) {
      Api.getNucleic({
        idCard: this.personInfo.idCard,
        name: this.personInfo.personName,
        isForce: isForce,
      }).then((res) => {
        this.NucleicList = res.data;
      });
    },
    initTimeAxis(id) {
      this.isopen = [];
      Api.getTimeaxis(id).then((res) => {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          this.isopen.push(element.children.length > 0);
        }
        this.TimeAxisList = res.data;
      });
    },
    getReportTemp() {
      this.approveInfoFileTemp =
        BaseUrl +
        "/app/datachange/downloadTemplate?token=" +
        window.localStorage.getItem("token");
    },
    getSensorList() {
      if (
        this.personInfo.controlMsg != null &&
        this.personInfo.controlMsg.deviceImei != ""
      )
        Api.getSensorPageList({
          limit: 10,
          page: this.SensorList.currentPage,
          personId: this.personInfo.id,
          imei: this.personInfo.controlMsg.deviceImei,
        }).then((res) => {
          this.SensorList.data = res.data;
        });
    },
    getIsolationModeList() {
      Api.getControlModeList().then((res) => {
        try {
          this.IsolatedProveList = res.data;
        } catch {
          //
        }
      });
    },
    getGridList() {
       if (this.personInfo.personType == "TwentyEight") {
      this.personInfo.personLabel = this.getDictLabel(
        "PeopleType",
        "TwentyEight"
      );
      let crowdTypeList = this.$store.state.Dict.find(
        (p) => p.dictType == "TwentyEightType"
      ).dataList;
      crowdTypeList.forEach((item) => {
        if (item.dictValue == this.personInfo.crowdType) {
          this.personInfo.crowdTypeLabel = item.dictLabel;
        }
      });

      this.communityList.forEach((item) => {
        if (item.id == this.personInfo.community) {
          this.personInfo.communityLabel = item.name;
        }
      });
      }
      this.gridList = [];
      Api.getGridList().then((res) => {
        res.data.forEach((item, index) => {
          if (item.id == this.personInfo.gridId) {
            this.personInfo.gridLabel = item.name;
            console.log(this.personInfo.gridLabel)
          }
        });
      });
    },
    getRegion() {
      Api.getRegionList().then((res) => {
        try {
          let list = res.data;
          if (this.personInfo.returnRegion != "") {
            try {
              let county = list.find(
                (p) => p.id == this.personInfo.returnRegion
              );
              this.DataInfo.returnRegion = county.name;
              let city = list.find((p) => p.id == county.pid);
              this.DataInfo.returnRegion =
                city.name + "/" + this.DataInfo.returnRegion;
              let province = list.find((p) => p.id == city.pid);
              this.DataInfo.returnRegion =
                province.name + "/" + this.DataInfo.returnRegion;
            } catch {
              //
            }
          }

          if (this.personInfo.pushRegion != "") {
            try {
              let county = list.find((p) => p.id == this.personInfo.pushRegion);
              this.DataInfo.pushRegion = county.name;
              let city = list.find((p) => p.id == county.pid);
              this.DataInfo.pushRegion =
                city.name + "/" + this.DataInfo.pushRegion;
              let province = list.find((p) => p.id == city.pid);
              this.DataInfo.pushRegion =
                province.name + "/" + this.DataInfo.pushRegion;
            } catch {
              //
            }
          }

          if (this.personInfo.touchRegion != "") {
            try {
              let county = list.find(
                (p) => p.id == this.personInfo.touchRegion
              );
              this.DataInfo.touchRegion = county.name;
              let city = list.find((p) => p.id == county.pid);
              this.DataInfo.touchRegion =
                city.name + "/" + this.DataInfo.touchRegion;
              let province = list.find((p) => p.id == city.pid);
              this.DataInfo.touchRegion =
                province.name + "/" + this.DataInfo.touchRegion;
            } catch {
              //
            }
          }
        } catch {
          //
        }
      });
    },
    getCommunityAllList() {
      this.fieldValue = "";
      Api.getOrgCommunityList().then((res) => {
        try {
          this.communityList = res.data;
          this.DataInfo.Community = this.communityList.find(
            (p) => p.id == this.personInfo.community
          ).name;
          if (this.personInfo.community != "") {
            Api.getOrgLiveList({ communityId: this.personInfo.community }).then(
              (res) => {
                res.data.forEach((element) => {
                  element["children"] = [];
                });
                try {
                  this.communityList.find(
                    (p) => p.id == this.personInfo.community
                  )["children"] = res.data;
                  this.DataInfo.Community = this.communityList.find(
                    (p) => p.id == this.personInfo.community
                  ).name;
                  this.fieldValue = this.DataInfo.Community;
                } catch {
                  //
                }
                if (this.personInfo.liveId != "") {
                  Api.getOrgStoreList({ liveId: this.personInfo.liveId }).then(
                    (res) => {
                      res.data.forEach((element) => {
                        element["children"] = [];
                      });
                      try {
                        this.communityList
                          .find((p) => p.id == this.personInfo.community)
                          .children.find(
                            (p) => p.id == this.personInfo.liveId
                          ).children = res.data;
                        this.DataInfo.Live = this.communityList
                          .find((p) => p.id == this.personInfo.community)
                          .children.find(
                            (p) => p.id == this.personInfo.liveId
                          ).name;
                        this.fieldValue =
                          this.DataInfo.Community + "/" + this.DataInfo.Live;
                      } catch {
                        //
                      }

                      if (this.personInfo.buildingId != "") {
                        var type="";
                    if(this.personInfo.community&&this.personInfo.liveId){
                      let storeList=this.communityList.find(p => p.id == this.personInfo.community).children.find(p => p.id == this.personInfo.liveId).children;
                      type=storeList.find(e=>e.id==this.personInfo.buildingId).storeTypeCode;
                    }
                        Api.getOrgPublicPage({
                          storeId: this.personInfo.buildingId,
                          limit: 1000,
                          type:type
                        }).then((res) => {
                          try {
                            this.communityList
                              .find((p) => p.id == this.personInfo.community)
                              .children.find(
                                (p) => p.id == this.personInfo.liveId
                              )
                              .children.find(
                                (p) => p.id == this.personInfo.buildingId
                              ).children = res.data;
                            this.DataInfo.Store = this.communityList
                              .find((p) => p.id == this.personInfo.community)
                              .children.find(
                                (p) => p.id == this.personInfo.liveId
                              )
                              .children.find(
                                (p) => p.id == this.personInfo.buildingId
                              ).name;
                            this.fieldValue =
                              this.DataInfo.Community +
                              "/" +
                              this.DataInfo.Live +
                              "/" +
                              this.DataInfo.Store;
                          } catch {
                            //
                          }
                          if (this.personInfo.houseId != "") {
                            try {
                              this.DataInfo.House = res.data.find(
                                (p) => p.id == this.personInfo.houseId
                              ).name;
                              this.fieldValue =
                                this.DataInfo.Community +
                                "/" +
                                this.DataInfo.Live +
                                "/" +
                                this.DataInfo.Store +
                                "/" +
                                this.DataInfo.House;
                            } catch {
                              //
                            }
                          }
                        });
                      }
                    }
                  );
                }
              }
            );
          }
        } catch {
          //
        }
      });
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(
          (p) => p.dictType == type
        ).dataList.find((p) => p.dictValue == value).dictLabel;
      } catch {
        return "";
      }
    },
    onConfirmDriver(data) {
      this.ControlPickShow = false;

      this.personInfo.controlMsg[this.PickerField] = data.dictValue;
    },
    onConfirmIsolationMode(data) {
      let baseTime = new Date(
        this.personInfo[this.dicBeginTime[this.personInfo.personType]]
      );
      let diffday = 0;
      try {
        diffday = differenceInDays(new Date(), baseTime);
      } catch {
        //
      }
      let a = addDays(baseTime, 1);
      let b = addDays(baseTime, -1);
      this.isolationModePickShow = false;
      this.personInfo.controlMsg.isolationMode = data.id;
      this.personInfo.controlMsg.relieveCentralizeDate = format(
        addDays(baseTime, data.focusDay - diffday),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.personInfo.controlMsg.relieveHomeDate = format(
        addDays(baseTime, data.focusDay + data.homeDay - diffday),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.personInfo.controlMsg.relieveHealthDate = format(
        addDays(
          baseTime,
          data.focusDay + data.homeDay + data.healthDay - diffday
        ),
        "yyyy-MM-dd HH:mm:ss"
      );
    },

    submitContorl() {
      let edit = {
        centralizeCycle: "",
        controlId: 0,
        controlRemark: "",
        controlStatus: "",
        controlStep: "",
        controlType: "",

        deviceImei: "",

        healthCycle: "",
        homeCycle: "",

        isolationMode: "",

        relieveCentralizeDate: "",
        relieveHealthDate: "",
        relieveHomeDate: "",
      };

      let ComfirmData = {
        centralizeCycle: "",
        controlStatus: "",
        controlStep: "",
        controlType: "",
        createDate: "",

        deviceImei: "",
        healthCycle: "",
        homeCycle: "",
        id: "",

        isolationMode: "",
        personId: "",
        relieveCentralizeDate: "",
        relieveHealthDate: "",
        relieveHomeDate: "",
        redYellowDate: "",
        remark: "",
        status: "",
        statusDate: "",
        transportType: "",
        updateDate: "",
      };

      if (this.personInfo.controlMsg.status == 2) {
        ComfirmData = { ...this.personInfo.controlMsg };

        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: "数据传输中",
        });

        Api.swjApprove(ComfirmData).then((res) => {
          toast.clear();
          if (res.code == 0) {
            this.controlEdit = false;
            Notify({ type: "success", message: "审核成功!" });
            this.initdata(this.personInfo.id);
          } else {
            Notify({ type: "danger", message: res.msg });
          }
        });
      } else {
        edit = { ...this.personInfo.controlMsg };
        edit.controlId = this.personInfo.controlMsg.id;
        edit.id = this.personInfo.id;
        edit.controlRemark = this.personInfo.controlMsg.remark;
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: "数据传输中",
        });
        Api.swjPersonUpdate(edit).then((res) => {
          toast.clear();
          if (res.code == 0) {
            this.controlEdit = false;
            Notify({ type: "success", message: "保存成功!" });
            this.initdata(this.personInfo.id);
          } else {
            Notify({ type: "danger", message: res.msg });
          }
        });
      }
    },
    submit() {
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "数据传输中",
      });

      this.personInfo.dailyMsg.personId = this.personInfo.id;
      this.personInfo.dailyMsg.controlId = this.personInfo.controlMsg.id;
      //this.voucherInfo.personId=''
      Api.getSubmit(this.personInfo.dailyMsg).then((res) => {
        toast.clear();
        if (res.code == 0) {
          Notify({ type: "success", message: "上报成功!" });
          this.initdata(this.personInfo.id);
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
    submitApprove() {
      Dialog.confirm({
        title: "系统消息",
        message: "确认提交申请？",
      }).then(() => {
        this.approveInfo.personId = this.personInfo.id;
        Api.submitApprove(this.approveInfo).then((res) => {
          if (res.code == 0) {
            this.approveInfo.content = "";
            Notify({ type: "success", message: "保存成功!" });
            this.approveActive = "approveList";
            this.getApprovePageList();
          } else {
            Notify({ type: "danger", message: res.msg });
          }
        });
      });
    },
    getApprovePageList() {
      Api.getApprovePageList({
        limit: 10,
        page: this.approveList.currentPage,
        personId: this.personInfo.id,
      }).then((res) => {
        this.approveList.data = res.data;
      });
    },

    afterRead(file, field) {
      // 这时候我们创建一个formData对象实例
      const formData = new FormData();
      // 通过append方法添加需要的file
      // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
      formData.append("file", file.file);

      //上传文件、成功后， 把Url 赋值给 field
      Api.uploadFile(formData).then((res) => {
        if (res.code == 0 && res.data != null && res.data != "") {
          this.personInfo.dailyMsg[field] = res.data;
        }
      });
    },

    afterReadReportFile(file, field) {
      // 这时候我们创建一个formData对象实例
      const formData = new FormData();
      // 通过append方法添加需要的file
      // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
      formData.append("file", file.file);
      // eslint-disable-next-line no-debugger
      debugger;
      //上传文件、成功后， 把Url 赋值给 field
      Api.uploadFile(formData).then((res) => {
        if (res.code == 0 && res.data != null && res.data != "") {
          this.approveInfo.reportFile = res.data;
          this.filename = file.file.name;
        }
      });
    },
    setControlType() {
      this.controlTypeInfo.id = this.personInfo.controlMsg.id;
      Api.ControlTypeAdd(this.controlTypeInfo).then((res) => {
        if (res.code == 0) {
          Notify({ type: "success", message: "保存成功!" });
          this.controlTypeInfo = {
            controlType: "DoorMagnet",
            deviceImei: "",
            id: "",
          };
          this.isShowControlType = false;
          this.initdata(this.personInfo.id);
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
    },
  },
};
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
  background: #fff;
}

.van-cell >>> .van-cell_title {
  color: #666666 !important;
}

.van-cell >>> .van-cell_value {
  color: #333333 !important;
}

.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}

.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}

.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}

.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}

.box::-webkit-scrollbar-corner {
  background: transparent;
}

.dataList {
  background: #f8f8f8ff;
}

.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
  padding-bottom: 10px;
  text-align: center;
}

.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .leftTag {
  margin-right: 5px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}

.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}

.dataItem .r2 .itemTitle {
  color: #999999;
}

.dataItem .r2 .itemTitleL {
  text-align: left;
  color: #999999;
}

.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r23 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  text-align: left;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.emptyData {
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding-top: 30px;
  color: #666666;
}

.timeaxisBox {
  margin-left: 5px;
  position: relative;
}
.timeaiosItem {
}
.itemLine {
  position: absolute;
  left: 4px;
  height: 100%;
  border-left: 2px solid #e4e7ed;
}
.itemCircle {
  background-color: #409eff;
  border-color: #409eff;
  left: -1px;
  width: 12px;
  height: 12px;
  position: absolute;

  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Head1 {
  margin-left: 12px;
  font-size: 14px !important;
  font-weight: 600;
}
.HeadStatus1 {
  margin-left: 12px;

  font-weight: 600;
}

.itembody {
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.citembody {
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #f7f9ff;
}

.sitembody {
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.Head2 {
  /* margin-left: 12px; */
  font-weight: 400;
  font-size: 14px !important;
  color: #333333;
}

.contains {
  margin-left: 15px;
}
.contains div {
  font-size: 12px;
  color: #666666;
}

.ccontains {
  margin-left: 25px;
}
.citem {
  margin: 5px 0 5px 0;

  padding-left: 5px;
}

.sitem {
  margin: 5px 0 5px 0;
  background: #f7f9ff;
  padding-left: 5px;
}

.scontains {
}
</style>
