<template>
  <van-form @failed="onFailed" @submit="submit">
    <div class="about">
      <van-cell-group title="人员信息">
        <van-field
          v-model="personInfo.idCard"
          name="证件号码"
          label="证件号码"
          placeholder="证件号码"
          :rules="[
            /*{
            required: true,
            message: '证件号码不能为空',
            trigger: 'onBlur'
          }, {

            validator: idCardValid,
            message: IdCardMessage,
            trigger: 'onBlur'
          }*/
          ]"
          @blur="getPersonBaseInfo"
        />

        <van-field
          v-model="personInfo.personName"
          name="姓名"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />

        <!-- <van-cell title="性别" :value="personInfo.sex" /> -->

        <van-field
          v-model="personInfo.phone"
          type="tel"
          name="手机号码"
          label="手机号码"
          placeholder="手机号码"
          :rules="[
            {
              required: true,
              message: '手机号码不能为空',
              trigger: 'onBlur',
            },
            {
              validator: (value) => {
                return /^(0|86|17951)?(1)[0-9]{10}$/.test(value);
              },
              message: '请输入正确格式的手机号码',
              trigger: 'onBlur',
            },
          ]"
        />

        <!-- <van-field
          v-model="personInfo.workUnit"
          name="工作单位"
          label="工作单位"
          placeholder="工作单位"
        />
        <van-field
          v-model="personInfo.occupation"
          name="职业"
          label="职业"
          placeholder="职业"
        />-->

        <van-field
          v-model="personInfo.permanentAddress"
          name="户籍地址"
          label="户籍地址"
          placeholder="户籍地址"
        />

        <van-field
          v-model="personInfo.address"
          name="现居住地"
          label="现居住地"
          placeholder="现居住地"
          :rules="[{ required: true, message: '现居住地' }]"
        />

        <van-field
          v-model="fieldValue"
          is-link
          readonly
          label="所在地区"
          placeholder="请选择所在地区"
          :rules="[{ required: true, message: '请选择所在地区' }]"
          @click="show = true"
        />
        <van-popup v-model:show="show" round position="bottom">
          <van-cascader
            v-model="personInfo.AreaId"
            title="请选择所在地区"
            :field-names="fieldNames"
            :options="communityList"
            @close="show = false"
            @change="onChange"
            @finish="onFinish"
          />
        </van-popup>

        <van-field
          v-model="personInfo.disease"
          name="基础疾病情况"
          label="基础疾病情况"
          placeholder="请输入"
        />
        <van-field
          v-model="
            dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]
          "
          is-link
          readonly
          name="picker"
          label="人员类型"
          placeholder="点击选择人员类型"
          :rules="[{ required: true, message: '人员类型' }]"
          @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker
            value-key="value"
            :columns="dicPersonTypeLabel"
            :default-index="dicPersonType.indexOf(personInfo.personType)"
            @confirm="
              (value) => {
                personInfo.personType =
                  dicPersonType[dicPersonTypeLabel.indexOf(value)];
                showPicker = false;
              }
            "
            @cancel="showPicker = false"
          />
        </van-popup>
        <template
          v-if="
            personInfo.personType == 'Touch' ||
            personInfo.personType == 'SecondTouch'
          "
        >
          <van-field
            v-model="personInfo.caseRegion"
            name="病例所在市县区"
            label="病例所在市县区"
            placeholder="病例所在市县区"
          />
          <van-field
            v-model="personInfo.firstTime"
            name="第一次采样时间"
            label="第一次采样时间"
            placeholder="第一次采样时间"
            @click="
              showDatetimePicker = true;
              DatetimeDefault =
                personInfo.firstTime == null ||
                personInfo.firstTime == ''
                  ? new Date()
                  : new Date(personInfo.firstTime);
              DatetimePickerField = 'firstTime';
            "
          />
          <van-field
            v-model="personInfo.secondTime"
            name="第二次采样时间"
            label="第二次采样时间"
            placeholder="第二次采样时间"
            @click="
              showDatetimePicker = true;
              DatetimeDefault =
                personInfo.secondTime == null ||
                personInfo.secondTime == ''
                  ? new Date()
                  : new Date(personInfo.secondTime);
              DatetimePickerField = 'secondTime';
            "
          />
          <van-field
            v-model="personInfo.thirdTime"
            name="第三次采样时间"
            label="第三次采样时间"
            placeholder="第三次采样时间"
            @click="
              showDatetimePicker = true;
              DatetimeDefault =
                personInfo.thirdTime == null ||
                personInfo.thirdTime == ''
                  ? new Date()
                  : new Date(personInfo.thirdTime);
              DatetimePickerField = 'thirdTime';
            "
          />
        </template>
      </van-cell-group>
      <!-- 密接次密接 需填写病例 、密接姓名、m末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 阳性物品暴露人员 需填写 末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 高风险地区来（返）肥人员 需填写  返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 红黄码人员 需填写 安康码、行程码-->
      <!-- 其他情况 需填写  其他说明、安康码、行程码-->

      <van-cell-group
        :title="
          dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]
        "
      >
        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 4 &&
            personInfo.personType != 'BjStudent'
          "
          v-model="personInfo.touchCase"
          name="密接病例"
          label="密接病例"
          :rules="[{ required: true, message: '密接病例' }]"
          placeholder="密接病例"
        />
        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) == 5 &&
            personInfo.personType != 'BjStudent'
          "
          v-model="DataInfo.pushRegion"
          is-link
          readonly
          name="area"
          label="推送次密接地区"
          placeholder="推送次密接地区"
          @click="
            showArea = true;
            RegionField = 'pushRegion';
          "
        />
        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) == 5 &&
            personInfo.personType != 'BjStudent'
          "
          v-model="DataInfo.touchRegion"
          is-link
          readonly
          name="area"
          label="密接所在地区"
          placeholder="密接所在地区"
          @click="
            showArea = true;
            RegionField = 'touchRegion';
          "
        />

        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 5 &&
            personInfo.personType != 'BjStudent'
          "
          v-model="personInfo.releaseMode"
          name="接触方式"
          label="接触方式"
          :rules="[{ required: true, message: '接触方式' }]"
          placeholder="接触方式"
        />

        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 5&&
            personInfo.personType != 'BjStudent'
          "
          v-model="personInfo.lastContactDate"
          name="末次接触时日期"
          label="末次接触时日期"
          placeholder="末次接触时日期"
          :rules="[{ required: true, message: '末次接触时日期' }]"
          @click="
            DatetimeDefault = new Date();
            showDatetimePicker = true;
            DatetimePickerField = 'lastContactDate';
          "
        />

        <van-popup v-model:show="showDatetimePicker" position="bottom">
          <van-datetime-picker
            v-model="DatetimeDefault"
            type="datetime"
            @confirm="
              (value) => {
                personInfo[DatetimePickerField] = timeFormat(value);
                showDatetimePicker = false;
              }
            "
            @cancel="showDatetimePicker = false"
          />
        </van-popup>
        <van-cell center title="是否转运">
          <template #right-icon>
            <el-select
              v-model="personInfo.isTransport"
              placeholder="请选择是否转运"
            >
              <el-option :value="1" label="是" />
              <el-option :value="2" label="否" />
              <el-option :value="3" label="道口转运" />
            </el-select>
          </template>
        </van-cell>
         <van-field
          v-if="personInfo.isTransport==3"
          v-model="personInfo.crossDetail"
          rows="2"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="请输入详细情形"
          show-word-limit
          name="详细情形"
          label="详细情形"
          :rules="[{ required: true, message: '请输入详细情形'}]"
        />
        <van-cell
          v-if="
            dicPersonType.indexOf(personInfo.personType) == 2 ||
            personInfo.personType == 'BjStudent'
          "
          center
          title="是否抵肥"
        >
          <template #right-icon>
            <el-select
              v-if="
                dicPersonType.indexOf(personInfo.personType) == 2 ||
                personInfo.personType == 'BjStudent'
              "
              v-model="personInfo.isDraft"
              placeholder="请选择是否抵肥"
            >
              <el-option value="是" label="是" />
              <el-option value="否" label="否" />
            </el-select>
          </template>
        </van-cell>

        <van-field
          v-if="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
          "
          v-model="personInfo.returnDate"
          is-link
          name="返回合肥时间"
          label="返回合肥时间"
          placeholder="返回合肥时间"
          :rules="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
              ? [{ required: false }]
              : [{ required: true, message: '返回合肥时间' }]
          "
          @click="
            showDatetimePicker = true;
            DatetimeDefault = new Date();
            DatetimePickerField = 'returnDate';
          "
        />
        <van-field
          v-if="personInfo.personType == 'BjStudent'"
          v-model="personInfo.arriveHomeTime"
          is-link
          name="到家时间"
          label="到家时间"
          placeholder="到家时间"
          :rules="
            personInfo.isDraft == '是'
              ? [{ required: true, message: '到家时间' }]
              : [{ required: false }]
          "
          @click="
            showDatetimePicker = true;
            DatetimeDefault = new Date();
            DatetimePickerField = 'arriveHomeTime';
          "
        />
        <van-field
          v-if="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
          "
          v-model="personInfo.leaveTime"
          is-link
          name="离肥时间"
          label="离肥时间"
          placeholder="请输入离肥时间"
          @click="
            showDatetimePicker = true;
            DatetimeDefault = new Date();
            DatetimePickerField = 'leaveTime';
          "
        />

        <!--
        <van-field
          v-model="DataInfo.returnRegion"
          is-link
          readonly
          v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
          name="area"
          label="返回前所在地区"
          placeholder="返回前所在地区"
         @click="showArea = true;RegionField='returnRegion'"
        /> -->
        <van-field
          v-if="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
          "
          v-model="personInfo.soureRegion"
          name="area"
          label="来源区域"
          placeholder="来源区域"
          :rules="[{ required: true, message: '来源区域' }]"
        />
        <van-popup v-model:show="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="(data) => onConfirm(data, RegionField)"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-if="
            personInfo.personType == 'ReturnHf' ||
            personInfo.personType == 'HighRisk'
          "
          v-model="personInfo.lastContactDate"
          name="末次接触时间"
          label="末次接触时间"
          placeholder="末次接触时间"
          :rules="[{ required: true, message: '末次接触时间' }]"
          @click="
            DatetimeDefault = new Date();
            showDatetimePicker = true;
            DatetimePickerField = 'lastContactDate';
          "
        />
        <van-field
          v-if="
            personInfo.personType == 'ReturnHf' ||
            personInfo.personType == 'HighRisk'
          "
          v-model="personInfo.riskName"
          is-link
          readonly
          name="riskName"
          label="风险等级"
          placeholder="请选择风险等级"
          :rules="[{ required: true, message: '请选择风险等级' }]"
          @click="showRisk = true"
        />
        <van-popup v-model:show="showRisk" position="bottom">
          <van-picker
            :columns="riskList"
            @confirm="
              (value) => {
                personInfo['riskId'] = value.value;
                personInfo.riskName = value.text;
                showRisk = false;
              }
            "
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          v-if="personInfo.personType == 'HighRisk'"
          v-model="personInfo.dataType"
          name="数据类型"
          label="数据类型"
          placeholder="数据类型"
        />
        <van-field
          v-if="personInfo.personType == 'HighRisk'"
          v-model="personInfo.stayMsg"
          name="住宿信息"
          label="住宿信息"
          placeholder="住宿信息"
        />

        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) != 3
          "
          v-model="personInfo.vehicle"
          name="交通工具"
          label="交通工具"
          placeholder="交通工具"
        />

        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) != 3
          "
          v-model="personInfo.vehicle"
          name="交通工具"
          label="交通工具"
          placeholder="交通工具"
        />
        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) != 3
          "
          v-model="personInfo.flightNumber"
          name="航班车次号"
          label="航班车次号"
          placeholder="航班车次号"
        />

        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) == 0||dicPersonType.indexOf(personInfo.personType) == 1"
          v-model="personInfo.redYellowDate"
          name="转码时间"
          label="转码时间"
          placeholder="请输入转码时间"
          :rules="[{ required: true, message: '转码时间' }]"
          @click="
            showDatetimePicker = true;
            DatetimeDefault = new Date();
            DatetimePickerField = 'redYellowDate';
          "
        />  
        <template v-if="personInfo.personType!='Touch'&&personInfo.personType!='SecondTouch'">
        <van-cell title="安康码">
          <template #value>
            <van-uploader
              v-model="DataInfo.ankangCodeList"
              width="100"
              height="100"
              :after-read="(file) => afterRead(file, 'ankangCode')"
              max-count="1"
            />
          </template>
        </van-cell>
        <van-cell title="行程码">
          <template #value>
            <van-uploader
              v-model="DataInfo.tripCodeList"
              width="100"
              height="100"
              :after-read="(file) => afterRead(file, 'tripCode')"
              max-count="1"
            />
          </template>
        </van-cell>
        </template>
        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) == 4"
          v-model="personInfo.isolatedCity"
          name="隔离城市"
          label="隔离城市"
          placeholder="隔离城市"
        />

        <van-field
          v-model="personInfo.isolatedHotel"
          name="隔离酒店"
          label="隔离酒店"
          placeholder="隔离酒店"
        />
        <van-cell
          v-if="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
          "
          :title="
            dicPersonType.indexOf(personInfo.personType) <= 3
              ? '返回后核酸'
              : '核酸检测照片'
          "
        >
          <template #value>
            <van-uploader
              v-model="DataInfo.nucleinImgList"
              width="100"
              height="100"
              :after-read="(file) => afterRead(file, 'nucleinImg')"
              max-count="1"
              :rules="[
                {
                  required: dicPersonType.indexOf(personInfo.personType) == 4,
                  message:
                    dicPersonType.indexOf(personInfo.personType) <= 3
                      ? '返回后核酸'
                      : '核酸检测照片',
                },
              ]"
            />
          </template>
        </van-cell>
        <van-cell
          v-if="dicPersonType.indexOf(personInfo.personType) == 4"
          title="解除隔离证明"
        >
          <template #value>
            <van-uploader
              v-model="DataInfo.isolatedProveList"
              width="100"
              height="100"
              :after-read="(file) => afterRead(file, 'isolatedProve')"
              max-count="1"
            />
          </template>
        </van-cell>

        <van-field
          v-model="personInfo.remark"
          rows="2"
          autosize
          label="其他说明"
          type="textarea"
          maxlength="50"
          placeholder="请输入其他说明"
          show-word-limit
        />
      </van-cell-group>
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        :disabled="disBtn"
        native-type="submit"
      >
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { ref } from "@vue/reactivity";

import { useRouter } from "vue-router";
import { ImagePreview, Notify } from "vant";
import Api from "../util/App";
export default {
  name: "Home",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const personInfo = ref({
      crossDetail:'',
      caseRegion: "",
      firstTime: null,
      secondTime: null,
      thirdTime: null,
      address: "",
      age: 0,
      ankangCode: "",
      buildingId: "",
      community: "",
      dataSoucre: "",
      disease: "",
      flightNumber: "",
      redYellowDate:null,
      grid: "",
      gridId: 0,
      houseCode: "",
      houseId: "",
      idCard: "",
      isolatedCity: "",
      isolatedHotel: "",
      isolatedProve: "",
      lastContactDate:null,
      nucleinImg: "",
      permanentAddress: "",
      personName: "",
      personType: "",
      phone: "",
      releaseMode: "",
      remark: "",
      returnDate: "",
      arriveHomeTime:null,
      returnRegion: 0,
      touchRegion: 0,
      pushRegion: 0,
      sex: "",
      touchCase: "",
      tripCode: "",
      vehicle: "",
      workUnit: "",
      isDraft: "否",
      riskId: "",
      isTransport: "",
      soureRegion: "",
      occupation: "",
      tripCodeList: [],
      ankangCodeList: [],
      stayMsg: "",
      dataType: "",
    });

    const dicPersonTypeLabel = [
      "阳性病例",
      "红码人员",
      "返肥人员",
      "中高风险地区返回",
      "境外人员",
      "次密接",
      "密接",
      "北京/上海大学生",
     
    ];

    const dicPersonType = [
      "Positive",
      "RedCode",
      "ReturnHf",
      "HighRisk",
      "Abroad",
      "SecondTouch",
      "Touch",
      "BjStudent",
    ];

    const showPicker = ref(false);
    const showDatetimePicker = ref(false);
    const DatetimePickerField = ref(false);
    const router = useRouter();
    const showArea = ref(false);
    const showRisk = ref(false);
    const riskList = ref([]);
    const areaList = ref({
      province_list: {},
      city_list: {},
      county_list: {},
    });
    const show = ref(false);
    const fieldValue = ref("");
    const cascaderValue = ref("");
    const communityList = ref([
      {
        id: "3401044000010000",
        pid: "1067246875800000066",
        children: [],
        name: "万泽社区",
        lon: null,
        lat: null,
        sort: 1,
        createDate: null,
        parentName: null,
        remark: null,
        isDelete: 0,
        userLists: null,
      },
    ]);
    const fieldNames = {
      text: "name",
      value: "id",
    };
    const DataInfo = ref({
      returnRegion: "",
      Community: "",
      Live: "",
      Store: "",
      House: "",
      touchRegion: "",
      pushRegion: "",
    });
    const RegionField = ref("");
    const DatetimeDefault = ref(new Date());
    const disBtn = ref(false);
    const IdCardMessage = ref("");
    return {
      router,
      riskList,
      disBtn,
      IdCardMessage,
      showRisk,
      DatetimeDefault,
      personInfo,
      dicPersonType,
      dicPersonTypeLabel,
      DataInfo,
      RegionField,
      ImagePreview,
      showPicker,
      showDatetimePicker,
      DatetimePickerField,
      show,
      fieldValue,
      cascaderValue,
      communityList,
      fieldNames,
      showArea,
      areaList,
    };
  },
  watch:{
    "personInfo.isTransport"(newval,oldval){
      if(newval!=3){
        this.dataForm.crossDetail="";
      }
    }
  },
  mounted() {
    // let id = this.router.currentRoute.value.query.id;
    // this.initdata(id)
    this.getCommunityList();
    this.getRegionList();
    this.getRiskList();
    // if(this.this.personInfo.tripCode!=null&&this.personInfo.tripCode!=''){
    //     this.personInfo.tripCodeList = [this.personInfo.tripCode]
    // }
    //     if(this.this.personInfo.ankangCode!=null&&this.personInfo.ankangCode!=''){
    //       this.personInfo.ankangCodeList = [this.personInfo.ankangCode]
    //     }
  },
  methods: {
    // initdata(id) {
    //     if(id!=null&&id!="")
    //     this.persionInfo = getPersoninfo(id);
    // },
    getRegionList() {
      Api.getRegionList().then((res) => {
        const list = res.data;
        const ProvinceList = list.filter((p) => p.pid == 0);
        ProvinceList.forEach((province) => {
          this.areaList.province_list[province.id] = province.name;
          const cityList = list.filter((p) => p.pid == province.id);
          cityList.forEach((city) => {
            this.areaList.city_list[city.id] = city.name;
            const countyList = list.filter((p) => p.pid == city.id);
            countyList.forEach((county) => {
              this.areaList.county_list[county.id] = county.name;
            });
          });
        });
      });
    },
    getRiskList() {
      var that = this;
      Api.getRiskList().then((res) => {
        res.data.forEach((element) => {
          that.riskList.push({ text: element.riskName, value: element.id });
        });
      });
    },
    onConfirm(data, field) {
      this.showArea = false;
      this.personInfo[field] = data[data.length - 1].code;
      this.DataInfo[this.RegionField] = data.map((item) => item.name).join("/");
    },
    getPersonBaseInfo() {
      this.personInfo.idCard = this.personInfo.idCard.trim();
      const idCard = this.personInfo.idCard.trim();
      if (idCard != null && idCard != "") {
        const agesex = this.GetAgeSex(this.personInfo.idCard);
        this.personInfo.sex = agesex.sex;
        this.personInfo.age = agesex.age;
        Api.getOrgPersonBaseInfo(this.personInfo.idCard).then((res) => {
          if (res.data == null) return;
          const baseInfo = res.data;
          this.personInfo.personName = baseInfo.name;
          this.personInfo.phone = baseInfo.phone;
          this.personInfo.address = baseInfo.address;

          this.personInfo.gridId = baseInfo.gridId;
          this.personInfo.houseId = baseInfo.houseId;
          this.personInfo.buildingId = baseInfo.buildingId;
          this.personInfo.community = baseInfo.community;
          this.personInfo.liveId = baseInfo.liveId;
          this.personInfo.permanentAddress = baseInfo.permanentAddress;

          this.personInfo.idCard = idCard;

          this.getCommunityAllList();
        });
      }
    },
    GetAgeSex(identityCard) {
      const len = (identityCard + "").length;
      let strBirthday = "";
      let strSex = "";
      if (len == 18) {
        // 处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday =
          identityCard.substr(6, 4) +
          "/" +
          identityCard.substr(10, 2) +
          "/" +
          identityCard.substr(12, 2);

        if (identityCard.charAt(16) >= "0" && identityCard.charAt(16) <= "9") {
          if (parseInt(identityCard.charAt(16)) % 2 === 0) {
            strSex = "女";
          } else {
            strSex = "男";
          }
        }
      }
      if (len == 15) {
        let birthdayValue = "";
        birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
        if (parseInt(birthdayValue) < 10) {
          strBirthday =
            "20" +
            identityCard.substr(6, 2) +
            "/" +
            identityCard.substr(8, 2) +
            "/" +
            identityCard.substr(10, 2);
        } else {
          strBirthday =
            "19" +
            identityCard.substr(6, 2) +
            "/" +
            identityCard.substr(8, 2) +
            "/" +
            identityCard.substr(10, 2);
        }

        if (identityCard.substr(14) >= "0" && identityCard.substr(14) <= "9") {
          if (parseInt(identityCard.charAt(14) % 2 === 0)) {
            strSex = "女";
          } else {
            strSex = "男";
          }
        }
      }
      const birthDate = new Date(strBirthday);
      const nowDateTime = new Date();
      let age = nowDateTime.getFullYear() - birthDate.getFullYear();
      // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return { age: isNaN(age) ? "" : age, sex: strSex };
    },
    afterRead(file, field) {
      // 这时候我们创建一个formData对象实例
      const formData = new FormData();
      // 通过append方法添加需要的file
      // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
      formData.append("file", file.file);

      // 上传文件、成功后， 把Url 赋值给 field
      Api.uploadFile(formData).then((res) => {
        if (res.code == 0 && res.data != null && res.data != "") {
          this.personInfo[field] = res.data;
        }
      });
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();
      const hour = time.getHours();
      const min = time.getMinutes();
      return (
        year +
        "-" +
        this.padLeft(month, 2) +
        "-" +
        this.padLeft(day, 2) +
        " " +
        this.padLeft(hour, 2) +
        ":" +
        this.padLeft(min, 2) +
        ":00"
      );
    },
    padLeft(num, n) {
      const y = "00000000000000000000000000000" + num; // 爱几个0就几个，自己够用就行
      return y.substr(y.length - n);
    },
    getCommunityList() {
      this.communityList = [];
      Api.getOrgCommunityList().then((res) => {
        this.communityList = res.data;
        //   for (let index = 0; index < res.data.length; index++) {
        //       const item = res.data[index];
        //            this.communityList.push({text:item.name,value:item.id,children:item.children})
        //   }
      });
    },
    getCommunityAllList() {
      this.fieldValue = "";
      if (this.personInfo.community != "") {
        Api.getOrgLiveList({ communityId: this.personInfo.community }).then(
          (res) => {
            res.data.forEach((element) => {
              element["children"] = [];
            });
            try {
              this.communityList.find((p) => p.id == this.personInfo.community)[
                "children"
              ] = res.data;
              this.DataInfo.Community = this.communityList.find(
                (p) => p.id == this.personInfo.community
              ).name;
              this.fieldValue = this.DataInfo.Community;
            } catch {
              //
            }
            if (this.personInfo.liveId != "") {
              Api.getOrgStoreList({ liveId: this.personInfo.liveId }).then(
                (res) => {
                  res.data.forEach((element) => {
                    element["children"] = [];
                  });
                  try {
                    this.communityList
                      .find((p) => p.id == this.personInfo.community)
                      .children.find(
                        (p) => p.id == this.personInfo.liveId
                      ).children = res.data;
                    this.DataInfo.Live = this.communityList
                      .find((p) => p.id == this.personInfo.community)
                      .children.find(
                        (p) => p.id == this.personInfo.liveId
                      ).name;
                    this.fieldValue =
                      this.DataInfo.Community + "/" + this.DataInfo.Live;
                  } catch {
                    //
                  }
                  if (this.personInfo.buildingId != "") {
                    let storeList = this.communityList
                      .find((p) => p.id == this.personInfo.community)
                      .children.find(
                        (p) => p.id == this.personInfo.liveId
                      ).children;
                    let type = storeList.find(
                      (e) => e.id == this.personInfo.buildingId
                    ).storeTypeCode;
                    Api.getOrgPublicPage({
                      storeId: this.personInfo.buildingId,
                      limit: 1000,
                      type: type,
                    }).then((res) => {
                      try {
                        this.communityList
                          .find((p) => p.id == this.personInfo.community)
                          .children.find((p) => p.id == this.personInfo.liveId)
                          .children.find(
                            (p) => p.id == this.personInfo.buildingId
                          ).children = res.data;
                        this.DataInfo.Store = this.communityList
                          .find((p) => p.id == this.personInfo.community)
                          .children.find((p) => p.id == this.personInfo.liveId)
                          .children.find(
                            (p) => p.id == this.personInfo.buildingId
                          ).name;
                        this.fieldValue =
                          this.DataInfo.Community +
                          "/" +
                          this.DataInfo.Live +
                          "/" +
                          this.DataInfo.Store;
                      } catch {
                        //
                      }
                      if (this.personInfo.houseId != "") {
                        try {
                          this.DataInfo.House = res.data.find(
                            (p) => p.id == this.personInfo.houseId
                          ).name;
                          this.fieldValue =
                            this.DataInfo.Community +
                            "/" +
                            this.DataInfo.Live +
                            "/" +
                            this.DataInfo.Store +
                            "/" +
                            this.DataInfo.House;
                        } catch {
                          //
                        }
                      }
                    });
                  }
                }
              );
            }
          }
        );
      }
    },
    onChange(data) {
      switch (data.tabIndex) {
        case 0:
          Api.getOrgLiveList({ communityId: data.value }).then((res) => {
            res.data.forEach((element) => {
              element["children"] = [];
            });
            if (res.data.length == 0) this.onFinish(data);
            this.communityList.find((p) => p.id == data.value).children =
              res.data;
          });
          break;
        case 1:
          Api.getOrgStoreList({ liveId: data.value }).then((res) => {
            res.data.forEach((element) => {
              element["children"] = [];
            });
            if (res.data.length == 0) this.onFinish(data);
            this.communityList
              .find((p) => p.id == data.selectedOptions[0].id)
              .children.find((p) => p.id == data.value).children = res.data;
          });
          break;
        case 2:
          Api.getOrgPublicPage({
            storeId: data.selectedOptions[2].id,
            limit: 1000,
            type: data.selectedOptions[2].storeTypeCode,
          }).then((res) => {
            if (res.data.length == 0) this.onFinish(data);
            this.communityList
              .find((p) => p.id == data.selectedOptions[0].id)
              .children.find((p) => p.id == data.selectedOptions[1].id)
              .children.find(
                (p) => p.id == data.selectedOptions[2].id
              ).children = res.data;
          });
          break;
      }

      this.fieldValue = data.selectedOptions
        .map((option) => option.name)
        .join("/");

      this.personInfo.community =
        data.selectedOptions.length > 0 ? data.selectedOptions[0].id : "";

      this.personInfo.gridId =
        data.selectedOptions.length > 1 ? data.selectedOptions[1].gridId : "";

      this.personInfo.liveId =
        data.selectedOptions.length > 1 ? data.selectedOptions[1].id : "";

      this.personInfo.buildingId =
        data.selectedOptions.length > 2 ? data.selectedOptions[2].id : "";

      this.personInfo.houseId =
        data.selectedOptions.length > 3 ? data.selectedOptions[3].id : "";
    },

    onFinish(data) {
      this.show = false;

      this.fieldValue = data.selectedOptions
        .map((option) => option.name)
        .join("/");

      this.personInfo.community =
        data.selectedOptions.length > 0 ? data.selectedOptions[0].id : "";

      this.personInfo.gridId =
        data.selectedOptions.length > 1 ? data.selectedOptions[1].gridId : "";

      this.personInfo.liveId =
        data.selectedOptions.length > 1 ? data.selectedOptions[1].id : "";

      this.personInfo.buildingId =
        data.selectedOptions.length > 2 ? data.selectedOptions[2].id : "";

      this.personInfo.houseId =
        data.selectedOptions.length > 3 ? data.selectedOptions[3].id : "";
    },
    idCardValid(value) {
      if (!value) {
        this.IdCardMessage = "请输入证件号码";
        return false;
      }
      if (!/(^\d{8}$)|(^\d{9}$)|(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        this.IdCardMessage = "你输入的身份证、台胞证、护照编号长度或格式错误";
        return false;
      }
      if (value.length < 10) {
        return true;
      }
      var aCity = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外",
      };
      if (!aCity[parseInt(value.substr(0, 2))]) {
        this.IdCardMessage = "你的身份证地区非法";
        return false;
      }
      // 出生日期验证
      var sBirthday = (
        value.substr(6, 4) +
        "-" +
        Number(value.substr(10, 2)) +
        "-" +
        Number(value.substr(12, 2))
      ).replace(/-/g, "/");
      var d = new Date(sBirthday);
      if (
        sBirthday !=
        d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()
      ) {
        this.IdCardMessage = "身份证上的出生日期非法";
        return false;
      }

      // 身份证号码校验
      var sum = 0;
      var weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      var codes = "10X98765432";
      for (var i = 0; i < value.length - 1; i++) {
        sum += value[i] * weights[i];
      }
      var last = codes[sum % 11]; // 计算出来的最后一位身份证号码
      if (value[value.length - 1] != last) {
        this.IdCardMessage = "你输入的身份证号非法";
        return false;
      }
      return true;
    },

    submit() {
      // if(this.personInfo.ankangCode==null||this.personInfo.ankangCode=='')
      //     {
      //        Notify({ type: 'danger', message: '请上传安康码图片' });
      //       return
      //     }
      //     if(this.personInfo.tripCode==null||this.personInfo.tripCode=='')
      //     {
      //  Notify({ type: 'danger', message: '请上传行程码图片' });
      //       return
      //     }

      if (
        this.personInfo.isTransport == null ||
        this.personInfo.isTransport == ""
      ) {
        Notify({ type: "danger", message: "请选择是否转运" });
        return;
      }
      this.disBtn = true;
      this.personInfo.firstTime=this.personInfo.firstTime==''?null:this.personInfo.firstTime;
      this.personInfo.secondTime=this.personInfo.secondTime==''?null:this.personInfo.secondTime;
      this.personInfo.thirdTime=this.personInfo.thirdTime==''?null:this.personInfo.thirdTime;
      Api.addPersonInfo(this.personInfo)
        .then((res) => {
          if (res.code == 0) {
            Notify({ type: "success", message: "填报成功!" });
            // 成功后 清空表单数据
            this.personInfo = {
              crossDetail:'',
              caseRegion: "",
              firstTime: null,
              secondTime: null,
              thirdTime: null,
              address: "",
              age: 0,
              ankangCode: "",
              buildingId: "",
              community: "",
              dataSoucre: "",
              disease: "",
              flightNumber: "",
              redYellowDate:null,
              grid: "",
              gridId: 0,
              houseCode: "",
              houseId: "",
              idCard: "",
              isolatedCity: "",
              isolatedHotel: "",
              isolatedProve: "",
              lastContactDate:null,
              nucleinImg: "",
              permanentAddress: "",
              personName: "",
              personType: "",
              phone: "",
              releaseMode: "",
              remark: "",
              returnDate: "",
              arriveHomeTime:null,
              returnRegion: 0,
              touchRegion: 0,
              pushRegion: 0,
              sex: "",
              touchCase: "",
              tripCode: "",
              vehicle: "",
              workUnit: "",
              isDraft: "否",
              isTransport: "",
              occupation: "",
              tripCodeList: [],
              ankangCodeList: [],
              stayMsg: "",
              dataType: "",
            };

            this.fieldValue = "";
            this.cascaderValue = "";

            const DataInfo = ref({
              returnRegion: "",
              Community: "",
              Live: "",
              Store: "",
              House: "",
              touchRegion: "",
              pushRegion: "",
              tripCodeList: [],
              ankangCodeList: [],
              nucleinImgList: [],
              isolatedProveList: [],
            });

            this.router.push("blank");
          } else {
            Notify({ type: "danger", message: res.msg });
            this.disBtn = false;
          }
        })
        .catch((err) => {
          Notify({ type: "danger", message: JSON.stringify(err) });
        });
      setTimeout(() => {
        this.disBtn = false;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
}
.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}
.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}
.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}
.box::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
