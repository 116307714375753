<template>
  <van-form
    @failed="onFailed"
    @submit="submit"
  >
    <div class="about">
      <van-cell-group title="人员信息">
        <van-field
          v-model="personInfo.idCard"
          name="证件号码"
          label="证件号码"
          placeholder="证件号码"
          @blur="getPersonBaseInfo"
          :rules="[
            /*{
              required: false,
              message: '证件号码不能为空',
              trigger: 'onBlur',
            },
            {
              validator: idCardValid,
              message: IdCardMessage,
              trigger: 'onBlur',
            },*/
          ]"
        />

        <van-field
          v-model="personInfo.personName"
          name="姓名"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />

        <!-- <van-cell
          title="性别"
          :value="personInfo.sex"
        /> -->
        <!-- <van-cell title="年龄" :value="personInfo.age" /> -->

        <van-field
          v-model="personInfo.phone"
          name="手机号码"
          label="手机号码"
          placeholder="手机号码"
          :rules="[
            {
              required: true,
              message: '手机号码不能为空',
              trigger: 'onBlur',
            },
            {
              validator: (value) => {
                return /^(0|86|17951)?(1)[0-9]{10}$/.test(value);
              },
              message: '请输入正确格式的手机号码',
              trigger: 'onBlur',
            },
          ]"
        />

        <!-- <van-field
          v-model="personInfo.workUnit"
          name="工作单位"
          label="工作单位"
          placeholder="工作单位"
        />
        <van-field
          v-model="personInfo.occupation"
          name="职业"
          label="职业"
          placeholder="职业"
        /> -->
        <van-field
          v-model="personInfo.permanentAddress"
          name="户籍地址"
          label="户籍地址"
          placeholder="户籍地址"
        />
        <van-field
          v-model="personInfo.address"
          name="现居住地"
          label="现居住地"
          placeholder="现居住地"
        />
        <van-field
          v-model="fieldValue"
          is-link
          readonly
          label="所在地区"
          placeholder="请选择所在地区"
          @click="show = true"
          :rules="[{ required: true, message: '请选择所在地区' }]"
        />
        <van-popup
          v-model:show="show"
          round
          position="bottom"
        >
          <van-cascader
            v-model="personInfo.AreaId"
            title="请选择所在地区"
            :field-names="fieldNames"
            :options="communityList"
            @close="show = false"
            @change="onChange"
            @finish="onFinish"
          />
        </van-popup>

        <van-row style="margin-top: 15px">
          <van-col
            style="
              font-size: 14px;
              color: #646566;
              padding-left: 15px;
              padding-top: 10px;
            "
            span="6"
          >所属网格</van-col>
          <van-col span="18">
            <el-select
              style="width: 98%"
              v-model="personInfo.gridId"
              ref="gridField"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键字"
              :remote-method="remoteMethod"
              :loading="loading"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </van-col>
        </van-row>

        <van-field
          v-model="personInfo.disease"
          name="基础疾病情况"
          label="基础疾病情况"
          placeholder="请输入"
        />
        <van-field
          v-model="
            dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]
          "
          readonly
          name="picker"
          label="人员类型"
          placeholder="点击选择人员类型"
          :rules="[{ required: true, message: '人员类型' }]"
        />
        <van-popup
          v-model:show="showPicker"
          position="bottom"
        >
          <van-picker
            value-key="value"
            :columns="dicPersonTypeLabel"
            @confirm="
              (value) => {
                personInfo.personType =
                  dicPersonType[dicPersonTypeLabel.indexOf(value)];
                showPicker = false;
              }
            "
            :default-index="dicPersonType.indexOf(personInfo.personType)"
            @cancel="showPicker = false"
          />
        </van-popup>
      </van-cell-group>
      <!-- 密接次密接 需填写病例 、密接姓名、m末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 阳性物品暴露人员 需填写 末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 高风险地区来（返）肥人员 需填写  返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 红黄码人员 需填写 安康码、行程码-->
      <!-- 其他情况 需填写  其他说明、安康码、行程码-->
      <van-cell-group :title="
          dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]
        ">
        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 5 &&
            personInfo.personType != 'BjStudent'
          "
          v-model="personInfo.touchCase"
          name="密接病例"
          label="密接病例"
          placeholder="密接病例"
        />

        <van-field
          v-model="DataInfo.pushRegion"
          is-link
          readonly
          v-if="
            dicPersonType.indexOf(personInfo.personType) == 5 &&
            personInfo.personType != 'BjStudent'
          "
          name="area"
          label="推送次密接地区"
          placeholder="推送次密接地区"
          @click="
            showArea = true;
            RegionField = 'pushRegion';
          "
        />
        <van-field
          v-model="DataInfo.touchRegion"
          is-link
          readonly
          v-if="
            dicPersonType.indexOf(personInfo.personType) == 5 &&
            personInfo.personType != 'BjStudent'
          "
          name="area"
          label="密接所在地区"
          placeholder="密接所在地区"
          @click="
            showArea = true;
            RegionField = 'touchRegion';
          "
        />

        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 5 &&
            personInfo.personType != 'BjStudent'
          "
          v-model="personInfo.releaseMode"
          name="接触方式"
          label="接触方式"
          placeholder="接触方式"
        />

        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 5&&
            personInfo.personType != 'BjStudent'
          "
          v-model="personInfo.lastContactDate"
          name="末次接触时间"
          label="末次接触时间"
          placeholder="末次接触时间"
          :rules="[{ required: true, message: '末次接触时间' }]"
          @click="
            showDatetimePicker = true;
            DatetimeDefault =
              personInfo.lastContactDate == null ||
              personInfo.lastContactDate == ''
                ? new Date()
                : new Date(personInfo.lastContactDate);
            DatetimePickerField = 'lastContactDate';
          "
        />

        <van-popup
          v-model:show="showDatetimePicker"
          position="bottom"
        >
          <van-datetime-picker
            v-model="DatetimeDefault"
            type="datetime"
            @confirm="
              (value) => {
                personInfo[DatetimePickerField] = timeFormat(value);
                showDatetimePicker = false;
              }
            "
            @cancel="showDatetimePicker = false"
          />
        </van-popup>
        <van-cell
          center
          title="是否转运"
        >
          <template #right-icon>
            <el-select
              v-model="personInfo.isTransport"
              :disabled="personInfo.isTransport == 1"
              placeholder="请选择是否转运"
            >
              <el-option
                :value="1"
                label="是"
              ></el-option>
              <el-option
                :value="2"
                label="否"
              ></el-option>
              <el-option
                :value="3"
                label="道口转运"
              ></el-option>
            </el-select>
          </template>
        </van-cell>
        <van-field
          v-if="personInfo.isTransport==3"
          v-model="personInfo.crossDetail"
          rows="2"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="请输入详细情形"
          show-word-limit
          name="详细情形"
          label="详细情形"
          :rules="[{ required: true, message: '请输入详细情形'}]"
        />
        <van-cell
          center
          title="是否抵肥"
          v-if="
            dicPersonType.indexOf(personInfo.personType) == 2 ||
            personInfo.personType == 'BjStudent'
          "
        >
          <template #right-icon>
            <el-select
              v-model="personInfo.isDraft"
              placeholder="请选择是否抵肥"
            >
              <el-option value="是">是</el-option>
              <el-option value="否">否</el-option>
            </el-select>
          </template>
        </van-cell>
        <van-field
          v-if="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
          "
          is-link
          v-model="personInfo.returnDate"
          name="返回合肥时间"
          label="返回合肥时间"
          placeholder="返回合肥时间"
          :rules="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
              ? [{ required: false }]
              : [{ required: true, message: '返回合肥时间' }]
          "
          @click="
            showDatetimePicker = true;
            DatetimeDefault = new Date();
            DatetimePickerField = 'returnDate';
          "
        />
        <van-field
          v-if="personInfo.personType == 'BjStudent'"
          is-link
          v-model="personInfo.arriveHomeTime"
          name="到家时间"
          label="到家时间"
          placeholder="到家时间"
          :rules="
            personInfo.isDraft == '是'
              ? [{ required: true, message: '到家时间' }]
              : [{ required: false }]
          "
          @click="
            showDatetimePicker = true;
            DatetimeDefault = new Date();
            DatetimePickerField = 'arriveHomeTime';
          "
        />
        <van-field
          v-if="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
          "
          is-link
          v-model="personInfo.leaveTime"
          name="离肥时间"
          label="离肥时间"
          placeholder="请输入离肥时间"
          @click="
            showDatetimePicker = true;
            DatetimeDefault = new Date();
            DatetimePickerField = 'leaveTime';
          "
        />
        <van-field
          v-model="personInfo.soureRegion"
          v-if="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
          "
          name="area"
          label="来源区域"
          placeholder="来源区域"
          :rules="[{ required: true, message: '来源区域' }]"
        />
        <van-popup
          v-model:show="showArea"
          position="bottom"
        >
          <van-area
            :area-list="areaList"
            @confirm="(data) => onConfirm(data, RegionField)"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-if="personInfo.personType == 'HighRisk'"
          v-model="personInfo.dataType"
          name="数据类型"
          label="数据类型"
          placeholder="数据类型"
        />
        <van-field
          v-if="personInfo.personType == 'HighRisk'"
          v-model="personInfo.stayMsg"
          name="住宿信息"
          label="住宿信息"
          placeholder="住宿信息"
        />
         <van-field
        v-if="personInfo.personType == 'HighRisk'||personInfo.personType =='ReturnHf'"
          v-model="personInfo.lastContactDate"
          name="末次接触时间"
          label="末次接触时间"
          placeholder="末次接触时间"
          :rules="[{ required: true, message: '末次接触时间' }]"
          @click="
            showDatetimePicker = true;
            DatetimeDefault =
              personInfo.lastContactDate == null ||
              personInfo.lastContactDate == ''
                ? new Date()
                : new Date(personInfo.lastContactDate);
            DatetimePickerField = 'lastContactDate';
          "
        />
        <template v-if="personInfo.personType =='Touch'||personInfo.personType =='SecondTouch'">
         <van-field
          v-model="personInfo.caseRegion"
          name="病例所在市县区"
          label="病例所在市县区"
          placeholder="病例所在市县区"
        />
         <van-field
          v-model="personInfo.firstTime"
          name="第一次采样时间"
          label="第一次采样时间"
          placeholder="第一次采样时间"
          @click="
            showDatetimePicker = true;
            DatetimeDefault =
              personInfo.firstTime == null ||
              personInfo.firstTime == ''
                ? new Date()
                : new Date(personInfo.firstTime);
            DatetimePickerField = 'firstTime';
          "
        />
         <van-field
          v-model="personInfo.secondTime"
          name="第二次采样时间"
          label="第二次采样时间"
          placeholder="第二次采样时间"
          @click="
            showDatetimePicker = true;
            DatetimeDefault =
              personInfo.secondTime == null ||
              personInfo.secondTime == ''
                ? new Date()
                : new Date(personInfo.secondTime);
            DatetimePickerField = 'secondTime';
          "
        />
         <van-field
          v-model="personInfo.thirdTime"
          name="第三次采样时间"
          label="第三次采样时间"
          placeholder="第三次采样时间"
          @click="
            showDatetimePicker = true;
            DatetimeDefault =
              personInfo.thirdTime == null ||
              personInfo.thirdTime == ''
                ? new Date()
                : new Date(personInfo.thirdTime);
            DatetimePickerField = 'thirdTime';
          "
        />
        </template>
        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) != 3
          "
          v-model="personInfo.vehicle"
          name="交通工具"
          label="交通工具"
          placeholder="交通工具"
        />
        <van-field
          v-if="
            dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) != 3
          "
          v-model="personInfo.flightNumber"
          name="航班车次号"
          label="航班车次号"
          placeholder="航班车次号"
        />

        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) == 0||dicPersonType.indexOf(personInfo.personType) == 1"
          v-model="personInfo.redYellowDate"
          name="转码时间"
          label="转码时间"
          placeholder="转码时间"
          :rules="[{ required: true, message: '转码时间' }]"
          @click="
            showDatetimePicker = true;
            DatetimeDefault =
              personInfo.redYellowDate == null || personInfo.redYellowDate == ''
                ? new Date()
                : new Date(personInfo.redYellowDate);
            DatetimePickerField = 'redYellowDate';
          "
        />

        <van-cell title="安康码">
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="DataInfo.ankangCodeList"
              :after-read="(file) => afterRead(file, 'ankangCode')"
              max-count="1"
              :rules="[{ required: true, message: '安康码' }]"
            />
          </template>
        </van-cell>
        <van-cell title="行程码">
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="DataInfo.tripCodeList"
              :after-read="(file) => afterRead(file, 'tripCode')"
              max-count="1"
              :rules="[{ required: true, message: '行程码' }]"
            />
          </template>
        </van-cell>
        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) == 4"
          v-model="personInfo.isolatedCity"
          name="隔离城市"
          label="隔离城市"
          placeholder="隔离城市"
        />

        <van-field
          v-model="personInfo.isolatedHotel"
          name="隔离酒店"
          label="隔离酒店"
          placeholder="隔离酒店"
        />
        <van-cell
          v-if="
            (dicPersonType.indexOf(personInfo.personType) >= 2 &&
              dicPersonType.indexOf(personInfo.personType) <= 4) ||
            personInfo.personType == 'BjStudent'
          "
          :title="
            dicPersonType.indexOf(personInfo.personType) <= 3
              ? '返回后核酸'
              : '核酸检测照片'
          "
        >
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="DataInfo.nucleinImgList"
              :after-read="(file) => afterRead(file, 'nucleinImg')"
              max-count="1"
              :rules="[
                {
                  required: true,
                  message:
                    dicPersonType.indexOf(personInfo.personType) <= 3
                      ? '返回后核酸'
                      : '核酸检测照片',
                },
              ]"
            />
          </template>
        </van-cell>
        <van-cell
          title="解除隔离证明"
          v-if="dicPersonType.indexOf(personInfo.personType) == 4"
        >
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="DataInfo.isolatedProveList"
              :after-read="(file) => afterRead(file, 'isolatedProve')"
              max-count="1"
            />
          </template>
        </van-cell>

        <van-field
          v-model="personInfo.remark"
          rows="2"
          autosize
          label="其他说明"
          type="textarea"
          maxlength="50"
          placeholder="请输入其他说明"
          show-word-limit
        />
      </van-cell-group>
      <van-cell-group title="管控信息
        ">
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控措施"
          :value="
            this.getDictLabel('controlStep', personInfo.controlMsg.controlStep)
          "
          is-link
          readonly
          placeholder="管控措施"
          :rules="[{ required: true, message: '管控措施' }]"
          @click="
            ControlPickShow = true;
            PickerField = 'controlStep';
            DicPickerField = 'controlStep';
          "
        />
        <van-popup
          v-model:show="ControlPickShow"
          round
          position="bottom"
        >
          <van-picker
            :title="
              DicPickerField == 'controlStatus'
                ? '选择管控状态'
                : '选择管控措施'
            "
            :columns-field-names="{ text: 'dictLabel', value: 'dictValue' }"
            :columns="
              $store.state.Dict.find((p) => p.dictType == DicPickerField)
                .dataList
            "
            @confirm="onConfirmDriver"
            @cancel="ControlPickShow = false"
            @change="onChange"
          />
        </van-popup>
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控状态"
          v-if="
            personInfo.controlMsg.controlStep != 'NoSegregation' &&
            personInfo.controlMsg.controlStep != 'CancelReturnHf'
          "
          :rules="[{ required: true, message: '管控状态' }]"
          is-link
          readonly
          :value="
            this.getDictLabel(
              'controlStatus',
              personInfo.controlMsg.controlStatus
            )
          "
          @click="
            ControlPickShow = true;
            PickerField = 'controlStatus';
            DicPickerField = 'controlStatus';
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="隔离模式"
          :rules="[{ required: true, message: '隔离模式' }]"
          v-if="
            personInfo.controlMsg.controlStep != 'NoSegregation' &&
            personInfo.controlMsg.controlStep != 'CancelReturnHf'
          "
          is-link
          readonly
          :value="
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null
              ? IsolatedProveList.find(
                  (p) => p.id == personInfo.controlMsg.isolationMode
                ).patternName
              : ''
          "
          @click="
            isolationModePickShow = true;
            PickerField = 'isolationMode';
            DicPickerField = 'isolationMode';
          "
        />
        <van-popup
          v-model:show="isolationModePickShow"
          round
          position="bottom"
        >
          <van-picker
            title="选择隔离模式"
            v-model="personInfo.controlMsg.isolationMode"
            :columns-field-names="{ text: 'patternName', value: 'id' }"
            :columns="IsolatedProveList"
            @confirm="onConfirmIsolationMode"
            @cancel="isolationModePickShow = false"
          />
        </van-popup>
        <van-field
          v-if="
            personInfo.controlMsg.controlStep == 'FocusSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).focusDay != 0
          "
          v-model="personInfo.controlMsg.relieveCentralizeDate"
          is-link
          readonly
          name="datetimePicker"
          label="解除集中隔离日期"
          placeholder="解除集中隔离日期"
          @click="
            showPicker1 = true;
            DatetimeDefault = new Date(
              personInfo.controlMsg.relieveCentralizeDate
            );
            DatetimePickerField = 'relieveCentralizeDate';
          "
        />
        <van-popup
          v-model:show="showPicker1"
          position="bottom"
        >
          <van-datetime-picker
            v-model="DatetimeDefault"
            type="datetime"
            @confirm="
              (value) => {
                personInfo.controlMsg[DatetimePickerField] = timeFormat(value);
                showPicker1 = false;
              }
            "
            @cancel="showPicker1 = false"
          />
        </van-popup>

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="集中隔离填报周期"
          v-if="
            personInfo.controlMsg.controlStep == 'FocusSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).focusDay != 0
          "
          :value="
            this.getDictLabel(
              'ReportingCycle',
              personInfo.controlMsg.centralizeCycle
            )
          "
          is-link
          readonly
          placeholder="集中隔离填报周期"
          @click="
            ControlPickShow = true;
            PickerField = 'centralizeCycle';
            DicPickerField = 'ReportingCycle';
          "
        />

        <van-field
          v-if="
            (personInfo.controlMsg.controlStep == 'FocusSegregation' ||
              personInfo.controlMsg.controlStep == 'InHomeSegregation') &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).homeDay != 0
          "
          v-model="personInfo.controlMsg.relieveHomeDate"
          is-link
          readonly
          name="datetimePicker"
          label="解除居家隔离日期"
          placeholder="解除隔离日期"
          @click="
            showPicker1 = true;
            DatetimeDefault = new Date(personInfo.controlMsg.relieveHomeDate);
            DatetimePickerField = 'relieveHomeDate';
          "
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="居家隔离填报周期"
          v-if="
            (personInfo.controlMsg.controlStep == 'FocusSegregation' ||
              personInfo.controlMsg.controlStep == 'InHomeSegregation') &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).homeDay != 0
          "
          :value="
            this.getDictLabel('ReportingCycle', personInfo.controlMsg.homeCycle)
          "
          is-link
          readonly
          placeholder="居家隔离填报周期"
          @click="
            ControlPickShow = true;
            PickerField = 'homeCycle';
            DicPickerField = 'ReportingCycle';
          "
        />
        <van-field
          v-if="
            personInfo.controlMsg.controlStep != 'NoSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).healthDay != 0
          "
          v-model="personInfo.controlMsg.relieveHealthDate"
          is-link
          readonly
          name="datetimePicker"
          label="解除健康监测日期"
          placeholder="解除健康监测日期"
          @click="
            showPicker1 = true;
            DatetimeDefault = new Date(personInfo.controlMsg.relieveHealthDate);
            DatetimePickerField = 'relieveHealthDate';
          "
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="健康监测填报周期"
          v-if="
            personInfo.controlMsg.controlStep != 'NoSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).healthDay != 0
          "
          :value="
            this.getDictLabel(
              'ReportingCycle',
              personInfo.controlMsg.healthCycle
            )
          "
          is-link
          readonly
          placeholder="健康监测填报周期"
          @click="
            ControlPickShow = true;
            PickerField = 'healthCycle';
            DicPickerField = 'ReportingCycle';
          "
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控类型"
          v-if="
            personInfo.controlMsg.controlStep == 'FocusSegregation' ||
            personInfo.controlMsg.controlStep == 'InHomeSegregation'
          "
          :value="
            this.getDictLabel('controlType', personInfo.controlMsg.controlType)
          "
          is-link
          readonly
          placeholder="管控类型"
          @click="
            ControlPickShow = true;
            PickerField = 'controlType';
            DicPickerField = 'controlType';
          "
        />

        <van-field
          v-if="
            personInfo.controlMsg.controlStep == 'FocusSegregation' ||
            personInfo.controlMsg.controlStep == 'InHomeSegregation'
          "
          v-model="personInfo.controlMsg.deviceImei"
          name="管控设备序列号"
          label="管控设备序列号"
          placeholder="管控设备序列号"
        />

        <van-field
          v-model="personInfo.controlMsg.remark"
          name="管控备注"
          label="管控备注"
          placeholder="管控备注"
        />
      </van-cell-group>
    </div>

    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        :disabled="disBtn"
        native-type="submit"
      >
        审核
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { ref } from "@vue/reactivity";
// @ is an alias to /src
import { useRouter } from "vue-router";
import { addDays, format, differenceInDays } from "date-fns";
import { ImagePreview, Notify, Toast, Dialog } from "vant";
import Api from "../util/App";
export default {
  name: "Home",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const personInfo = ref({
      caseRegion:'',
      firstTime:null,
      secondTime:null,
      thirdTime:null,
      leaveTime:null,
      id: "",
      address: "",
      age: 0,
      ankangCode: "",
      buildingId: "",
      community: "",
      dataSoucre: "",
      disease: "",
      flightNumber: "",
      grid: "",
      gridId: 0,
      houseCode: "",
      houseId: "",
      idCard: "",
      isolatedCity: "",
      isolatedHotel: "",
      isolatedProve: "",
      lastContactDate: "",
      redYellowDate: "",
      nucleinImg: "",
      permanentAddress: "",
      personName: "",
      personType: "",
      phone: "",
      releaseMode: "",
      remark: "",
      returnDate:null,
      arriveHomeTime:null,
      returnRegion: 0,
      sex: "",
      touchCase: "",
      tripCode: "",
      vehicle: "",
      workUnit: "",
      isDraft: "",
      isTransport: "",
      stayMsg: "",
      dataType: "",
      controlMsg: {
        centralizeCycle: "",
        controlStatus: "",
        controlStep: "",
        controlType: "",
        createDate: "",
        creator: 0,
        deviceImei: "",
        healthCycle: "",
        homeCycle: "",
        id: 0,
        isDelete: 0,
        isolationMode: "",
        personId: 0,
        relieveCentralizeDate: "",
        relieveHealthDate: "",
        relieveHomeDate: "",
        remark: "",
        status: "",
        statusDate: "",
        transportType: "",
        updateDate: "",
        updater: 0,
      },
    });

    const dicBeginTime = {
      Positive: "redYellowDate",
      RedCode: "redYellowDate",
      ReturnHf: "lastContactDate",
      HighRisk: "lastContactDate",
      Abroad: "returnDate",
      SecondTouch: "lastContactDate",
      Touch: "lastContactDate",
      BjStudent: "arriveHomeTime",
    };

    const dicPersonTypeLabel = [
       "阳性病例",
      "红码人员",
      "返肥人员",
      "中高风险地区返回",
      "境外人员",
      "次密接",
      "密接",
      "北京/上海大学生",
    ];

    const dicPersonType = [
      "Positive",
      "RedCode",
      "ReturnHf",
      "HighRisk",
      "Abroad",
      "SecondTouch",
      "Touch",
      "BjStudent",
    ];
    const showPicker = ref(false);
    const showDatetimePicker = ref(false);
    const DatetimePickerField = ref(false);
    const router = useRouter();
    const showArea = ref(false);
    const areaList = ref({
      province_list: {},
      city_list: {},
      county_list: {},
    });
    const show = ref(false);
    const fieldValue = ref("");
    const cascaderValue = ref("");
    const communityList = ref([
      //   {
      //     "id": "3401044000010000",
      //     "pid": "1067246875800000066",
      //     "children": [],
      //     "name": "万泽社区",
      //     "lon": null,
      //     "lat": null,
      //     "sort": 1,
      //     "createDate": null,
      //     "parentName": null,
      //     "remark": null,
      //     "isDelete": 0,
      //     "userLists": null
      //   }
    ]);
    const fieldNames = {
      text: "name",
      value: "id",
    };

    const DataInfo = ref({
      returnRegion: "",
      Community: "",
      Live: "",
      Store: "",
      House: "",
      touchRegion: "",
      pushRegion: "",
      tripCodeList: [],
      ankangCodeList: [],
      nucleinImgList: [],
      isolatedProveList: [],
    });

    const approveInfo = ref({
      approveDate: "",
      approveResult: "",
      approve_user: 0,
      changeType: 1,
      content: "",
      createDate: "",
      creator: 0,
      isDelete: 0,
      personId: 0,
      remark: "",
      status: "",
      updateDate: "",
      updater: 0,
    });

    const timeFormat = (time) => {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return (
        year +
        "-" +
        padLeft(month, 2) +
        "-" +
        padLeft(day, 2) +
        " " +
        padLeft(hour, 2) +
        ":" +
        padLeft(min, 2) +
        ":00"
      );
    };
    const DatetimeDefault = ref(new Date());
    const padLeft = (num, n) => {
      let y = "00000000000000000000000000000" + num; //爱几个0就几个，自己够用就行
      return y.substr(y.length - n);
    };

    const disBtn = ref(false);
    const RegionField = ref("");
    const ShowDateChange = ref(false);
    const IsolatedProveList = ref([]);
    const ControlPickShow = ref(false);
    const isolationModePickShow = ref(false);
    const showPicker1 = ref(false);
    const loading = ref(false);
    const options = ref([]);
    const GridList = ref([]);
    const IdCardMessage = ref("");
    return {
      router,
      loading,
      IdCardMessage,
      options,
      GridList,
      disBtn,
      showPicker1,
      personInfo,
      dicBeginTime,
      ShowDateChange,
      ControlPickShow,
      isolationModePickShow,
      IsolatedProveList,
      dicPersonType,
      DatetimeDefault,
      dicPersonTypeLabel,
      ImagePreview,
      showPicker,
      RegionField,
      timeFormat,
      showDatetimePicker,
      DatetimePickerField,
      show,
      fieldValue,
      cascaderValue,
      communityList,
      fieldNames,
      showArea,
      areaList,
      DataInfo,
    };
  },
  mounted() {
    let id = this.router.currentRoute.value.query.id;
    this.initdata(id);
  },
  activated() {
    let id = this.router.currentRoute.value.query.id;
    this.initdata(id);
  },
  watch:{
    "personInfo.redYellowDate"(newval, oldval) {
      if (newval != oldval && newval) {
        this.onConfirmIsolationMode(this.personInfo.controlMsg.isolationMode);
      }
    },
    "personInfo.lastContactDate"(newval, oldval) {
      if (newval != oldval && newval) {
        this.onConfirmIsolationMode(this.personInfo.controlMsg.isolationMode);
      }
    },
    "personInfo.returnDate"(newval, oldval) {
      if (newval != oldval && newval) {
        this.onConfirmIsolationMode(this.personInfo.controlMsg.isolationMode);
      }
    },
    "personInfo.arriveHomeTime"(newval, oldval) {
      if (newval != oldval && newval) {
        this.onConfirmIsolationMode(this.personInfo.controlMsg.isolationMode);
      }
    },
    "personInfo.isTransport"(newval,oldval){
      if(newval!=oldval&&newval==3){
        this.personInfo.controlMsg.controlStep='FocusSegregation';
        this.personInfo.controlMsg.controlStatus='CentralizedIsolation';
      }else{
        this.personInfo.crossDetail="";
      }
    }
  },
  methods: {
    idCardValid(value) {
      if (!value) {
        this.IdCardMessage = "请输入身份证号";
        return false;
      }
      if (!/(^\d{8}$)|(^\d{9}$)|(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        this.IdCardMessage = "你输入的身份证、台胞证、护照编号长度或格式错误";
        return false;
      }
      if (value.length < 10) {
        return true;
      }

      var aCity = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外",
      };
      if (!aCity[parseInt(value.substr(0, 2))]) {
        this.IdCardMessage = "你的身份证地区非法";
        return false;
      }
      // 出生日期验证
      var sBirthday = (
          value.substr(6, 4) +
          "-" +
          Number(value.substr(10, 2)) +
          "-" +
          Number(value.substr(12, 2))
        ).replace(/-/g, "/"),
        d = new Date(sBirthday);
      if (
        sBirthday !=
        d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()
      ) {
        this.IdCardMessage = "身份证上的出生日期非法";
        return false;
      }

      // 身份证号码校验
      var sum = 0,
        weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
        codes = "10X98765432";
      for (var i = 0; i < value.length - 1; i++) {
        sum += value[i] * weights[i];
      }
      var last = codes[sum % 11]; //计算出来的最后一位身份证号码
      if (value[value.length - 1] != last) {
        this.IdCardMessage = "你输入的证件号码非法";
        return false;
      }
      return true;
    },
    getAllGrid(id) {
      let that = this;
      Api.getGridList({community:id}).then((res) => {
        this.GridList = res.data;
        that.options = this.GridList;
        if (this.GridList.find((p) => p.id == that.personInfo.gridId) == null) {
          that.personInfo.gridId = "";
        } else {
          //   that.$refs.gridField.selectedLabel = this.GridList.find(p => p.id == that.personInfo.gridId) == null ? '' : this.GridList.find(p => p.id == that.personInfo.gridId).name
        }
      });
    },
    remoteMethod(query) {
      if (query != "") {
        this.options = this.GridList.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });
      } else {
        this.options = this.GridList;
      }
    },
    initdata(id) {
      let that = this;
      if (id != null && id != "") {
        Api.getPersoninfo(id).then((res) => {
          if (res.data.controlMsg == null) {
            res.data.controlMsg = {
              centralizeCycle: "",
              controlStatus: "",
              controlStep: "",
              controlType: "",
              createDate: "",
              creator: 0,
              deviceImei: "",
              healthCycle: "",
              homeCycle: "",
              id: 0,
              isDelete: 0,
              isolationMode: "",
              personId: 0,
              relieveCentralizeDate: "",
              relieveHealthDate: "",
              relieveHomeDate: "",
              remark: "",
              status: "",
              statusDate: "",
              transportType: "",
              updateDate: "",
              updater: 0,
            };
          }

          this.personInfo = res.data;
          if (this.personInfo.houseId != "") {
            this.personInfo.AreaId = this.personInfo.houseId;
          } else if (this.personInfo.liveId != "") {
            this.personInfo.AreaId = this.personInfo.liveId;
          } else if (this.personInfo.buildingId != "") {
            this.personInfo.AreaId = this.personInfo.buildingId;
          } else if (this.personInfo.communityId != "") {
            this.personInfo.AreaId = this.personInfo.community;
          }
          this.getAllGrid(this.personInfo.community);

          this.getCommunityList();
          this.getRegionList();
          this.DataInfo.tripCodeList = [];
          if (
            this.personInfo.tripCode != null &&
            this.personInfo.tripCode != ""
          ) {
            this.DataInfo.tripCodeList = [{ url: this.personInfo.tripCode }];
          }
          this.DataInfo.ankangCodeList = [];
          if (
            this.personInfo.ankangCode != null &&
            this.personInfo.ankangCode != ""
          ) {
            this.DataInfo.ankangCodeList = [
              { url: this.personInfo.ankangCode },
            ];
          }

          this.DataInfo.nucleinImgList = [];
          if (
            this.personInfo.nucleinImg != null &&
            this.personInfo.nucleinImg != ""
          ) {
            this.DataInfo.nucleinImgList = [
              { url: this.personInfo.nucleinImg },
            ];
          }

          this.DataInfo.isolatedProveList = [];
          if (
            this.personInfo.isolatedProve != null &&
            this.personInfo.isolatedProve != ""
          ) {
            this.DataInfo.isolatedProveList = [
              { url: this.personInfo.isolatedProve },
            ];
          }

          this.getRegion();
          this.getCommunityLive();
          this.getIsolationModeList();

          if (this.personInfo.controlMsg.centralizeCycle == "") {
            this.personInfo.controlMsg.centralizeCycle = "1";
          }

          if (this.personInfo.controlMsg.homeCycle == "") {
            this.personInfo.controlMsg.homeCycle = "1";
          }

          if (this.personInfo.controlMsg.healthCycle == "") {
            this.personInfo.controlMsg.healthCycle = "1";
          }
        });
      }
    },
    getRegion() {
      Api.getRegionList().then((res) => {
        try {
          let list = res.data;
          // if (this.personInfo.returnRegion != "") {
          //   let county = list.find((p) => p.id == this.personInfo.returnRegion);
          //   let city = list.find((p) => p.id == county.pid);
          //   let province = list.find((p) => p.id == city.pid);

          //   this.DataInfo.returnRegion =
          //     province.name + "/" + city.name + "/" + county.name;
          // }
          if (this.personInfo.pushRegion != "") {
            let county = list.find((p) => p.id == this.personInfo.pushRegion);
            let city = list.find((p) => p.id == county.pid);
            let province = list.find((p) => p.id == city.pid);

            this.DataInfo.pushRegion =
              province.name + "/" + city.name + "/" + county.name;
          }

          if (this.personInfo.touchRegion != "") {
            let county = list.find((p) => p.id == this.personInfo.touchRegion);
            let city = list.find((p) => p.id == county.pid);
            let province = list.find((p) => p.id == city.pid);

            this.DataInfo.touchRegion =
              province.name + "/" + city.name + "/" + county.name;
          }
        } catch {
          //
        }
      });
    },
    getCommunityLive() {
      Api.getOrgCommunityList().then((res) => {
        try {
          this.communityList = res.data;
          this.DataInfo.Community = this.communityList.find(
            (p) => p.id == this.personInfo.community
          ).name;
          this.getCommunityAllList();
        } catch {
          //
        }
      });
      if (this.personInfo.community != null) {
        Api.getOrgLiveList({ communityId: this.personInfo.community }).then(
          (res) => {
            try {
              this.DataInfo.Live = res.data.find(
                (p) => p.id == this.personInfo.liveId
              ).name;
            } catch {
              //
            }
          }
        );
      }
      let storeList='';
      Api.getOrgStoreList({ liveId: this.personInfo.liveId }).then((res) => {
        try {
          storeList=res.data;
          this.DataInfo.Store = res.data.find(
            (p) => p.id == this.personInfo.buildingId
          ).name;
        let type=storeList.find(e=>e.id==this.personInfo.buildingId).storeTypeCode;
         Api.getOrgPublicPage({
        storeId: this.personInfo.buildingId,
        limit: 1000,
        type:type
      }).then((res) => {
        try {
          this.DataInfo.House = res.data.find(
            (p) => p.id == this.personInfo.houseId
          ).name;
        } catch {
          //
        }
      });
        } catch {
          //
        }
      });
     // let storeList=this.communityList.find(p => p.id == this.personInfo.community).children.find(p => p.id == this.personInfo.liveId).children;
    },

    getRegionList() {
      Api.getRegionList().then((res) => {
        let list = res.data;
        let ProvinceList = list.filter((p) => p.pid == 0);
        ProvinceList.forEach((province) => {
          this.areaList.province_list[province.id] = province.name;
          let cityList = list.filter((p) => p.pid == province.id);
          cityList.forEach((city) => {
            this.areaList.city_list[city.id] = city.name;
            let countyList = list.filter((p) => p.pid == city.id);
            countyList.forEach((county) => {
              this.areaList.county_list[county.id] = county.name;
            });
          });
        });
      });
    },
    getIsolationModeList() {
      Api.getControlModeList().then((res) => {
        try {
          this.IsolatedProveList = res.data;
        } catch {
          //
        }
      });
    },
    onConfirm(data, field) {
      this.showArea = false;
      this.personInfo[field] = data[data.length - 1].code;
      this.DataInfo[this.RegionField] = data.map((item) => item.name).join("/");
    },
    getPersonBaseInfo() {
      let idCard = this.personInfo.idCard;
      if (idCard != null && idCard != "") {
        let agesex = this.GetAgeSex(this.personInfo.idCard);
        this.personInfo.sex = agesex.sex;
        this.personInfo.age = agesex.age;
        Api.getOrgPersonBaseInfo(this.personInfo.idCard).then((res) => {
          let baseInfo = res.data;

          this.personInfo.personName = baseInfo.name;
          this.personInfo.phone = baseInfo.phone;
          this.personInfo.address = baseInfo.address;

          this.personInfo.gridId = baseInfo.gridId;
          this.personInfo.houseId = baseInfo.houseId;
          this.personInfo.buildingId = baseInfo.buildingId;
          this.personInfo.community = baseInfo.community;
          this.personInfo.liveId = baseInfo.liveId;
          this.personInfo.permanentAddress = baseInfo.permanentAddress;

          this.personInfo.idCard = idCard;

          this.getCommunityAllList();
        });
      }
    },
    GetAgeSex(identityCard) {
      let len = (identityCard + "").length;
      let strBirthday = "";
      let strSex = "";
      if (len == 18) {
        //处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday =
          identityCard.substr(6, 4) +
          "/" +
          identityCard.substr(10, 2) +
          "/" +
          identityCard.substr(12, 2);

        if (identityCard.charAt(16) >= "0" && identityCard.charAt(16) <= "9") {
          if (parseInt(identityCard.charAt(16)) % 2 === 0) {
            strSex = "女";
          } else {
            strSex = "男";
          }
        }
      }
      if (len == 15) {
        let birthdayValue = "";
        birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
        if (parseInt(birthdayValue) < 10) {
          strBirthday =
            "20" +
            identityCard.substr(6, 2) +
            "/" +
            identityCard.substr(8, 2) +
            "/" +
            identityCard.substr(10, 2);
        } else {
          strBirthday =
            "19" +
            identityCard.substr(6, 2) +
            "/" +
            identityCard.substr(8, 2) +
            "/" +
            identityCard.substr(10, 2);
        }

        if (identityCard.substr(14) >= "0" && identityCard.substr(14) <= "9") {
          if (parseInt(identityCard.charAt(14) % 2 === 0)) {
            strSex = "女";
          } else {
            strSex = "男";
          }
        }
      }
      let birthDate = new Date(strBirthday);
      let nowDateTime = new Date();
      let age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return { age: isNaN(age) ? "" : age, sex: strSex };
    },
    afterRead(file, field) {
      // 这时候我们创建一个formData对象实例
      const formData = new FormData();
      // 通过append方法添加需要的file
      // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
      formData.append("file", file.file);

      //上传文件、成功后， 把Url 赋值给 field
      Api.uploadFile(formData).then((res) => {
        if (res.code == 0 && res.data != null && res.data != "") {
          this.personInfo[field] = res.data;
        }
      });
    },

    getCommunityList() {
      this.communityList = [];
      Api.getOrgCommunityList().then((res) => {
        this.communityList = res.data;
        //   for (let index = 0; index < res.data.length; index++) {
        //       const item = res.data[index];
        //            this.communityList.push({text:item.name,value:item.id,children:item.children})
        //   }
      });
    },
    getCommunityAllList() {
      this.fieldValue = "";
      if (this.personInfo.community != "") {
        Api.getOrgLiveList({ communityId: this.personInfo.community }).then(
          (res) => {
            res.data.forEach((element) => {
              element["children"] = [];
            });
            try {
              this.communityList.find((p) => p.id == this.personInfo.community)[
                "children"
              ] = res.data;
              this.DataInfo.Community = this.communityList.find(
                (p) => p.id == this.personInfo.community
              ).name;
              this.fieldValue = this.DataInfo.Community;
            } catch {
              //
            }
            if (this.personInfo.liveId != "") {
              Api.getOrgStoreList({ liveId: this.personInfo.liveId }).then(
                (res) => {
                  res.data.forEach((element) => {
                    element["children"] = [];
                  });
                  try {
                    this.communityList
                      .find((p) => p.id == this.personInfo.community)
                      .children.find(
                        (p) => p.id == this.personInfo.liveId
                      ).children = res.data;
                    this.DataInfo.Live = this.communityList
                      .find((p) => p.id == this.personInfo.community)
                      .children.find(
                        (p) => p.id == this.personInfo.liveId
                      ).name;
                    this.fieldValue =
                      this.DataInfo.Community + "/" + this.DataInfo.Live;
                  } catch {
                    //
                  }
                  if (this.personInfo.buildingId != "") {
                    var type="";
                    if(this.personInfo.community&&this.personInfo.liveId){
                      let storeList=this.communityList.find(p => p.id == this.personInfo.community).children.find(p => p.id == this.personInfo.liveId).children;
                      type=storeList.find(e=>e.id==this.personInfo.buildingId).storeTypeCode;
                    }
                    Api.getOrgPublicPage({
                      storeId: this.personInfo.buildingId,
                      limit: 1000,
                      type:type
                    }).then((res) => {
                      try {
                        this.communityList
                          .find((p) => p.id == this.personInfo.community)
                          .children.find((p) => p.id == this.personInfo.liveId)
                          .children.find(
                            (p) => p.id == this.personInfo.buildingId
                          ).children = res.data;
                        this.DataInfo.Store = this.communityList
                          .find((p) => p.id == this.personInfo.community)
                          .children.find((p) => p.id == this.personInfo.liveId)
                          .children.find(
                            (p) => p.id == this.personInfo.buildingId
                          ).name;
                        this.fieldValue =
                          this.DataInfo.Community +
                          "/" +
                          this.DataInfo.Live +
                          "/" +
                          this.DataInfo.Store;
                      } catch {
                        //
                      }
                      if (this.personInfo.houseId != "") {
                        try {
                          this.DataInfo.House = res.data.find(
                            (p) => p.id == this.personInfo.houseId
                          ).name;
                          this.fieldValue =
                            this.DataInfo.Community +
                            "/" +
                            this.DataInfo.Live +
                            "/" +
                            this.DataInfo.Store +
                            "/" +
                            this.DataInfo.House;
                        } catch {
                          //
                        }
                      }
                    });
                  }
                }
              );
            }
          }
        );
      }
    },
    onChange(data) {
      switch (data.tabIndex) {
        case 0:
          this.getAllGrid(data.value);
          Api.getOrgLiveList({ communityId: data.value }).then((res) => {
            res.data.forEach((element) => {
              element["children"] = [];
            });
            if (res.data.length == 0) this.onFinish(data);
            this.communityList.find((p) => p.id == data.value).children =
              res.data;
          });
          break;
        case 1:
          Api.getOrgStoreList({ liveId: data.value }).then((res) => {
            res.data.forEach((element) => {
              element["children"] = [];
            });
            if (res.data.length == 0) this.onFinish(data);
            this.communityList
              .find((p) => p.id == data.selectedOptions[0].id)
              .children.find((p) => p.id == data.value).children = res.data;
          });
          break;
        case 2:
          Api.getOrgPublicPage({
            storeId: data.selectedOptions[2].id,
            limit: 1000,
            type: data.selectedOptions[2].storeTypeCode
          }).then((res) => {
            if (res.data.length == 0) this.onFinish(data);
            this.communityList
              .find((p) => p.id == data.selectedOptions[0].id)
              .children.find((p) => p.id == data.selectedOptions[1].id)
              .children.find(
                (p) => p.id == data.selectedOptions[2].id
              ).children = res.data;
          });
          break;
      }
      if(data.selectedOptions){
        this.fieldValue = data.selectedOptions.map((option) => option.name).join("/");
      this.personInfo.community =
        data.selectedOptions.length > 0 ? data.selectedOptions[0].id : "";

      this.personInfo.gridId =
        data.selectedOptions.length > 1 ? data.selectedOptions[1].gridId : "";

      this.personInfo.liveId =
        data.selectedOptions.length > 1 ? data.selectedOptions[1].id : "";

      this.personInfo.buildingId =
        data.selectedOptions.length > 2 ? data.selectedOptions[2].id : "";

      this.personInfo.houseId =
        data.selectedOptions.length > 3 ? data.selectedOptions[3].id : "";
      }
    },

    onFinish(data) {
      this.show = false;
      if(data.selectedOptions){
      this.fieldValue = data.selectedOptions.map((option) => option.name).join("/");
     
      if (data.selectedOptions.length > 0)
        this.personInfo.community = data.selectedOptions[0].id;
      if (data.selectedOptions.length > 1)
        this.personInfo.gridId = data.selectedOptions[1].gridId;
      if (data.selectedOptions.length > 1)
        this.personInfo.liveId = data.selectedOptions[1].id;
      if (data.selectedOptions.length > 2)
        this.personInfo.buildingId = data.selectedOptions[2].id;
      if (data.selectedOptions.length > 3)
        this.personInfo.houseId = data.selectedOptions[3].id;
         }
    },

    submit() {
      // if(this.personInfo.ankangCode==null||this.personInfo.ankangCode=='')
      //     {
      //        Notify({ type: 'danger', message: '请上传安康码图片' });
      //       return
      //     }
      //     if(this.personInfo.tripCode==null||this.personInfo.tripCode=='')
      //     {
      //  Notify({ type: 'danger', message: '请上传行程码图片' });
      //       return
      //     }
    if (
        this.personInfo.gridId == null ||
        this.personInfo.gridId == ""
      ) {
        Notify({ type: "danger", message: "请选择所属网格" });
        return;
      }
      if (
        this.personInfo.isTransport == null ||
        this.personInfo.isTransport == ""
      ) {
        Notify({ type: "danger", message: "请选择是否转运" });
        return;
      }

      // 校验
      if (
        this.personInfo.controlMsg.controlStep == null ||
        this.personInfo.controlMsg.controlStep == ""
      ) {
        Notify({ type: "danger", message: "请选择管控措施!" });
        return false;
      } else if (
        this.personInfo.controlMsg.controlStep != "NoSegregation" &&
        this.personInfo.controlMsg.controlStep != "CancelReturnHf" &&
        (this.personInfo.controlMsg.controlStatus == null ||
          this.personInfo.controlMsg.controlStatus == "")
      ) {
        Notify({ type: "danger", message: "请选择管控状态!" });
        return false;
      } else if (
        this.personInfo.controlMsg.controlStep != "NoSegregation" &&
        this.personInfo.controlMsg.controlStep != "CancelReturnHf" &&
        (this.personInfo.controlMsg.isolationMode == null ||
          this.personInfo.controlMsg.isolationMode == "")
      ) {
        Notify({ type: "danger", message: "请选择隔离模式!" });
        return false;
      }

      this.disBtn = true;
      this.personInfo.firstTime=this.personInfo.firstTime==''?null:this.personInfo.firstTime;
      this.personInfo.secondTime=this.personInfo.secondTime==''?null:this.personInfo.secondTime;
      this.personInfo.thirdTime=this.personInfo.thirdTime==''?null:this.personInfo.thirdTime;
      //格式转换
      let approveInfo = {
        crossDetail:this.personInfo.crossDetail,
        caseRegion: this.personInfo.caseRegion,
        firstTime:this.personInfo.firstTime,
        secondTime:this.personInfo.secondTime,
        thirdTime:this.personInfo.thirdTime,
        address: this.personInfo.address,
        age: this.personInfo.age,
        leaveTime:this.personInfo.leaveTime,
        ankangCode: this.personInfo.ankangCode,
        arriveHomeTime: this.personInfo.arriveHomeTime,
        buildingId: this.personInfo.buildingId,
        centralizeCycle: this.personInfo.controlMsg.centralizeCycle,
        community: this.personInfo.community,
        controlId: "",
        controlRemark: this.personInfo.controlMsg.remark,
        controlStatus: this.personInfo.controlMsg.controlStatus,
        controlStep: this.personInfo.controlMsg.controlStep,
        controlType: this.personInfo.controlMsg.controlType,

        deviceImei: this.personInfo.controlMsg.deviceImei,
        disease: this.personInfo.disease,
        flightNumber: this.personInfo.flightNumber,
        gridId: this.personInfo.gridId,
        healthCycle: this.personInfo.controlMsg.healthCycle,
        homeCycle: this.personInfo.controlMsg.homeCycle,
        houseCode: this.personInfo.controlMsg.houseCode,
        houseId: this.personInfo.houseId,
        id: this.personInfo.id,
        idCard: this.personInfo.idCard,
        isolatedCity: this.personInfo.isolatedCity,
        isolatedHotel: this.personInfo.isolatedHotel,
        isolatedProve: this.personInfo.isolatedProve,
        isolationMode: this.personInfo.controlMsg.isolationMode,
        lastContactDate: this.personInfo.lastContactDate,
        liveId: this.personInfo.liveId,
        nucleinImg: this.personInfo.nucleinImg,
        occupation: this.personInfo.occupation,
        permanentAddress: this.personInfo.permanentAddress,
        personName: this.personInfo.personName,
        personType: this.personInfo.personType,
        phone: this.personInfo.phone,
        pushRegion: this.personInfo.pushRegion,
        redYellowDate: this.personInfo.redYellowDate,
        releaseMode: this.personInfo.releaseMode,
        relieveCentralizeDate: this.personInfo.controlMsg.relieveCentralizeDate,
        relieveHealthDate: this.personInfo.controlMsg.relieveHealthDate,
        relieveHomeDate: this.personInfo.controlMsg.relieveHomeDate,
        remark: this.personInfo.remark,
        returnDate: this.personInfo.returnDate,
        returnRegion: this.personInfo.returnRegion,
        sex: this.personInfo.sex,
        soureRegion: this.personInfo.soureRegion,
        touchCase: this.personInfo.touchCase,
        touchRegion: this.personInfo.touchRegion,
        transportType: this.personInfo.transportType,
        tripCode: this.personInfo.tripCode,
        type: "1",
        vehicle: this.personInfo.vehicle,
        workUnit: this.personInfo.workUnit,
        isDraft: this.personInfo.isDraft,
        isTransport: this.personInfo.isTransport,
        stayMsg: this.personInfo.stayMsg,
        dataType: this.personInfo.dataType,
      };

      Api.swjApprove(approveInfo).then((res) => {
        if (res.code == 0) {
          Notify({ type: "success", message: "审核成功!" });
          this.personInfo = {
            crossDetail:'',
            caseRegion:'',
            firstTime:null,
            secondTime:null,
            thirdTime:null,
            leaveTime:null,
            address: "",
            arriveHomeTime:null,
            age: "",
            ankangCode: "",
            buildingId: "",
            community: "",
            dataSoucre: "",
            disease: "",
            flightNumber: "",
            grid: "",
            gridId: 0,
            houseCode: "",
            houseId: "",
            idCard: "",
            isolatedCity: "",
            isolatedHotel: "",
            isolatedProve: "",
            lastContactDate: "",
            liveId: "",
            redYellowDate:null,
            nucleinImg: "",
            permanentAddress: "",
            personName: "",
            personType: "",
            phone: "",
            releaseMode: "",
            remark: "",
            returnDate:null,

            returnRegion: 0,
            sex: "",
            touchCase: "",
            tripCode: "",
            vehicle: "",
            workUnit: "",
            isDraft: "",
            isTransport: "",
            stayMsg: "",
            dataType: "",
            controlMsg: {
              centralizeCycle: "",
              controlStatus: "",
              controlStep: "",
              controlType: "",
              createDate: "",
              creator: 0,
              deviceImei: "",
              healthCycle: "",
              homeCycle: "",
              id: 0,
              isDelete: 0,
              isolationMode: "",
              personId: 0,
              relieveCentralizeDate: "",
              relieveHealthDate: "",
              relieveHomeDate: "",
              remark: "",
              status: "",
              statusDate: "",
              transportType: "",
              updateDate: "",
              updater: 0,
            },
          };

          this.fieldValue = "";
          this.cascaderValue = "";
          this.DataInfo = {
            returnRegion: "",
            Community: "",
            Live: "",
            Store: "",
            House: "",
            touchRegion: "",
            pushRegion: "",
            tripCodeList: [],
            ankangCodeList: [],
            nucleinImgList: [],
            isolatedProveList: [],
          };
          this.router.replace({path:"swjApproveList",query:{backhome:'list'}});
        } else {
          Notify({ type: "danger", message: res.msg });
          this.disBtn = false;
        }
      });
      setTimeout(() => {
        this.disBtn = false;
      }, 3000);
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(
          (p) => p.dictType == type
        ).dataList.find((p) => p.dictValue == value).dictLabel;
      } catch {
        return "";
      }
    },
    onConfirmDriver(data) {
      this.ControlPickShow = false;

      this.personInfo.controlMsg[this.PickerField] = data.dictValue;

      if (
        this.personInfo.controlMsg.controlStep == "NoSegregation" ||
        this.personInfo.controlMsg.controlStep == "CancelReturnHf"
      ) {
        // 清空
        this.personInfo.controlMsg.centralizeCycle = "";
        this.personInfo.controlMsg.controlStatus = "";
        this.personInfo.controlMsg.healthCycle = "";
        this.personInfo.controlMsg.homeCycle = "";
        this.personInfo.controlMsg.isolationMode = "";
        this.personInfo.controlMsg.relieveCentralizeDate = "";
        this.personInfo.controlMsg.relieveHealthDate = "";
        this.personInfo.controlMsg.relieveHomeDate = "";
      }
    },
    onConfirmIsolationMode(data) {
      if(!data.id){
         data = this.IsolatedProveList.find((e) => e.id == data);
      }
      if(!data) return
      let baseTime = new Date(this.personInfo[this.dicBeginTime[this.personInfo.personType]]);
      if (baseTime == "Invalid Date") {
        baseTime = new Date();
      }
      let diffday = 0;
      try {
        diffday = 0; // differenceInDays(new Date(), baseTime);
      } catch {
        //
      }
      let a = addDays(baseTime, 1);
      let b = addDays(baseTime, -1);
      this.isolationModePickShow = false;
      this.personInfo.controlMsg.isolationMode = data.id;
      this.personInfo.controlMsg.relieveCentralizeDate =
        data.focusDay == 0
          ? ""
          : format(
              addDays(baseTime, data.focusDay - diffday),
              "yyyy-MM-dd HH:mm:ss"
            );
      this.personInfo.controlMsg.relieveHomeDate =
        data.homeDay == 0
          ? ""
          : format(
              addDays(baseTime, data.focusDay + data.homeDay - diffday),
              "yyyy-MM-dd HH:mm:ss"
            );
      this.personInfo.controlMsg.relieveHealthDate =
        data.healthDay == 0
          ? ""
          : format(
              addDays(
                baseTime,
                data.focusDay + data.homeDay + data.healthDay - diffday
              ),
              "yyyy-MM-dd HH:mm:ss"
            );

      this.personInfo.controlMsg.relieveCentralizeDate =
        data.focusDay == 0
          ? ""
          : this.personInfo.controlMsg.relieveCentralizeDate;
      this.personInfo.controlMsg.relieveHomeDate =
        data.homeDay == 0 ? "" : this.personInfo.controlMsg.relieveHomeDate;
      this.personInfo.controlMsg.relieveHealthDate =
        data.healthDay == 0 ? "" : this.personInfo.controlMsg.relieveHealthDate;
    },
  },
};
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
}
.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}
.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}
.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}
.box::-webkit-scrollbar-corner {
  background: transparent;
}
</style>
