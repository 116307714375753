<template>
  <div class="about">
    <div>
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="姓名"
        :value="personInfo.personName"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="证件号码"
        :value="personInfo.idCard"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="手机号码"
      >
        <template #value>
          <a :href="'tel:' + personInfo.phone">{{ personInfo.phone }} </a>
        </template>
      </van-cell>
      <div>
        <template v-if="personInfo.personType=='TwentyEight'">
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="人员类型"
            :value="personInfo.personLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="人群类型"
            :value="personInfo.crowdTypeLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属社区"
            :value="personInfo.communityLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属网格"
            :value="personInfo.gridLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="单位地址"
            :value="personInfo.workAddress"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属行业"
            :value="personInfo.industryType"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            :value="personInfo.remark"
            title="其他说明"
          />
        </template>
        <template v-else>
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="现居住地"
            :value="personInfo.address"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属社区"
            :value="DataInfo.Community"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属网格"
            :value="personInfo.gridLabel"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="所属小区"
            :value="DataInfo.Live"
          />
          <van-cell
            title-class="van-cell_title"
            v-if="role != 'GridPerson'"
            value-class="van-cell_value"
            title="网格长"
            :value="personInfo.gridPersonName"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="居住楼栋"
            :value="DataInfo.Store"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="居住房屋号"
            :value="DataInfo.House"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="户籍地址"
            :value="personInfo.permanentAddress"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="基础疾病"
            :value="personInfo.disease"
          />
          <van-cell
            title-class="van-cell_title"
            value-class="van-cell_value"
            title="数据来源"
            :value="this.getDictLabel('DataSourceType', personInfo.dataSoucre)"
          />
        </template>
      </div>
      <div style="margin-top: 10px; text-align: center">
      </div>
    </div>
    <div>
      <!-- 密接次密接 需填写病例 、密接姓名、m末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 阳性物品暴露人员 需填写 末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 高风险地区来（返）肥人员 需填写  返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 红黄码人员 需填写 安康码、行程码-->
      <!-- 其他情况 需填写  其他说明、安康码、行程码-->

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="人员类型"
        :value="
          dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]
        "
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="密接病例"
        :value="personInfo.touchCase"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) == 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="推送次密接地区"
        :value="DataInfo.pushRegion"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) == 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="密接所在地区"
        :value="DataInfo.touchRegion"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="接触方式"
        :value="personInfo.releaseMode"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 5 &&
          personInfo.personType != 'BjStudent'
        "
        title="末次密接日期"
        :value="personInfo.lastContactDate"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="是否转运"
        :value="personInfo.isTransport == 1 ? '是' :personInfo.isTransport==2?'否':personInfo.isTransport==3?'道口转运':''"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) == 2 ||
          personInfo.personType == 'BjStudent'
        "
        title="是否抵肥"
        :value="personInfo.isDraft"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          (dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) <= 4) ||
          personInfo.personType == 'BjStudent'
        "
        title="返回合肥时间"
        :value="personInfo.returnDate"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="personInfo.personType == 'BjStudent'"
        title="到家时间"
        :value="personInfo.arriveHomeTime"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          (dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) <= 4) ||
          personInfo.personType == 'BjStudent'
        "
        title="离肥时间"
        :value="personInfo.leaveTime"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if=" personInfo.personType == 'ReturnHf'||personInfo.personType == 'HighRisk'"
        title="末次接触日期"
        :value="personInfo.lastContactDate"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          (dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) <= 4) ||
          personInfo.personType == 'BjStudent'
        "
        title="来源区域"
        :value="personInfo.soureRegion"
      />

      <van-cell
        v-if="
          personInfo.personType == 'ReturnHf' ||
            personInfo.personType == 'HighRisk'
        "
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="风险等级"
        :value="personInfo.riskName"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="personInfo.personType == 'HighRisk'"
        title="数据类型"
        :value="personInfo.dataType"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="personInfo.personType == 'HighRisk'"
        title="住宿信息"
        :value="personInfo.stayMsg"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 2 &&
          dicPersonType.indexOf(personInfo.personType) != 3
        "
        title="交通工具"
        :value="personInfo.vehicle"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          dicPersonType.indexOf(personInfo.personType) >= 2 &&
          dicPersonType.indexOf(personInfo.personType) != 3
        "
        title="航班车次号"
        :value="personInfo.flightNumber"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="dicPersonType.indexOf(personInfo.personType) == 0||dicPersonType.indexOf(personInfo.personType) == 1"
        title="转码时间"
        :value="personInfo.redYellowDate"
      />

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="安康码"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.ankangCode"
            @click="
              ImagePreview({
                images: [personInfo.ankangCode],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="行程码"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.tripCode"
            @click="
              ImagePreview({
                images: [personInfo.tripCode],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="隔离城市"
        v-if="dicPersonType.indexOf(personInfo.personType) == 4"
        :value="personInfo.isolatedCity"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="隔离酒店"
        :value="personInfo.isolatedHotel"
      />
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="
          (dicPersonType.indexOf(personInfo.personType) >= 2 &&
            dicPersonType.indexOf(personInfo.personType) <= 4) ||
          personInfo.personType == 'BjStudent'
        "
        :title="
          dicPersonType.indexOf(personInfo.personType) <= 3
            ? '返回后核酸'
            : '核酸检测照片'
        "
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.nucleinImg"
            @click="
              ImagePreview({
                images: [personInfo.nucleinImg],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        v-if="dicPersonType.indexOf(personInfo.personType) == 4"
        title="解除隔离证明"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.isolatedProve"
            @click="
              ImagePreview({
                images: [personInfo.isolatedProve],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>

      <van-cell
        title-class="van-cell_title"
        value-class="van-cell_value"
        title="其他说明"
        :label="personInfo.remark"
      />

      <div v-if="personInfo.controlMsg != null">
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控措施"
          :value="
            this.getDictLabel('controlStep', personInfo.controlMsg.controlStep)
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控状态"
          :value="
            this.getDictLabel(
              'controlStatus',
              personInfo.controlMsg.controlStatus
            )
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="隔离模式"
          :value="
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null
              ? IsolatedProveList.find(
                  (p) => p.id == personInfo.controlMsg.isolationMode
                ).patternName
              : ''
          "
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="解除集中隔离日期"
          v-if="
            personInfo.controlMsg.controlStep == 'FocusSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).focusDay != 0
          "
          :value="personInfo.controlMsg.relieveCentralizeDate"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="集中隔离填报周期"
          v-if="
            personInfo.controlMsg.controlStep == 'FocusSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).focusDay != 0
          "
          :value="
            this.getDictLabel(
              'ReportingCycle',
              personInfo.controlMsg.centralizeCycle
            )
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="解除居家隔离日期"
          v-if="
            (personInfo.controlMsg.controlStep == 'FocusSegregation' ||
              personInfo.controlMsg.controlStep == 'InHomeSegregation') &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).homeDay != 0
          "
          :value="personInfo.controlMsg.relieveHomeDate"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="居家隔离填报周期"
          v-if="
            (personInfo.controlMsg.controlStep == 'FocusSegregation' ||
              personInfo.controlMsg.controlStep == 'InHomeSegregation') &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).homeDay != 0
          "
          :value="
            this.getDictLabel('ReportingCycle', personInfo.controlMsg.homeCycle)
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          v-if="
            personInfo.controlMsg.controlStep != 'NoSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).healthDay != 0
          "
          title="解除健康监测日期"
          :value="personInfo.controlMsg.relieveHealthDate"
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="健康监测填报周期"
          v-if="
            personInfo.controlMsg.controlStep != 'NoSegregation' &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ) != null &&
            IsolatedProveList.find(
              (p) => p.id == personInfo.controlMsg.isolationMode
            ).healthDay != 0
          "
          :value="
            this.getDictLabel(
              'ReportingCycle',
              personInfo.controlMsg.healthCycle
            )
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          v-if="personInfo.controlMsg.controlStep != 'NoSegregation'"
          title="管控方式"
          :value="
            this.getDictLabel('controlType', personInfo.controlMsg.controlType)
          "
        />
        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          v-if="personInfo.controlMsg.controlStep != 'NoSegregation'"
          title="管控设备序列号"
          :value="personInfo.controlMsg.deviceImei"
        />

        <van-cell
          title-class="van-cell_title"
          value-class="van-cell_value"
          title="管控备注"
          :value="personInfo.controlMsg.remark"
        />
      </div>
    </div>
    <!-- 开启底部安全区适配 -->
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
// @ is an alias to /src
import Api from "../util/App";
import { useRouter } from "vue-router";
import { ImagePreview} from "vant";
import { addDays, format} from "date-fns";
export default {
  name: "Home",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const personInfo = ref({
      id: "",
      personName: "",
      riskId: "",
      riskName: "",
      idCard: "",
      isDraft: "",
      isTransport: "",
      soureRegion: "",
      phone: "",
      sex: "",
      age: 0,
      releaseMode: "",
      workUnit: "",
      community: "",
      grid: "",
      buildingId: "",
      houseId: "",
      houseCode: "A15",
      address: "",
      permanentAddress: null,
      status: "2",
      disease: "",
      personType: "",
      touchCase: "",
      lastContactDate: "",
      returnRegion: "",
      touchRegion: 0,
      pushRegion: 0,
      returnDate: "",
      arriveHomeTime: "",
      ankangCode: "",
      tripCode: "",
      gridId: null,
      dataSoucre: "Gridreport",
      remark: "",
      isolatedCity: "",
      isolatedHotel: "",
      nucleinImg: "",
      isolatedProve: "",
      vehicle: "",
      flightNumber: "",
      creator: "",
      createDate: "",
      updater: null,
      updateDate: null,
      isDelete: "0",
      controlStep: null,
      controlStatus: null,
      relieveIsolationDate: null,
      isolationCycle: null,
      relieveHealthDate: null,
      healthCycle: null,
      stayMsg: "",
      dataType: "",
      controlMsg: {
        id: "",
        personId: "",
        controlStep: "",
        controlStatus: "",
        relieveCentralizeDate: "",
        centralizeCycle: "",
        relieveHomeDate: null,
        homeCycle: "1",
        relieveHealthDate: "",
        healthCycle: "1",
        isolationMode: "",
        transportType: null,
        controlType: "",
        status: null,
        deviceImei: "",
        remark: "",
        creator: "",
        createDate: "",
        updater: "",
        updateDate: "",
        isDelete: "0",
        centralizeVouchers: null,
        homeVouchers: null,
        healthVouchers: null,
        statusDate: "",
      },
      centralizeVouchers: null,
      homeVouchers: null,
      healthVouchers: null,
    });

    const dicPersonTypeLabel = [
      "阳性病例",
      "红码人员",
      "返肥人员",
      "中高风险地区返回",
      "境外人员",
      "次密接",
      "密接",
      "北京/上海大学生",
      "50类人群",
    ];

    const dicPersonType = [
      "Positive",
      "RedCode",
      "ReturnHf",
      "HighRisk",
      "Abroad",
      "SecondTouch",
      "Touch",
      "BjStudent",
      "TwentyEight",
    ];

    const router = useRouter();
    const communityList = ref([]);
    const DataInfo = ref({
      returnRegion: "",
      Community: "",
      Live: "",
      Store: "",
      House: "",
      touchRegion: "",
      pushRegion: "",
    });
    const IsolatedProveList = ref([]);
    const role = ref("");
    const active = ref("");
    return {
      active,
      router,
      addDays,
      format,
      personInfo,
      role,
      IsolatedProveList,
      dicPersonType,
      dicPersonTypeLabel,
      ImagePreview,
      communityList,
      DataInfo,
    };
  },
  mounted() {
    this.role = window.localStorage.getItem("role");
    let id = this.router.currentRoute.value.query.id;
    console.log(id)
    if (id != undefined && id != null) {
      window.localStorage.setItem("personId", id);
    } else {
      id = window.localStorage.getItem("personId");
    }
    this.initdata(id);
  },
  activated() {
    this.role = window.localStorage.getItem("role");
    let id = this.router.currentRoute.value.query.id;
    if (id != undefined && id != null) {
      window.localStorage.setItem("personId", id);
    } else {
      id = window.localStorage.getItem("personId");
    }
    this.active = 0;
    this.initdata(id);
  },
  methods: {
    initdata(id) {
      this.DataInfo = {
        returnRegion: "",
        Community: "",
        Live: "",
        Store: "",
        House: "",
        touchRegion: "",
        pushRegion: "",
      };
      Api.getPersoninfo(id).then((res) => {
        this.personInfo = res.data;

        //if (this.personInfo.personType == "TwentyEight") {
          this.getGridList();
        //}
        if (this.personInfo.dailyMsg == null) {
          this.personInfo.dailyMsg = {
            addStatus: "",
            controlId: 0,
            controlStatus: "",
            controlStep: "",
            createDate: "",
            creator: 0,
            firTemp: "36.5",
            idCard: "",
            isDelete: 0,
            personId: 0,
            personName: "",
            phone: "",
            releaseCertificate: "",
            releaseCertificateList: [],
            voucherImgList: [],
            remark: "",
            secTemp: "36.5",
            staffId: 0,
            updateDate: "",
            updater: 0,
            voucherDate: "",
            voucherImg: "",
            voucherType: "",
          };
        }

        if (
          this.personInfo.dailyMsg.releaseCertificate != null &&
          this.personInfo.dailyMsg.releaseCertificate != ""
        )
          this.personInfo.dailyMsg["releaseCertificateList"] = [
            { url: this.personInfo.dailyMsg.releaseCertificate },
          ];
        if (
          this.personInfo.dailyMsg.voucherImg != null &&
          this.personInfo.dailyMsg.voucherImg != ""
        )
          this.personInfo.dailyMsg["voucherImgList"] = [
            { url: this.personInfo.dailyMsg.voucherImg },
          ];
        this.getRiskList();
        this.getRegion();
        this.getCommunityAllList();
        this.getIsolationModeList();
      });
    },
    getRiskList() {
      Api.getRiskList().then((res) => {
        this.personInfo.riskName =
          this.personInfo.riskId &&
          res.data.find((item) => item.id == this.personInfo.riskId).riskName;
      });
    },
    getIsolationModeList() {
      Api.getControlModeList().then((res) => {
        try {
          this.IsolatedProveList = res.data;
        } catch {
          //
        }
      });
    },
    getGridList() {
       if (this.personInfo.personType == "TwentyEight") {
      this.personInfo.personLabel = this.getDictLabel(
        "PeopleType",
        "TwentyEight"
      );
      let crowdTypeList = this.$store.state.Dict.find(
        (p) => p.dictType == "TwentyEightType"
      ).dataList;
      crowdTypeList.forEach((item) => {
        if (item.dictValue == this.personInfo.crowdType) {
          this.personInfo.crowdTypeLabel = item.dictLabel;
        }
      });
       
      this.communityList.forEach((item) => {
        if (item.id == this.personInfo.community) {
          this.personInfo.communityLabel = item.name;
        }
      });
      }
      this.gridList = [];
      Api.getGridList().then((res) => {
        res.data.forEach((item, index) => {
          if (item.id == this.personInfo.gridId) {
            this.personInfo.gridLabel = item.name;
          }
        });
      });
    },
    getRegion() {
      Api.getRegionList().then((res) => {
        try {
          let list = res.data;
          if (this.personInfo.returnRegion != "") {
            try {
              let county = list.find(
                (p) => p.id == this.personInfo.returnRegion
              );
              this.DataInfo.returnRegion = county.name;
              let city = list.find((p) => p.id == county.pid);
              this.DataInfo.returnRegion =
                city.name + "/" + this.DataInfo.returnRegion;
              let province = list.find((p) => p.id == city.pid);
              this.DataInfo.returnRegion =
                province.name + "/" + this.DataInfo.returnRegion;
            } catch {
              //
            }
          }

          if (this.personInfo.pushRegion != "") {
            try {
              let county = list.find((p) => p.id == this.personInfo.pushRegion);
              this.DataInfo.pushRegion = county.name;
              let city = list.find((p) => p.id == county.pid);
              this.DataInfo.pushRegion =
                city.name + "/" + this.DataInfo.pushRegion;
              let province = list.find((p) => p.id == city.pid);
              this.DataInfo.pushRegion =
                province.name + "/" + this.DataInfo.pushRegion;
            } catch {
              //
            }
          }

          if (this.personInfo.touchRegion != "") {
            try {
              let county = list.find(
                (p) => p.id == this.personInfo.touchRegion
              );
              this.DataInfo.touchRegion = county.name;
              let city = list.find((p) => p.id == county.pid);
              this.DataInfo.touchRegion =
                city.name + "/" + this.DataInfo.touchRegion;
              let province = list.find((p) => p.id == city.pid);
              this.DataInfo.touchRegion =
                province.name + "/" + this.DataInfo.touchRegion;
            } catch {
              //
            }
          }
        } catch {
          //
        }
      });
    },
    getCommunityAllList() {
      this.fieldValue = "";
      Api.getOrgCommunityList().then((res) => {
        try {
          this.communityList = res.data;
          this.DataInfo.Community = this.communityList.find(
            (p) => p.id == this.personInfo.community
          ).name;
          if (this.personInfo.community != "") {
            Api.getOrgLiveList({ communityId: this.personInfo.community }).then(
              (res) => {
                res.data.forEach((element) => {
                  element["children"] = [];
                });
                try {
                  this.communityList.find(
                    (p) => p.id == this.personInfo.community
                  )["children"] = res.data;
                  this.DataInfo.Community = this.communityList.find(
                    (p) => p.id == this.personInfo.community
                  ).name;
                  this.fieldValue = this.DataInfo.Community;
                } catch {
                  //
                }
                if (this.personInfo.liveId != "") {
                  Api.getOrgStoreList({ liveId: this.personInfo.liveId }).then(
                    (res) => {
                      res.data.forEach((element) => {
                        element["children"] = [];
                      });
                      try {
                        this.communityList
                          .find((p) => p.id == this.personInfo.community)
                          .children.find(
                            (p) => p.id == this.personInfo.liveId
                          ).children = res.data;
                        this.DataInfo.Live = this.communityList
                          .find((p) => p.id == this.personInfo.community)
                          .children.find(
                            (p) => p.id == this.personInfo.liveId
                          ).name;
                        this.fieldValue =
                          this.DataInfo.Community + "/" + this.DataInfo.Live;
                      } catch {
                        //
                      }
                      
                      if (this.personInfo.buildingId != "") {
                        var type="";
                    if(this.personInfo.community&&this.personInfo.liveId){
                      let storeList=this.communityList.find(p => p.id == this.personInfo.community).children.find(p => p.id == this.personInfo.liveId).children;
                      type=storeList.find(e=>e.id==this.personInfo.buildingId).storeTypeCode;
                    }
                        Api.getOrgPublicPage({
                          storeId: this.personInfo.buildingId,
                          limit: 1000,
                          type:type
                        }).then((res) => {
                          try {
                            this.communityList
                              .find((p) => p.id == this.personInfo.community)
                              .children.find(
                                (p) => p.id == this.personInfo.liveId
                              )
                              .children.find(
                                (p) => p.id == this.personInfo.buildingId
                              ).children = res.data;
                            this.DataInfo.Store = this.communityList
                              .find((p) => p.id == this.personInfo.community)
                              .children.find(
                                (p) => p.id == this.personInfo.liveId
                              )
                              .children.find(
                                (p) => p.id == this.personInfo.buildingId
                              ).name;
                            this.fieldValue =
                              this.DataInfo.Community +
                              "/" +
                              this.DataInfo.Live +
                              "/" +
                              this.DataInfo.Store;
                          } catch {
                            //
                          }
                          if (this.personInfo.houseId != "") {
                            try {
                              this.DataInfo.House = res.data.find(
                                (p) => p.id == this.personInfo.houseId
                              ).name;
                              this.fieldValue =
                                this.DataInfo.Community +
                                "/" +
                                this.DataInfo.Live +
                                "/" +
                                this.DataInfo.Store +
                                "/" +
                                this.DataInfo.House;
                            } catch {
                              //
                            }
                          }
                        });
                      }
                    }
                  );
                }
              }
            );
          }
        } catch {
          //
        }
      });
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(
          (p) => p.dictType == type
        ).dataList.find((p) => p.dictValue == value).dictLabel;
      } catch {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
  background: #fff;
}

.van-cell >>> .van-cell_title {
  color: #666666 !important;
}

.van-cell >>> .van-cell_value {
  color: #333333 !important;
}

.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}

.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}

.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}

.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}

.box::-webkit-scrollbar-corner {
  background: transparent;
}

.dataList {
  background: #f8f8f8ff;
}

.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
  padding-bottom: 10px;
  text-align: center;
}

.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .leftTag {
  margin-right: 5px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}

.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}

.dataItem .r2 .itemTitle {
  color: #999999;
}

.dataItem .r2 .itemTitleL {
  text-align: left;
  color: #999999;
}

.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r23 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  text-align: left;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.emptyData {
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding-top: 30px;
  color: #666666;
}

.timeaxisBox {
  margin-left: 5px;
  position: relative;
}
.timeaiosItem {
}
.itemLine {
  position: absolute;
  left: 4px;
  height: 100%;
  border-left: 2px solid #e4e7ed;
}
.itemCircle {
  background-color: #409eff;
  border-color: #409eff;
  left: -1px;
  width: 12px;
  height: 12px;
  position: absolute;

  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Head1 {
  margin-left: 12px;
  font-size: 14px !important;
  font-weight: 600;
}
.HeadStatus1 {
  margin-left: 12px;

  font-weight: 600;
}

.itembody {
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.citembody {
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #f7f9ff;
}

.sitembody {
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.Head2 {
  /* margin-left: 12px; */
  font-weight: 400;
  font-size: 14px !important;
  color: #333333;
}

.contains {
  margin-left: 15px;
}
.contains div {
  font-size: 12px;
  color: #666666;
}

.ccontains {
  margin-left: 25px;
}
.citem {
  margin: 5px 0 5px 0;

  padding-left: 5px;
}

.sitem {
  margin: 5px 0 5px 0;
  background: #f7f9ff;
  padding-left: 5px;
}

.scontains {
}
</style>