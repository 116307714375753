<template>
  <van-form
    @failed="onFailed"
    @submit="submit"
  >
    <div class="about">
      <van-cell-group title="隔离人员信息">
        <van-cell
          title="姓名"
          :value="transport.personName"
        />
        <van-cell
          title="电话"
          :value="transport.phone"
        />
        <van-cell
          title="证件号码"
          :value="transport.idCard"
        />
        <van-cell
          title="人员类型"
          :value="transport.personType"
        />
        <van-cell
          title="末次接触时间"
          :value="transport.lastContactDate"
        />
        <van-cell
          title="居住地址"
          :value="transport.address"
        />
      </van-cell-group>

      <van-cell-group title="转运信息填报">

        <van-field
          v-if="transport.id !=''"
          v-model="transport.destination"
          name="转运目的地"
          label="转运目的地"
          placeholder="转运目的地"
          :rules="[{ required: true, message: '转运目的地' }]"
        />

        <van-popup
          v-model:show="showPicker1"
          position="bottom"
          get-container="#app"
        >
          <van-datetime-picker
            v-model="DatetimeDefault"
            type="datetime"
            :min-date="transport.transportTimeDate"
            @confirm="
              (value) => {
                transport[DatetimePickerField] = timeFormat(value);
                showPicker1 = false;
              }
            "
            @cancel="showPicker1 = false"
          />
        </van-popup>
      </van-cell-group>
      <van-cell-group
        v-if="transport.sysUserDTO!=null"
        title="转运专员"
      >
        <van-cell
          title="转运专员"
          :value="transport.sysUserDTO.username"
        />
        <van-cell
          title="手机号码"
          :value="transport.sysUserDTO.mobile"
        />
      </van-cell-group>
      <van-cell-group
        v-if="transport.id != ''"
        title="司机信息"
      >
        <van-cell
          title="司机姓名"
          :value="transport.driverName"
        />
        <van-cell
          title="手机号码"
          :value="transport.driverPhone"
        />
      </van-cell-group>
      <van-cell-group
        v-if="transport.status == 'InTransit'"
        title="转运凭证填报"
      >
        <van-field
          v-model="transport.transportTime"
          label="转运开始时间"
          placeholder="转运开始时间"
        />

        <van-field
          v-model="transport.remark"
          name="备注"
          label="备注"
          placeholder="备注"
        />
        <van-cell title="转运单凭证">
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="orderImgList"
              :after-read="(file) => afterRead(file, 'orderImg')"
              max-count="1"
            />
          </template>
        </van-cell>
        <van-cell title="转运凭证">
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="transportImgList"
              :after-read="(file) => afterRead(file, 'transportImg')"
              max-count="1"
            />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        v-if="role=='Transfer'||role=='GridPerson'"
      >
        {{ transport.id == "" ? "创建转运单" : "结束转运" }}
      </van-button>
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        @click="downLoad"
      >
        下载转运单
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { ImagePreview, Notify, Toast } from "vant";
import Api from "../util/App";
import { fetch } from "../util/downLoad.js";
export default {
  name: "Home",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const transport = ref({
      id: "",
      controlId: "",
      personId: "",
      userId: null,
      driverId: null,
      driverName: null,
      status: "",
      driverPhone: null,
      startPlace: null,
      destination: null,
      transportTime: null,
      transportStartTime: null,
      transportEndTime: null,
      arriveTime: null,
      transportImg: null,
      orderImg: null,
      transportType: "",
      carType: "",
      remark: "",
      creator: "",
      createDate: "",
      updater: null,
      updateDate: null,
      isDelete: "0",
      personName: null,
    });
    const dicPersonTypeList = [
      { value: "Positive", label: "阳性病例" },
      { value: "RedCode", label: "红码人员" },
      { value: "ReturnHf", label: "返肥人员" },
      { value: "HighRisk", label: "中高风险地区返回" },
      { value: "BjStudent", label: "北京/上海大学生" },
      { value: "Abroad", label: "境外人员" },
      { value: "SecondTouch", label: "次密接" },
      { value: "Touch", label: "密接" },
    ];
    const result = ref("");
    const showPicker = ref(false);
    const showPicker1 = ref(false);
    const driverPickShow = ref(false);
    const orderImgList = ref([]);
    const transportImgList = ref([]);
    const DatetimePickerField = ref("");
    const router = useRouter();
    const checked = 1;
    const DatetimeDefault = ref(new Date());
    const DriverList = ref([]);
    const role = ref("");
    return {
      router,
      role,
      transport,
      DatetimeDefault,
      ImagePreview,
      showPicker,
      DriverList,
      driverPickShow,
      result,
      showPicker1,
      checked,
      orderImgList,
      transportImgList,
      DatetimePickerField,
      dicPersonTypeList,
    };
  },
  mounted() {
    this.$emit("changeTitle", "居家隔离人员详情");
    this.role = window.localStorage.getItem("role");
    let id = this.router.currentRoute.value.query.id;
    if (id != null && id != "") {
      this.transport = {
        id: "",
        controlId: "",
        personId: "",
        userId: null,
        driverId: null,
        driverName: null,
        status: "",
        driverPhone: null,
        startPlace: null,
        destination: null,
        transportTime: null,
        transportStartTime: null,
        transportEndTime: null,
        arriveTime: null,
        transportImg: null,
        orderImg: null,
        transportType: "",
        carType: "",
        remark: "",
        creator: "",
        createDate: "",
        updater: null,
        updateDate: null,
        isDelete: "0",
        personName: null,
      };
      this.orderImgList = [];
      this.transportImgList = [];
      this.initdata(id);
    } else {
      let controlId = this.router.currentRoute.value.query.controlId;
      let personId = this.router.currentRoute.value.query.personId;
      let personName = this.router.currentRoute.value.query.personName;
      let phone = this.router.currentRoute.value.query.phone;
      let idCard = this.router.currentRoute.value.query.idCard;

      if (
        controlId != null &&
        controlId != "" &&
        personId != null &&
        personId != "" &&
        personName != null &&
        personName != "" &&
        phone != null &&
        phone != "" &&
        idCard != null &&
        idCard != ""
      ) {
        this.transport.controlId = controlId;
        this.transport.personId = personId;
        this.transport.personName = personName;
        this.transport.phone = phone;
        this.transport.idCard = idCard;
      }
    }
    this.loadDriverList();
  },

  activated() {
    this.role = window.localStorage.getItem("role");
    let id = this.router.currentRoute.value.query.id;
    if (id != null && id != "") {
      this.transport = {
        id: "",
        controlId: "",
        personId: "",
        userId: null,
        driverId: null,
        driverName: null,
        status: "",
        driverPhone: null,
        startPlace: null,
        destination: null,
        transportTime: null,
        transportStartTime: null,
        transportEndTime: null,
        arriveTime: null,
        transportImg: null,
        orderImg: null,
        transportType: "",
        carType: "",
        remark: "",
        creator: "",
        createDate: "",
        updater: null,
        updateDate: null,
        isDelete: "0",
        personName: null,
      };
      this.orderImgList = [];
      this.transportImgList = [];
      this.initdata(id);
    } else {
      Object.keys(this.transport).forEach((key) => (this.transport[key] = ""));
      this.transport.transportType = "1";
      let controlId = this.router.currentRoute.value.query.controlId;
      let personId = this.router.currentRoute.value.query.personId;
      let personName = this.router.currentRoute.value.query.personName;
      let phone = this.router.currentRoute.value.query.phone;
      let idCard = this.router.currentRoute.value.query.idCard;

      if (
        controlId != null &&
        controlId != "" &&
        personId != null &&
        personId != "" &&
        personName != null &&
        personName != "" &&
        phone != null &&
        phone != "" &&
        idCard != null &&
        idCard != ""
      ) {
        this.transport.controlId = controlId;
        this.transport.personId = personId;
        this.transport.personName = personName;
        this.transport.phone = phone;
        this.transport.idCard = idCard;
      }
    }
  },

  methods: {
     downLoad() {
      fetch({
        url: `/app/tsportDown/${this.router.currentRoute.value.query.id}`,
        type: "get",
        responseType: "arraybuffer",
      }).then((res) => {
        console.log(res.data);
        // 下载
        let blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }); // application/octet-stream代表不知道下载的是什么类型
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;

        // 获取文件名
        let fileName = decodeURI(
          res.headers["content-disposition"].split("=")[1]
        ); // 处理文件名乱码问题
        downloadElement.download = fileName; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    initdata(id) {
      Api.gettsDetail(id).then((res) => {
        this.transport = res.data;
        this.transport.transportTimeDate = new Date(
          this.transport.transportTime
        );
        this.transport.personType = this.dicPersonTypeList.find(
          (item) => item.value == this.transport.personType
        ).label;
      });
    },
    loadDriverList() {
      Api.getDriverList().then((res) => {
        this.DriverList = res.data;
      });
    },
    onConfirmDriver(data) {
      this.driverPickShow = false;
      this.transport[this.DatetimePickerField] = data.id;
      this.transport.driverName = data.driverName;
      this.transport.driverPhone = data.driverPhone;
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return (
        year +
        "-" +
        this.padLeft(month, 2) +
        "-" +
        this.padLeft(day, 2) +
        " " +
        this.padLeft(hour, 2) +
        ":" +
        this.padLeft(min, 2) +
        ":00"
      );
    },
    padLeft(num, n) {
      let y = "00000000000000000000000000000" + num; //爱几个0就几个，自己够用就行
      return y.substr(y.length - n);
    },
    afterRead(file, field) {
      // eslint-disable-next-line no-debugger
      debugger;
      // 这时候我们创建一个formData对象实例
      const formData = new FormData();
      // 通过append方法添加需要的file
      // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
      formData.append("file", file.file);

      //上传文件、成功后， 把Url 赋值给 field
      Api.uploadFile(formData).then((res) => {
        if (res.code == 0 && res.data != null && res.data != "") {
          this.transport[field] = res.data;
        }
      });
    },
    submit() {
      if (this.transport.id == "") {
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: "数据传输中",
        });
        Api.gettsAdd(this.transport).then((res) => {
          toast.clear();
          if (res.code == 0) {
            this.transport = {
              id: "",
              controlId: "",
              personId: "",
              userId: null,
              driverId: null,
              driverName: null,
              status: "",
              driverPhone: null,
              startPlace: null,
              destination: null,
              transportTime: null,
              transportStartTime: null,
              transportEndTime: null,
              arriveTime: null,
              transportImg: null,
              orderImg: null,
              transportType: "",
              carType: "",
              remark: "",
              creator: "",
              createDate: "",
              updater: null,
              updateDate: null,
              isDelete: "0",
              personName: null,
            };
            this.orderImgList = [];
            this.transportImgList = [];
            Notify({ type: "success", message: "创建转运单成功!" });
            // this.router.replace('todolist')
            this.$router.back();
          } else {
            Notify({ type: "danger", message: res.msg });
          }
        });
      } else {
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: "数据传输中",
        });

        Api.gettsUpdate(this.transport).then((res) => {
          toast.clear();
          if (res.code == 0) {
            Notify({ type: "success", message: "成功完成转运" });
            // this.router.replace('todolist')
            this.transport = {
              id: "",
              controlId: "",
              personId: "",
              userId: null,
              driverId: null,
              driverName: null,
              status: "",
              driverPhone: null,
              startPlace: null,
              destination: null,
              transportTime: null,
              transportStartTime: null,
              transportEndTime: null,
              arriveTime: null,
              transportImg: null,
              orderImg: null,
              transportType: "",
              carType: "",
              remark: "",
              creator: "",
              createDate: "",
              updater: null,
              updateDate: null,
              isDelete: "0",
              personName: null,
            };
            this.orderImgList = [];
            this.transportImgList = [];
            this.$router.back();
          } else {
            Notify({ type: "danger", message: res.msg });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
}
.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}
.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}
.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}
.box::-webkit-scrollbar-corner {
  background: transparent;
}
</style>